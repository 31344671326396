import React, { Component } from 'react';
import { canadaDateFormat } from '../../../utils/dateUtils';
import { List, Avatar } from 'antd';

class Notifications extends Component<any, any> {
    render() {
        return (
            <div>
                <List
                    className="demo-loadmore-list"
                    itemLayout="horizontal"
                    dataSource={this.props.notifications}
                    renderItem={(request: any) => (
                    <List.Item key={request.id} >
                        <List.Item.Meta
                            avatar={
                                <Avatar src="https://i1.wp.com/bitspacedevelopment.com/wp-content/uploads/2019/02/favicon_bsd_420.png" />
                            }
                            title={canadaDateFormat(request.sentAt)}
                            description={request.payload}
                        />
                    </List.Item>
                    )}
                />
            </div>
        )
    }
}

export default Notifications;