import React, { Component } from 'react';
import { NumberOutlined, RightOutlined, ShopOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Select, Form } from 'antd';

const { Option } = Select;

class CompanyForm extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            industries: [],
            sizes: [],
            loading: true,
        };
    }

    async componentDidMount() {
        await this.setState({
            industries: this.props.values.industries,
            sizes: this.props.values.sizes,
            loading: false,
        });
    }

    selectionOfIndustries() {
        let items = [];
        for (let i = 0; i < this.state.industries.length; i++) {
            items.push(
                <Option
                    key={this.state.industries[i].id}
                    value={this.state.industries[i].id}
                >
                    {`${this.state.industries[i].sectionNumber} ${this.state.industries[i].name}`}
                </Option>
            );
        }
        return items;
    }

    selectionOfSizes() {
        let items = [];
        for (let i = 0; i < this.state.sizes.length; i++) {
            items.push(
                <Option
                    key={this.state.sizes[i].id}
                    value={this.state.sizes[i].id}
                >
                    {`${this.state.sizes[i].name} (${this.state.sizes[i].range})`}
                </Option>
            );
        }
        return items;
    }

    render() {
        return (
            <div>
                <Form.Item
                    validateStatus={
                        this.props.values.errors.legalName ? 'error' : ''
                    }
                    help={this.props.values.errors.legalName || ''}
                >
                    <Input
                        prefix={
                            <ShopOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                        }
                        type="text"
                        value={this.props.values.legalName}
                        placeholder="Legal Name"
                        onChange={this.props.handleChange('legalName')}
                    />
                </Form.Item>
                <Form.Item
                    validateStatus={
                        this.props.values.errors.entityIndustry ? 'error' : ''
                    }
                    help={this.props.values.errors.entityIndustry || ''}
                >
                    <Select
                        value={
                            this.props.values.entityIndustry > 0
                                ? this.props.values.entityIndustry
                                : 'Select Industry'
                        }
                        onChange={this.props.handleChange('entityIndustry')}
                    >
                        {this.selectionOfIndustries()}
                    </Select>
                </Form.Item>
                <Form.Item
                    validateStatus={
                        this.props.values.errors.companySize ? 'error' : ''
                    }
                    help={this.props.values.errors.companySize || ''}
                >
                    <Select
                        value={
                            this.props.values.companySize > 0
                                ? this.props.values.companySize
                                : 'Select Size'
                        }
                        onChange={this.props.handleChange('companySize')}
                    >
                        {this.selectionOfSizes()}
                    </Select>
                </Form.Item>
                <Form.Item
                    validateStatus={
                        this.props.values.errors.numberOfEmployees
                            ? 'error'
                            : ''
                    }
                    help={this.props.values.errors.numberOfEmployees || ''}
                >
                    <Input
                        prefix={
                            <NumberOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                        }
                        type="number"
                        value={
                            this.props.values.numberOfEmployees > 0
                                ? this.props.values.numberOfEmployees
                                : ''
                        }
                        placeholder="Number of Employees"
                        onChange={this.props.handleChange('numberOfEmployees')}
                    />
                </Form.Item>
                <div style={{ textAlign: 'center' }}>
                    <Button
                        className="bsd-btn-teal"
                        onClick={this.props.nextStep}
                    >
                        Continue
                        <RightOutlined />
                    </Button>
                </div>
            </div>
        );
    }
}

export default CompanyForm;
