import axios from 'axios';
import { errorCallback } from '../ErrorCallback';
import { NOTIFICATIONS, SHARED_SERVICE_REQUESTS, SHARE_SERVICE_ACTION } from '../Routes';

export async function getNotifications(callback: Function){
    await axios.get( NOTIFICATIONS )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res.data);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}

export async function getSharedServices(callback: Function){
    await axios.get( SHARED_SERVICE_REQUESTS )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res.data);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}

export async function acceptSharedService(payload: any, callback: Function){
    await axios.post( SHARE_SERVICE_ACTION, payload )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res.data);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}