import React, { Component } from 'react'
import axios from 'axios';
import { connect } from 'react-redux';
import { isValid } from '../../../utils/validate';
import { getCurrentDate } from '../../../utils/dateUtils';
import { Skeleton, Collapse, Badge, message } from 'antd';
import Notifications from '../../components/NotificationCenter/Notifications';
import ConnectionsRequest from '../../components/NotificationCenter/ConnectionsRequest';
import ShareServiceRequests from '../../components/NotificationCenter/ShareServiceRequests';
import OwnerTransferRequests from '../../components/NotificationCenter/OwnerTransferRequests';
import { getNotifications, getSharedServices, acceptSharedService } from '../../../api/NofiticationApi/NofiticationApi';
import { ACCEPT_REQUEST_CONNECT, REQUESTS, TRANSFER_OWNER_REQUESTS, TRANSFER_OWNER_ACTION } from '../../components/routes';

const { Panel } = Collapse;
// const connection = new signalR.HubConnectionBuilder()
//     .withUrl("/signal-server", {
//         accessTokenFactory: () => localStorage?.token
//     })
//     .build();

class NotificationCenter extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            sharedServicesRequests: [],
            connectionRequests: [],
            ownerTransferRequests: [],
            notifications: [],
            loading: true
        };
        this.acceptConnectionRequest = this.acceptConnectionRequest.bind(this);
    }

    async componentDidMount(){
        // connection.on("ReceiveRequestNotification", (data: any) => {
        //     this.setState({ requests: [...this.state.requests, data] })
        // });
        // connection.start().catch(err => document.write(err));

        await getNotifications(this.successNotifications);
        await getSharedServices(this.successSharedServices);
        await axios.get(TRANSFER_OWNER_REQUESTS)
        .then(async (res) => {
            this.setState({ ownerTransferRequests: res.data });
        })
        .catch(async (err) => {

        });

        // await axios.get(REQUESTS)
        // .then(async (res) => {
        //     this.setState({ connectionRequests: res.data });
        // })
        // .catch(async (err) => {

        // });
        this.setState({ loading: false });
    }

    successNotifications = async (data: any) => {
        let notifications = data.sort((a: any, b: any) => {
            a = new Date(a.sentAt);
            b = new Date(b.sentAt);
            return a > b ? -1 : a < b ? 1 : 0;
        });
        await this.setState({ notifications })
    }

    successSharedServices = async (data: any) => {
        await this.setState({ sharedServicesRequests: data })
    }

    acceptConnectionRequest = (data: any) => async (e: any) => {
        //e.preventDefault();
        const { answer, id } = data;
        await axios.post(ACCEPT_REQUEST_CONNECT , {
            requestId: id,
            requestAnswer: answer,
            submittedAt: getCurrentDate()
        })
        .then(async (res) => {
            await this.setState({ requests: res.data });
        })
        .catch(async (err) => {

        });
    }

    actionOwnerTranferRequest = (data: any) => async (e: any) => {
        //e.preventDefault();
        const { answer, id } = data;
        await axios.post(TRANSFER_OWNER_ACTION, {
            requestId: id,
            requestAnswer: answer,
            submittedAt: getCurrentDate()
        })
    }

    // componentWillUnmount(){
    //     connection.stop();
    // }

    acceptSharedService = (data: any) => async (e: any) => {
        //e.preventDefault();
        const { answer, id } = data;
        let payload = { requestId: id, requestAnswer: answer, submittedAt: getCurrentDate() };
        let checker = isValid(payload);
        if(checker.isValid)
        {
            await acceptSharedService(payload, this.successAcceptSharedService);
        } else {
            message.error('Something went wrong check console.');
            console.error({ errors: checker.errors });
        }
    }

    successAcceptSharedService = async (data: any) => {
        await this.setState({ notifications: [...this.state.notifications, data] })
    }
    
    render() {
        return (
            <div>
                <h1>WOW Notifications</h1>
                {
                    (this.state.loading) ? 
                    <Skeleton active />
                    :
                    <Collapse defaultActiveKey={['4']}>
                        <Panel header={
                            <span>
                                <Badge count={this.state.ownerTransferRequests.length} overflowCount={10}/> Owner Transfer Requests
                            </span>
                        } key="1">
                            <OwnerTransferRequests
                                ownerTransferRequests={this.state.ownerTransferRequests}
                                actionOwnerTranferRequest={this.actionOwnerTranferRequest}
                            />
                        </Panel>
                        {/* <Panel header={
                            <span>
                                <Badge count={this.state.connectionRequests.length} overflowCount={10}/> Connection Requests
                            </span>
                        } key="2">
                            <ConnectionsRequest
                                connectionRequests={this.state.connectionRequests}
                                acceptConnectionRequest={this.acceptConnectionRequest}
                            />
                        </Panel> */}
                        <Panel  header={
                            <span>
                                <Badge count={this.state.sharedServicesRequests.length} overflowCount={10}/> Shared Services Requests
                            </span>
                        } key="3">
                            <ShareServiceRequests
                                sharedServicesRequests={this.state.sharedServicesRequests}
                                acceptSharedService={this.acceptSharedService}
                            />
                        </Panel>
                        
                        <Panel  header={
                            <span>
                                <Badge count={this.state.notifications.length} overflowCount={10}/> Notifications
                            </span>
                        } key="4">
                            <Notifications
                                notifications={this.state.notifications}
                            />
                        </Panel>
                    </Collapse>
                }
            </div>
        )
    }
}

function mapToStateToProps(state: any){
    return {
        auth: state.auth,
        company: state.menuLayout
    };
}

export default connect(mapToStateToProps, {})(NotificationCenter);