import React, { Component } from 'react'
import '@ant-design/compatible/assets/index.css';
import { FireOutlined, HomeOutlined, LoadingOutlined, MailOutlined, PhoneOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Button, message, Switch, Upload, Col, Row, Form } from 'antd';
import axios from 'axios';
import { PROFILE, IMAGE_UPLOAD, PROFILE_IMAGE_UPLOAD } from '../../components/routes';
import { isValid, objectToFormData } from '../../../utils/validate';
import { getCurrentDate } from '../../../utils/dateUtils';

class ProfileSettings extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            avatarImage: '',
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            jobPosition: '',
            address: '',
            city: '',
            province: '',
            postalCode: '',
            tutorialDone: false,
            submittedAt: '',
            errors: [],
            loading: true,
            imgLoading: true
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        await axios.get(PROFILE)
            .then(async (res) => {
                await this.setState({
                    email: res.data.email, firstName: res.data.firstName, avatarImage: res.data.avatarImage,
                    lastName: res.data.lastName, phoneNumber: res.data.phoneNumber, jobPosition: res.data.userInformation.jobPosition,
                    address: res.data.userInformation.address, city: res.data.userInformation.city, province: res.data.userInformation.province,
                    postalCode: res.data.userInformation.postalCode, tutorialDone: res.data.userInformation.tutorialDone, loading: false,
                    imgLoading: false
                });
            })
            .catch(async (err) => {
                message.error(`${err.response.status} ${err.response.statusText}`);
            });
    }

    handleChange = (input: any) => async (e: any) => {
        if (typeof e === 'object') {
            await this.setState({ [input]: e.target.value } as any);
        }
        else {
            await this.setState({ [input]: e } as any);
        }
    }

    UpdateProfileViewModel(value: any) {
        return {
            email: value.email,
            firstName: value.firstName,
            lastName: value.lastName,
            phoneNumber: value.phoneNumber,
            jobPosition: value.jobPosition,
            address: value.address,
            city: value.city,
            province: value.province,
            postalCode: value.postalCode,
            tutorialDone: value.tutorialDone,
            submittedAt: getCurrentDate(),
        }
    }

    async onSubmit(e: any) {
        this.setState({ loading: true });
        //e.preventDefault();
        let updateProfile = this.UpdateProfileViewModel(this.state);
        let checker = isValid(updateProfile);
        if (checker.isValid) {
            let formModel = objectToFormData(updateProfile);
            await axios.patch(PROFILE, formModel)
                .then(async (res) => {
                    await this.setState({
                        email: res.data.email, firstName: res.data.firstName,
                        lastName: res.data.lastName, phoneNumber: res.data.phoneNumber, jobPosition: res.data.userInformation.jobPosition,
                        address: res.data.userInformation.address, city: res.data.userInformation.city, province: res.data.userInformation.province,
                        postalCode: res.data.userInformation.postalCode, tutorialDone: res.data.userInformation.tutorialDone, loading: false
                    });
                    message.success("Profile Updated successfully.");
                })
                .catch(async (err) => {
                    this.setState({ loading: false });
                    message.error(err.response.data);
                });
        }
        this.setState({ errors: checker.errors, loading: false });
    }

    beforeUpload(file: any) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    getBase64(img: any, callback: any) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    handleImageChange = async (info: any) => {
        if (info.file.status === 'uploading') {
            this.setState({ imgLoading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            this.getBase64(info.file.originFileObj, (avatarImage: string) =>
                this.setState({
                    avatarImage,
                    imgLoading: false,
                }),
            );
        }

        let response = info.file;
        if (response.hasOwnProperty('xhr')) {
            await axios.post(PROFILE_IMAGE_UPLOAD, {
                avatarImage: response.xhr.responseText,
                submittedAt: getCurrentDate()
            })
                .catch(async (err) => {
                    console.log('====================================');
                    console.log(err.response);
                    console.log('====================================');
                });
        }
    };

    render() {
        return (
            <React.Fragment>

                <Row>
                    <Col xs={24} sm={24} md={1}></Col>
                    <Col xs={24} sm={24} md={22} style={{ backgroundColor: "#fff", textAlign: "center", padding: "30px" }}>
                        <h1>Profile Settings</h1>
                        <Upload
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action={IMAGE_UPLOAD}
                            beforeUpload={this.beforeUpload}
                            onChange={this.handleImageChange}
                        >
                            {
                                this.state.avatarImage ?
                                    <img src={this.state.avatarImage} alt="avatar" style={{ width: '100%' }} />
                                    :
                                    <div>{
                                        (this.state.imgLoading) ?
                                            <LoadingOutlined />
                                            :
                                            <PlusOutlined />
                                    }

                                        <div className="ant-upload-text">Upload</div>
                                    </div>
                            }
                        </Upload>
                        <Form onFinish={this.onSubmit} className="login-form">
                            <Form.Item validateStatus={this.state.errors.email ? 'error' : ''} help={this.state.errors.email || ''}
                                label="Email">
                                <Input
                                    prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="email" value={this.state.email} disabled
                                    placeholder="Email"
                                />
                            </Form.Item>
                            <Form.Item validateStatus={this.state.errors.firstName ? 'error' : ''} help={this.state.errors.firstName || ''}
                                label="First Name">
                                <Input
                                    prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="text" value={this.state.firstName}
                                    placeholder="First Name" onChange={this.handleChange('firstName')}
                                />
                            </Form.Item>
                            <Form.Item validateStatus={this.state.errors.lastName ? 'error' : ''} help={this.state.errors.lastName || ''}
                                label="Last Name">
                                <Input
                                    prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="text" value={this.state.lastName}
                                    placeholder="Last Name" onChange={this.handleChange('lastName')}
                                />
                            </Form.Item>
                            <Form.Item validateStatus={this.state.errors.phoneNumber ? 'error' : ''} help={this.state.errors.phoneNumber || ''}
                                label="Phone Number">
                                <Input
                                    prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="text" value={this.state.phoneNumber}
                                    placeholder="Phone Number" onChange={this.handleChange('phoneNumber')}
                                />
                            </Form.Item>
                            <Form.Item validateStatus={this.state.errors.jobPosition ? 'error' : ''} help={this.state.errors.jobPosition || ''}
                                label="Job Position">
                                <Input
                                    prefix={<FireOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="text" value={this.state.jobPosition}
                                    placeholder="Job Position" onChange={this.handleChange('jobPosition')}
                                />
                            </Form.Item>
                            <Form.Item validateStatus={this.state.errors.address ? 'error' : ''} help={this.state.errors.address || ''}
                                label="Address">
                                <Input
                                    prefix={<HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="text" value={this.state.address}
                                    placeholder="Address" onChange={this.handleChange('address')}
                                />
                            </Form.Item>
                            <Form.Item validateStatus={this.state.errors.city ? 'error' : ''} help={this.state.errors.city || ''}
                                label="City">
                                <Input
                                    prefix={<HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="text" value={this.state.city}
                                    placeholder="City" onChange={this.handleChange('city')}
                                />
                            </Form.Item>
                            <Form.Item validateStatus={this.state.errors.province ? 'error' : ''} help={this.state.errors.province || ''}
                                label="Province">
                                <Input
                                    prefix={<HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="text" value={this.state.province}
                                    placeholder="Province" onChange={this.handleChange('province')}
                                />
                            </Form.Item>
                            <Form.Item validateStatus={this.state.errors.postalCode ? 'error' : ''} help={this.state.errors.postalCode || ''}
                                label="Postal Code">
                                <Input
                                    prefix={<HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="text" value={this.state.postalCode}
                                    placeholder="Postal Code" onChange={this.handleChange('postalCode')}
                                />
                            </Form.Item>
                            <Form.Item validateStatus={this.state.errors.tutorialDone ? 'error' : ''} help={this.state.errors.tutorialDone || ''}
                                label="Tutorial Check" >
                                <Switch onChange={this.handleChange('tutorialDone')} checked={this.state.tutorialDone} />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loading}>
                                    Update
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col xs={24} sm={24} md={2}></Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default ProfileSettings