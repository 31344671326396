import React, { Component } from 'react';
import axios from 'axios';
import { Skeleton, message } from 'antd';
import ManageCompanies from '../../../components/ManageCompanyCard/ManageCompanies';

class ManagedCompanies extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            managerCompanies: [],
            loading: true
        }
    }

    async componentDidMount(){
        await axios.get('/api/v1/companies/manager')
        .then(async (res) => {
            this.setState({ managerCompanies: res.data });
        })
        .catch(async (err) => {
            console.log(err.response);
            message.error('Something went wrong check the console.');
        });

        this.setState({ loading: false });
    }

    render() {
        return (
            <div>
                <h1>Managed Companies</h1>
                {
                (this.state.loading) ?
                <Skeleton active />
                :
                <ManageCompanies
                    companies={this.state.managerCompanies}
                    menuName={'Company Manager'}
                />
                }
            </div>
        )
    }
}

export default ManagedCompanies;