import React, { Component } from 'react';
import { ConnectedUsersProps } from './module';
import { UserOutlined } from '@ant-design/icons';
import { List, Typography, Button, Avatar } from 'antd';
import { canadaDateFormat } from '../../../utils/dateUtils';

export class ConnectedUsers extends Component<ConnectedUsersProps, {}> {
    render() {
        return (
            <List
                header={<div>Connections</div>}
                bordered
                dataSource={this.props.connectedUsers}
                renderItem={(connection: any) => (
                    <List.Item actions={[
                        //<Button key='remove' type="danger" icon="delete" onClick={() => this.props.delete(connection.id)} />
                    ]}>
                        <List.Item.Meta
                            avatar={ <Avatar icon={<UserOutlined />} /> }
                            title={`[${connection.user.firstName}] ${connection.user.firstName} ${connection.user.lastName}`}
                            description={
                            <div>
                                <strong>Connected since: </strong> {canadaDateFormat(connection.since)}
                            </div>}
                        />
                    </List.Item>
                )}
            />
        );
    }
}

export default ConnectedUsers;