import React, { Component } from 'react';
import { List, Skeleton, Avatar, Button } from 'antd';

class OwnerTransferRequests extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = { loading: true };
    }

    async componentDidMount(){
       await this.setState({ requests: this.props.ownerTransferRequests, loading: false });
    }

    render() {
        return (
            <List
                className="demo-loadmore-list"
                loading={this.state.loading}
                itemLayout="horizontal"
                dataSource={this.state.requests}
                renderItem={(request: any) => (
                    <List.Item key={request.id}
                        actions={[
                            <Button key="accept" type="primary" onClick={this.props.actionOwnerTranferRequest({ answer: true, id: request.id})}>Accept</Button>,
                            <Button key="reject" onClick={this.props.actionOwnerTranferRequest({ answer: false, id: request.id})}>Reject</Button>
                        ]}
                    >                        
                        <List.Item.Meta
                            avatar={ <Avatar src="https://i1.wp.com/bitspacedevelopment.com/wp-content/uploads/2019/02/favicon_bsd_420.png" /> }
                            title={`You have been requested as new owner of ${request.company.legalEntityName}`}
                            description={`By accepting ${request.user.firstName} ${request.user.lastName} will become owner of ${request.company.legalEntityName}`}
                        />
                    </List.Item>
                )}
            />
        )
    }
}

export default OwnerTransferRequests;