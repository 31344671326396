import React, { Component } from 'react';
import { MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Divider, Avatar, Typography, Row, Col } from 'antd';

const { Title, Paragraph, Text } = Typography;

class CompanyPageInfo extends Component<any, {}> {
    render() {
        const BannerSrc =
            this.props.company.bannerImage === ''
                ? ''
                : this.props.company.bannerImage;
        return (
            <section>
                <figure className='cover-img onload lazy-loaded'>
                    <img
                        alt=''
                        src={BannerSrc}
                        style={{
                            width: '100%',
                            height: '335px',
                            objectFit: 'cover',
                        }}
                    />
                </figure>
                       
                <Row justify="space-between" style={{ marginTop: "20px" }} gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={12}>
                        <Title level={2}>
                            {this.props.company.legalEntityName}
                        </Title>
                        <Paragraph>{this.props.company.description}</Paragraph>
                                
                    </Col>
                    <Col xs={24} sm={24} md={11}>
                        
                        <Row className="tealBorder bsd-whiteBG" justify="space-between" style={{ display:"flex",marginBottom: "20px", padding: "10px" }}>
                            <Col xs={24} sm={24} md={4}>
                                {this.props.company.logoImage === '' ? (
                                    <Avatar
                                        size={98}
                                        icon={<UserOutlined />}
                                        shape="square"
                                    />
                                ) : (
                                        <Avatar
                                            size={98}
                                            src={this.props.company.logoImage}
                                            shape="square"
                                        />
                                    )}
                            </Col>
                            <Col xs={24} sm={24} md={18}>
                                <Row>{this.props.company.companyInformation.address}</Row>
                                <Row>{this.props.company.companyInformation.city} </Row>
                                <Row>{this.props.company.companyInformation.province} </Row>
                                <Row>{this.props.company.companyInformation.postalCode} </Row>
                                <Row>{this.props.company.companyInformation.phoneNumber}</Row>
                            </Col>
                        </Row>
                        
                        <Row className="tealBorder bsd-whiteBG" justify="space-between" style={{ padding: "10px"}}>
                            <Col xs={24} sm={24} md={6}>
                                <Avatar shape="circle" size={84} src={this.props.owner?.user?.avatarImage} />
                            </Col>
                            <Col id="OwnerInfo" xs={24} sm={24} md={18}>
                                <h3 style={{ marginBottom: "0", fontWeight: 600 }} className="bsd-dark-blue">{`${this.props.owner?.user?.firstName} ${this.props.owner?.user?.lastName}`}</h3>
                                <h4 style={{ marginBottom: "0" }} className="teakText">{`${this.props.owner?.user?.userInformation?.jobPosition}`}</h4>
                                <h4 style={{ marginBottom: "0" }} className="bsd-blue"><MailOutlined className="tealText" /> {`${this.props.owner?.user?.email}`}</h4>
                                <h4 style={{ marginBottom: "0" }} className="bsd-blue"><PhoneOutlined className="tealText" /> {`${this.props.owner?.user?.phoneNumber}`}</h4>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </section>
        );
    }
}

export default CompanyPageInfo;
