import React, { Component } from 'react';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Collapse, List, Button, Modal, Col, Input, Form } from 'antd';

const { Panel } = Collapse;
const { confirm } = Modal;

interface State {
    editModal: boolean,
    addModal: boolean,
    id: 0,
    name:string,
    fontColor:string,
    font1:string,
    font2:string,
    primaryColor:string,
    secondaryColor:string,
    bgColor:string,
    h1Color:string,
    h2Color:string,
    h3Color:string,
    h4Color:string,
    h5Color:string,
    pColor:string,
    filterColor:string,
    sortColor:string,
    buttonPrimary:string,
    buttonSecondary:string,
    cardButtons:string,
    span:string,
    popover:string,
    styleClass:string,
    anchorClass: string,
    logo: string,
    userColor: string,
    managerColor: string,
    footer: string,
    containerColor: string,
    h6Color: string,
    cardButtonsSecondary: string
}
class Layouts extends Component<any, State> {
    state: State = {
        editModal: false,
        addModal: false,
        id: 0,
        name: "",
        fontColor: "",
        font1: "",
        font2: "",
        primaryColor: "",
        secondaryColor: "",
        bgColor: "",
        h1Color: "",
        h2Color: "",
        h3Color: "",
        h4Color: "",
        h5Color: "",
        pColor: "",
        filterColor: "",
        sortColor: "",
        buttonPrimary: "",
        buttonSecondary: "",
        cardButtons: "",
        span: "",
        popover: "",
        styleClass: "",
        anchorClass: "",
        logo: "",
        userColor: "",
        managerColor: "",
        footer: "",
        containerColor: "",
        h6Color: "",
        cardButtonsSecondary:""
    }

    public constructor(props: any) {
        super(props);
    }

    componentDidMount() {
    }

    editLayoutModal = (layout: any) => {
        this.setState({
            id: layout.id, name: layout.name, fontColor: layout.fontColor, font1: layout.font1, font2: layout.font2, primaryColor: layout.primaryColor,
            secondaryColor: layout.secondaryColor, bgColor: layout.bgColor, h1Color: layout.h1Color, h2Color: layout.h2Color, h3Color: layout.h3Color, h4Color: layout.h4Color,
            h5Color: layout.h5Color, pColor: layout.pColor, filterColor: layout.filterColor, sortColor: layout.sortColor, buttonPrimary: layout.buttonPrimary,
            buttonSecondary: layout.buttonSecondary, cardButtons: layout.cardButtons, span: layout.span, popover: layout.popover, styleClass: layout.styleClass,
            anchorClass: layout.anchorClass, logo: layout.logo, userColor: layout.userColor, managerColor: layout.managerColor, footer: layout.footer,
            containerColor: layout.containerColor, h6Color: layout.h6Color, cardButtonsSecondary: layout.cardButtonsSecondary, editModal: true
        })
    }

    addLayoutModal = () => {
        this.setState({
            addModal: true
        })
    }

    editLayout = async() => {
        let postObj = {
            Id: this.state.id, Name: this.state.name, FontColor: this.state.fontColor, Font1: this.state.font1, Font2: this.state.font2, PrimaryColor: this.state.primaryColor,
            SecondaryColor: this.state.secondaryColor, BgColor: this.state.bgColor, H1Color: this.state.h1Color, H2Color: this.state.h2Color, H3Color: this.state.h3Color, H4Color: this.state.h4Color,
            H5Color: this.state.h5Color, PColor: this.state.pColor, FilterColor: this.state.filterColor, SortColor: this.state.sortColor, ButtonPrimary: this.state.buttonPrimary,
            ButtonSecondary: this.state.buttonSecondary, CardButtons: this.state.cardButtons, Span: this.state.span, Popover: this.state.popover, StyleClass: this.state.styleClass,
            AnchorClass: this.state.anchorClass, Logo: this.state.logo, userColor: this.state.userColor, managerColor: this.state.managerColor, footer: this.state.footer,
            containerColor: this.state.containerColor, h6Color: this.state.h6Color, cardButtonsSecondary: this.state.cardButtonsSecondary
        }
        await this.props.EditLayout(postObj);
        this.setState({
            id: 0, name: "", fontColor: "", font1: "", font2: "", primaryColor: "",
            secondaryColor: "", bgColor: "", h1Color: "", h2Color: "", h3Color: "", h4Color: "",
            h5Color: "", pColor: "", filterColor: "", sortColor: "", buttonPrimary: "",
            buttonSecondary: "", cardButtons: "", span: "", popover: "", styleClass: "",
            anchorClass: "", logo: "", userColor: "", managerColor: "", footer: "", containerColor: "", h6Color: "", cardButtonsSecondary: "", editModal: false });
    }

    addLayout = async () => {
        let postObj = {
            Name: this.state.name, FontColor: this.state.fontColor, Font1: this.state.font1, Font2: this.state.font2, PrimaryColor: this.state.primaryColor,
            SecondaryColor: this.state.secondaryColor, BgColor: this.state.bgColor, H1Color: this.state.h1Color, H2Color: this.state.h2Color, H3Color: this.state.h3Color, H4Color: this.state.h4Color,
            H5Color: this.state.h5Color, PColor: this.state.pColor, FilterColor: this.state.filterColor, SortColor: this.state.sortColor, ButtonPrimary: this.state.buttonPrimary,
            ButtonSecondary: this.state.buttonSecondary, CardButtons: this.state.cardButtons, Span: this.state.span, Popover: this.state.popover, StyleClass: this.state.styleClass,
            AnchorClass: this.state.anchorClass, Logo: this.state.logo, userColor: this.state.userColor, managerColor: this.state.managerColor, footer: this.state.footer,
            containerColor: this.state.containerColor, h6Color: this.state.h6Color, cardButtonsSecondary: this.state.cardButtonsSecondary
        }
        
        await this.props.AddLayout(postObj);
        this.setState({
            id: 0, name: "", fontColor: "", font1: "", font2: "", primaryColor: "",
            secondaryColor: "", bgColor: "", h1Color: "", h2Color: "", h3Color: "", h4Color: "",
            h5Color: "", pColor: "", filterColor: "", sortColor: "", buttonPrimary: "",
            buttonSecondary: "", cardButtons: "", span: "", popover: "", styleClass: "",
            anchorClass: "", logo: "", userColor: "", managerColor: "", footer: "", containerColor: "", h6Color: "", cardButtonsSecondary: "", addModal: false
        });
    }

    editLayoutCancel = () => {
        this.setState({
            id: 0, name: "", fontColor: "", font1: "", font2: "", primaryColor: "",
            secondaryColor: "", bgColor: "", h1Color: "", h2Color: "", h3Color: "", h4Color: "",
            h5Color: "", pColor: "", filterColor: "", sortColor: "", buttonPrimary: "",
            buttonSecondary: "", cardButtons: "", span: "", popover: "", styleClass: "",
            anchorClass: "", logo: "", userColor: "", managerColor: "", footer: "", containerColor: "", h6Color: "", cardButtonsSecondary: "", editModal: false, addModal:false
        });
    }

    handleChange = (input: any) => (e: any) => {
        this.setState({ [input]: e.target.value } as any);
    }

    error = (data:any) => {
        console.log(data);
    }

    render() {
        return (
            <div>
                <Button type="primary" danger icon={<PlusOutlined />} style={{marginBottom:"20px", width:"200px"}} onClick={() => { this.addLayoutModal() }}>Add</Button>
                <Collapse>
                    {
                        (this.props.allLayouts.map((layout: any) =>

                            <Panel
                                header={`${layout.name}`}
                                key={layout.id}
                            >
                                <Button type="primary" icon={<EditOutlined />} style={{ marginBottom: "20px", width: "200px" }} onClick={() => { this.editLayoutModal(layout)}}>Edit</Button>
                                {
                                    Object.keys(layout).map((key: any) => {
                                        return (
                                            
                                            <List.Item key={key}
                                            >   
                                                <Col span={6}><strong>{`${key}:`}</strong></Col>
                                                <Col span={6}>{`${layout[key]}`}</Col>
                                                <Col span={12}></Col>
                                            </List.Item>
                                        )
                                    })

                                }
                            </Panel>
                        ))
                    }
                </Collapse>

                <Modal
                    title="Edit layout properties"
                    open={this.state.editModal}
                    okText="Edit"
                    onOk={this.editLayout }
                    onCancel={this.editLayoutCancel}
                    closable
                >
                        <Form.Item>
                            <label>Layout ID: </label>
                            <Input
                                value={this.state.id}
                                placeholder="Store ID"
                                allowClear
                                disabled
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>Layout Name: </label>
                            <Input
                                value={this.state.name}
                                placeholder="Layout Name"
                                allowClear
                                disabled
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>Font Color: </label>
                            <Input
                                value={this.state.fontColor}
                                placeholder="Font Color"
                                allowClear
                                onChange={this.handleChange('fontColor')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>Font 1: </label>
                            <Input
                                value={this.state.font1}
                                placeholder="Font 1"
                                allowClear
                                onChange={this.handleChange('font1')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>Font 2: </label>
                            <Input
                                value={this.state.font2}
                                placeholder="Font 2"
                                allowClear
                                onChange={this.handleChange('font2')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>Primary Color: </label>
                            <Input
                                value={this.state.primaryColor}
                                placeholder="Primary Color"
                                allowClear
                                onChange={this.handleChange('primaryColor')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>Secondary Color: </label>
                            <Input
                                value={this.state.secondaryColor}
                                placeholder="Secondary Color"
                                allowClear
                                onChange={this.handleChange('secondaryColor')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>BG Color: </label>
                            <Input
                                value={this.state.bgColor}
                                placeholder="BG Color"
                                allowClear
                                onChange={this.handleChange('bgColor')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>H1 Color: </label>
                            <Input
                                value={this.state.h1Color}
                                placeholder="H1 Color"
                                allowClear
                                onChange={this.handleChange('h1Color')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>H2 Color: </label>
                            <Input
                                value={this.state.h2Color}
                                placeholder="H2 Color"
                                allowClear
                                onChange={this.handleChange('h2Color')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>H3 Color: </label>
                            <Input
                                value={this.state.h3Color}
                                placeholder="H3 Color"
                                allowClear
                                onChange={this.handleChange('h3Color')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>H4 Color: </label>
                            <Input
                                value={this.state.h4Color}
                                placeholder="H4 Color"
                                allowClear
                                onChange={this.handleChange('h4Color')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>H5 Color: </label>
                            <Input
                                value={this.state.h5Color}
                                placeholder="H5 Color"
                                allowClear
                                onChange={this.handleChange('h5Color')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>H6 Color: </label>
                            <Input
                                value={this.state.h6Color}
                                placeholder="H6 Color"
                                allowClear
                                onChange={this.handleChange('h6Color')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>P Color: </label>
                            <Input
                                value={this.state.pColor}
                                placeholder="P Color"
                                allowClear
                                onChange={this.handleChange('pColor')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>Filter Color: </label>
                            <Input
                                value={this.state.filterColor}
                                placeholder="Filter Color"
                                allowClear
                                onChange={this.handleChange('filterColor')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>Sort Color: </label>
                            <Input
                                value={this.state.sortColor}
                                placeholder="Sort Color"
                                allowClear
                                onChange={this.handleChange('sortColor')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>Button Primary: </label>
                            <Input
                                value={this.state.buttonPrimary}
                                placeholder="Button Primary"
                                allowClear
                                onChange={this.handleChange('buttonPrimary')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>Button Secondary: </label>
                            <Input
                                value={this.state.buttonSecondary}
                                placeholder="Button Secondary"
                                allowClear
                                onChange={this.handleChange('buttonSecondary')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>Card Buttons: </label>
                            <Input
                                value={this.state.cardButtons}
                                placeholder="Card Buttons"
                                allowClear
                                onChange={this.handleChange('cardButtons')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>Span: </label>
                            <Input
                                value={this.state.span}
                                placeholder="Span"
                                allowClear
                                onChange={this.handleChange('span')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                        <label>Popover: </label>
                            <Input
                                value={this.state.popover}
                                placeholder="Popover"
                                allowClear
                                onChange={this.handleChange('popover')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>Style Class: </label>
                            <Input
                                value={this.state.styleClass}
                                placeholder="Style Class"
                                allowClear
                                onChange={this.handleChange('styleClass')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>Anchor Class: </label>
                            <Input
                                value={this.state.anchorClass}
                                placeholder="Anchor Class"
                                allowClear
                                onChange={this.handleChange('anchorClass')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>Logo: </label>
                            <Input
                                value={this.state.logo}
                                placeholder="Logo"
                                allowClear
                                onChange={this.handleChange('logo')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>User Color: </label>
                            <Input
                                value={this.state.userColor}
                                placeholder="User Color"
                                allowClear
                                onChange={this.handleChange('userColor')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>Manager Color: </label>
                            <Input
                                value={this.state.managerColor}
                                placeholder="Manager Color"
                                allowClear
                                onChange={this.handleChange('managerColor')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>Footer: </label>
                            <Input
                                value={this.state.footer}
                                placeholder="Footer"
                                allowClear
                                onChange={this.handleChange('footer')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>Container Color: </label>
                            <Input
                                value={this.state.containerColor}
                                placeholder="Container Color"
                                allowClear
                                onChange={this.handleChange('containerColor')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <label>Card Buttons Secondary: </label>
                            <Input
                                value={this.state.cardButtonsSecondary}
                                placeholder="Card Buttons Secondary"
                                allowClear
                                onChange={this.handleChange('cardButtonsSecondary')}
                                style={{ marginBottom: "30px" }}
                            />
                        </Form.Item>
                </Modal>

                <Modal
                    title="Add layout properties"
                    open={this.state.addModal}
                    okText="Add"
                    onOk={this.addLayout}
                    onCancel={this.editLayoutCancel}
                    closable
                >
                    <Form.Item>
                        <label>Layout Name: </label>
                        <Input
                            value={this.state.name}
                            placeholder="Layout Name"
                            allowClear
                            onChange={this.handleChange('name')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>Font Color: </label>
                        <Input
                            value={this.state.fontColor}
                            placeholder="Font Color"
                            allowClear
                            onChange={this.handleChange('fontColor')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>Font 1: </label>
                        <Input
                            value={this.state.font1}
                            placeholder="Font 1"
                            allowClear
                            onChange={this.handleChange('font1')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>Font 2: </label>
                        <Input
                            value={this.state.font2}
                            placeholder="Font 2"
                            allowClear
                            onChange={this.handleChange('font2')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>Primary Color: </label>
                        <Input
                            value={this.state.primaryColor}
                            placeholder="Primary Color"
                            allowClear
                            onChange={this.handleChange('primaryColor')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>Secondary Color: </label>
                        <Input
                            value={this.state.secondaryColor}
                            placeholder="Secondary Color"
                            allowClear
                            onChange={this.handleChange('secondaryColor')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>BG Color: </label>
                        <Input
                            value={this.state.bgColor}
                            placeholder="BG Color"
                            allowClear
                            onChange={this.handleChange('bgColor')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>H1 Color: </label>
                        <Input
                            value={this.state.h1Color}
                            placeholder="H1 Color"
                            allowClear
                            onChange={this.handleChange('h1Color')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>H2 Color: </label>
                        <Input
                            value={this.state.h2Color}
                            placeholder="H2 Color"
                            allowClear
                            onChange={this.handleChange('h2Color')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>H3 Color: </label>
                        <Input
                            value={this.state.h3Color}
                            placeholder="H3 Color"
                            allowClear
                            onChange={this.handleChange('h3Color')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>H4 Color: </label>
                        <Input
                            value={this.state.h4Color}
                            placeholder="H4 Color"
                            allowClear
                            onChange={this.handleChange('h4Color')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>H5 Color: </label>
                        <Input
                            value={this.state.h5Color}
                            placeholder="H5 Color"
                            allowClear
                            onChange={this.handleChange('h5Color')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>H6 Color: </label>
                        <Input
                            value={this.state.h6Color}
                            placeholder="H6 Color"
                            allowClear
                            onChange={this.handleChange('h6Color')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>P Color: </label>
                        <Input
                            value={this.state.pColor}
                            placeholder="P Color"
                            allowClear
                            onChange={this.handleChange('pColor')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>Filter Color: </label>
                        <Input
                            value={this.state.filterColor}
                            placeholder="Filter Color"
                            allowClear
                            onChange={this.handleChange('filterColor')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>Sort Color: </label>
                        <Input
                            value={this.state.sortColor}
                            placeholder="Sort Color"
                            allowClear
                            onChange={this.handleChange('sortColor')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>Button Primary: </label>
                        <Input
                            value={this.state.buttonPrimary}
                            placeholder="Button Primary"
                            allowClear
                            onChange={this.handleChange('buttonPrimary')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>Button Secondary: </label>
                        <Input
                            value={this.state.buttonSecondary}
                            placeholder="Button Secondary"
                            allowClear
                            onChange={this.handleChange('buttonSecondary')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>Card Buttons: </label>
                        <Input
                            value={this.state.cardButtons}
                            placeholder="Card Buttons"
                            allowClear
                            onChange={this.handleChange('cardButtons')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>Span: </label>
                        <Input
                            value={this.state.span}
                            placeholder="Span"
                            allowClear
                            onChange={this.handleChange('span')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>Popover: </label>
                        <Input
                            value={this.state.popover}
                            placeholder="Popover"
                            allowClear
                            onChange={this.handleChange('popover')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>Style Class: </label>
                        <Input
                            value={this.state.styleClass}
                            placeholder="Style Class"
                            allowClear
                            onChange={this.handleChange('styleClass')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>Anchor Class: </label>
                        <Input
                            value={this.state.anchorClass}
                            placeholder="Anchor Class"
                            allowClear
                            onChange={this.handleChange('anchorClass')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>Logo: </label>
                        <Input
                            value={this.state.logo}
                            placeholder="Logo"
                            allowClear
                            onChange={this.handleChange('logo')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>User Color: </label>
                        <Input
                            value={this.state.userColor}
                            placeholder="User Color"
                            allowClear
                            onChange={this.handleChange('userColor')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>Manager Color: </label>
                        <Input
                            value={this.state.managerColor}
                            placeholder="Manager Color"
                            allowClear
                            onChange={this.handleChange('managerColor')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>Footer: </label>
                        <Input
                            value={this.state.footer}
                            placeholder="Footer"
                            allowClear
                            onChange={this.handleChange('footer')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>Container Color: </label>
                        <Input
                            value={this.state.containerColor}
                            placeholder="Container Color"
                            allowClear
                            onChange={this.handleChange('containerColor')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>Card Buttons Secondary: </label>
                        <Input
                            value={this.state.cardButtonsSecondary}
                            placeholder="Card Buttons Secondary"
                            allowClear
                            onChange={this.handleChange('cardButtonsSecondary')}
                            style={{ marginBottom: "30px" }}
                        />
                    </Form.Item>
                </Modal>

            </div>
        );
    }
}

export default Layouts;
