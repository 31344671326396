import React, { Component } from 'react'
import { ShopOutlined } from '@ant-design/icons';
import { List, Avatar, Button, message } from 'antd';
import { getCurrentDate } from '../../../utils/dateUtils';
import { isValid } from '../../../utils/validate';

class ContentSharedWithMe extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.contentAction = this.contentAction.bind(this);
    }

    GoBackToCompanyList(){
        this.props.companySharingToggle({}, 0);
    }

    contentAction = (content: any, action: boolean) => {
        let payload = {
            content: content.id,
            answer: action,
            submittedAt: getCurrentDate()
        };
        let check = isValid(payload);
        if(check.isValid){
            this.props.makeContentAvailable(payload);
        } else {
            message.error("something went wrong.");
        }
    }

    render() {
        return (
            <div>
                <h1>Company selected: {this.props.sharedWithCompany.legalEntityName}</h1>
                <Button onClick={this.GoBackToCompanyList.bind(this)}>
                    Go Back!
                </Button>
                <List
                    header={<div>Shared with me from {this.props.sharedWithCompany.legalEntityName}</div>}
                    itemLayout="horizontal"
                    dataSource={this.props.content}
                    renderItem={(content: any) => (
                        <List.Item
                            actions={[ (content.available) ? 
                                <Button type="primary" onClick={() => this.contentAction(content, false)}>
                                    Make Unavailable
                                </Button> :
                                <Button type="primary" onClick={() => this.contentAction(content, true)}>
                                    Make Available
                                </Button>
                            ]}
                        >
                            <List.Item.Meta
                                avatar={<Avatar shape="square" size={64} icon={<ShopOutlined />} />}
                                title={content.id}
                                description={(content.name === undefined) ? content.tourName : content.name}
                            />
                        </List.Item>
                    )}
                />
            </div>
        );
    }
}

export default ContentSharedWithMe