import React, { Component } from 'react';
import qs from 'qs';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Result, Progress, Button } from 'antd';
import { CONFIRM_EMAIL } from '../../components/routes';
import PoweredWithLove from '../../assetComponents/PoweredWithLove/PoweredWithLove';

class ConfirmEmail extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            success: false,
            status: '',
        };
    }

    async componentDidMount() {
        const queryString = this.props.location.search.replace('?', '');
        const params = await qs.parse(queryString, {
            strictNullHandling: true,
        });
        await axios
            .post(CONFIRM_EMAIL, {
                Token: `123${params['Token']}`,
                Id: params['Id'],
            })
            .then(async (res) => {
                this.setState({ loading: false, success: true });
            })
            .catch(async (err) => {
                let status: any = 'warning';
                let title = 'Bad Request!';
                let description = 'Bad Request!';
                if (err.response.status >= 500) {
                    status = 500;
                    title = 'Server Error!';
                    description =
                        'Sorry, something went wrong with the server. Try again later.';
                } else if (err.response.status === 400) {
                    title = 'Warning!';
                    if (typeof err.response.data === 'object') {
                        description = err.response.data[0].description;
                    } else {
                        description = err.response.data;
                    }
                }
                this.setState({
                    loading: false,
                    success: false,
                    status: status,
                    title: title,
                    description: description,
                });
            });
    }

    render() {
        return (
            <div className="ant-card loginCard">
                {this.state.loading ? (
                    <Progress
                        strokeColor={{
                            from: '#108ee9',
                            to: '#87d068',
                        }}
                        percent={99.9}
                        status="active"
                    />
                ) : this.state.success ? (
                    <Result
                        status="success"
                        title="Your email address has been confirmed"
                        subTitle="Thank you for confirming your email address. We are glad to have you on board!"
                        extra={[
                            <Button className={`bsd-btn-teal`}>
                                <Link to={`/`}>Login Now</Link>
                            </Button>,
                        ]}
                    />
                ) : (
                    <Result
                        status={this.state.status}
                        title={this.state.title}
                        subTitle={this.state.description}
                        extra={[
                            <Button className={`bsd-btn-teal`}>
                                <Link to={`/`}>Login Now</Link>
                            </Button>,
                        ]}
                    />
                )}
                <PoweredWithLove />
            </div>
        );
    }
}

export default ConfirmEmail;
