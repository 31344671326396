import axios from 'axios';
import { errorCallback } from '../ErrorCallback';
import { CHILD_COMPANIES, SHARE_SERVICE_REQUEST, SHARING_SERVICE_CONTENT,
    LINKED_CONTENT, MAKE_AVAILABLE, AVAILABLE_CONTENT } from '../Routes';

export async function getChildCompanies(payload: string, callback: Function){
    await axios.get( `${CHILD_COMPANIES}${payload}` )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res.data);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}

export async function requestShareService(payload: any, callback: Function){
    await axios.post( SHARE_SERVICE_REQUEST, payload )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res.data);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}

export async function contentFromService(payload: any, callback: Function){
    await axios.get( SHARING_SERVICE_CONTENT, {
        params: {
            Company: payload.company,
            Service: payload.service,
            ShareToCompany: payload.shareToCompany
        }
    })
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res.data);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}


export async function shareContent(payload: any, callback: Function){
    await axios.post( SHARING_SERVICE_CONTENT, payload)
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res.data);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}

export async function linkedContnet(payload: any, callback: Function){
    await axios.get( LINKED_CONTENT, {
        params: {
            Company: payload.company,
            Service: payload.service,
            CompanySharing: payload.companySharing
        }
    })
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res.data);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}

export async function makeAvailable(payload: any, callback: Function){
    await axios.post( MAKE_AVAILABLE, payload)
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res.data);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}

export async function unshareLinkedContent(payload: string, callback: Function){
    await axios.delete(`${LINKED_CONTENT}/${payload}`)
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res.data);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}

export async function availableContnet(payload: any, callback: Function){
    await axios.get( AVAILABLE_CONTENT, {
        params: {
            Company: payload.company,
            Service: payload.service
        }
    })
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res.data);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}