import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import Company from '../Company/Company';
import Services from '../Services/Services';
import ManagementUsers from '../ManagementUsers/ManagementUsers';
import { ManagementServiceProps } from './modules';
import { COMPANY_OWNER } from '../../../app/UserMenuTypes';
import { COMPANY } from '../../../app/UserMenuTypes';
import axios from 'axios';
import { COMPAY_SUBSCRIPTIONS } from '../../components/routes';
import { getCurrentDate } from '../../../utils/dateUtils';
import { Permission } from '../../../interfaces/Permission';
import { UserLoginPerms } from '../../../api/LoginApi/LoginApi';
import { getUserPermissions } from '../../../api/ServiceApi/ServiceApi';
import { userCompanyPermission } from '../../../actions/permissionActions';

export class ManagementService extends Component<
    ManagementServiceProps,
    any
> {
    constructor(props: any) {
        super(props);
        this.state = {
            services: [],
            vrsafety: {},
            loading: true,
        };
    }

    componentDidMount() {
        this.getCompaySubscriptions();

    }
    getCompaySubscriptions = async () => {
        await axios.get(COMPAY_SUBSCRIPTIONS, {
            params: { company: this.props.management.managingCompany.id, date: getCurrentDate() },
        })
            .then(async (res) => {
                let vrsafety = res.data.find((data: any) => data.service.name === "Janus VR Safety Tour")
                await this.setState({ services: res.data, vrsafety: vrsafety });
            })
            .catch(async (err) => {
                console.log(err.response);
            });
    }
    checkUserPerms = async (data: any) => {
        await UserLoginPerms(this.successUserLoginPerms);
    };

    successUserLoginPerms = async (data: any) => {
        let userCompService = {
            params: {
                User: data.userId,
                Company: data.companyId,
                Service: data.serviceId,
                Role: data.role,
            },
        };

        await getUserPermissions(
            userCompService,
            async (data: any) =>
                await this.successUserPermissions(data, data.company)
        );
    };

    successUserPermissions = async (data: Permission, company: any) => {
        let perms: Permission = {
            company: {
                id: company.id,
                logo: company.logo,
                banner: company.banner,
                name: company.name,
            },
            relation: {
                connected: data.relation.connected,
                permissions: data.relation.permissions,
                role: data.relation.role,
            },
            service: data.service,
        };

        await this.props.userCompanyPermission(perms);
        // await this.props.changeManagement(
        //     {
        //         menu: 'Default',
        //         role: data.relation.role,
        //     },
        //     company
        // );


        window.location.href = '/home';
    };

    render() {
        return (
            <div>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            key: "1",
                            label: "User Management",
                            children: (
                                <ManagementUsers services={this.state.services} />
                            )
                        },
                        {
                            key: "2",
                            label: "Company Management",
                            children: (
                                <Company />
                            )
                        },
                        {
                            key: "3",
                            label: (this.props.management.menuSettings.role ===
                                COMPANY_OWNER || this.props.management.menuSettings.role ===
                                COMPANY) ? "Subscription Management" : "Services",
                            children: (
                                <>
                                    {(this.props.management.menuSettings.role ===
                                        COMPANY_OWNER || this.props.management.menuSettings.role ===
                                        COMPANY) ? (

                                        <Services getCompaySubscriptions={this.getCompaySubscriptions} servicesPublic={true} managementRole={this.props.management.menuSettings.role} />

                                    ) : (

                                        <Services getCompaySubscriptions={this.getCompaySubscriptions} servicesPublic={true} managementRole={this.props.management.menuSettings.role} />
                                    )}
                                </>)
                        },
                    ]}
                />

            </div>
        );
    }
}

function reduxStateToProps(state: any) {
    return {
        management: state.management,
    };
}

export default connect(reduxStateToProps, { userCompanyPermission })(ManagementService);
