import React from 'react';
import { Route } from 'react-router-dom';
import requiredAuth from '../../utils/requiredAuth';
import requireSigned from '../../utils/requireSigned';
import {
    PathComponent,
    loggedInRoutes,
    ownerRoutes,
    globalRoutes,
    adminRoutes,
    managerRoutes,
    managerVRSafetyRoutes,
    rootRoutes,
    notLoggedInRoutes,
    vrsafetyRoutes,
    vrsafetyShareContentRoutes,
} from './componentRegistry';

import {
    ROOT_ROLE,
    COMPANY_OWNER,
    COMPANY_ADMIN,
    COMPANY_MANAGER,
    COMPANY_MANAGER_LOGIN,
    DEFAULT,
    COMPANY_OWNER_LOGIN,
    COMPANY_ADMIN_LOGIN,
    VRSAFETY_LOGIN,
    VRSAFETY_SHARECONTENT_LOGIN,
} from '../../app/UserMenuTypes';

export function routeSelector(menuChoice: string) {
    
    let routes: any = [];
    switch (menuChoice) {
        case ROOT_ROLE:
            routes = loggedInRouteBuilder(rootRoutes);
            break;
        case COMPANY_OWNER:
            routes = loggedInRouteBuilder(ownerRoutes);
            break;
        case COMPANY_ADMIN:
            routes = loggedInRouteBuilder(adminRoutes);
            break;
        case COMPANY_MANAGER:
            routes = loggedInRouteBuilder(managerRoutes);
            break;
        case DEFAULT:
            routes = loggedInRouteBuilder(loggedInRoutes);
            break;
        case COMPANY_OWNER_LOGIN:
            routes = loggedInRouteBuilder(ownerRoutes);
            break;
        case COMPANY_ADMIN_LOGIN:
            routes = loggedInRouteBuilder(adminRoutes);
            break;
        case COMPANY_MANAGER_LOGIN:
            routes = loggedInRouteBuilder(managerVRSafetyRoutes);
            break;
        case VRSAFETY_LOGIN:
            routes = loggedInRouteBuilder(vrsafetyRoutes);
            break;
        case VRSAFETY_SHARECONTENT_LOGIN:
            routes = loggedInRouteBuilder(vrsafetyShareContentRoutes);
            break;
        default:
            routes = notLoggedInRouteBuilder(notLoggedInRoutes);
            break;
    }
    return routes;
}

function loggedInRouteBuilder(pathCompLists: Array<PathComponent>) {
    let Routes: any = [];
    pathCompLists.map((item: PathComponent, index: number) =>
        Routes.push(
            <Route
                key={index}
                exact
                path={item.path}
                component={requiredAuth(item.component)}
            />
        )
    );
    globalRoutes.map((item: PathComponent, index: number) =>
        Routes.push(
            <Route
                key={index}
                exact
                path={item.path}
                component={requiredAuth(item.component)}
            />
        )
    );
    return Routes;
}

function notLoggedInRouteBuilder(pathCompLists: Array<PathComponent>) {
    let Routes: any = [];
    pathCompLists.map((item: PathComponent, index: number) =>
        Routes.push(
            <Route
                key={index}
                exact
                path={item.path}
                component={requireSigned(item.component)}
            />
        )
    );
    globalRoutes.map((item: PathComponent, index: number) =>
        Routes.push(
            <Route
                key={index}
                exact
                path={item.path}
                component={requireSigned(item.component)}
            />
        )
    );
    return Routes;
}
