import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout, login } from '../actions/authActions';
import {
    emptyManagement,
    defaultManagement,
    changeManagement,
} from '../actions/management-action/management-action';
import { defaultUserCompPermission } from '../actions/permissionActions';
import { LayoutProps, LayoutState } from './modules';
import NavMenu from '../Nav/NavMenu';
import { Layout, Typography } from 'antd';
import Head from './Head';
import LoginBackground from '../Janus/assets/img/login-background.jpg';

const { Text } = Typography;

const { Content, Footer, Sider } = Layout;

class LayOut extends Component<LayoutProps, LayoutState> {
    constructor(props: LayoutProps) {
        super(props);
        this.state = {
            collapsed: false,
        };
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        const { isAuthenticated } = this.props.auth;
        return (
            <div style={{ height: '100%' }}>
                {isAuthenticated ? (
                    <Layout style={{ minHeight: '100vh' }} >
                        <Sider
                            trigger={null}
                            collapsible
                            collapsed={this.state.collapsed}
                            width="230px"
                            breakpoint="md"
                            className={(process.env.REACT_APP_FRONT_END === "janus") ? "" : "rxdarkBlueBG"}
                        >
                            <NavMenu {...this.props} />
                        </Sider>
                        <Layout>
                            {                              

                                    <Head
                                        {...this.props}
                                        collapsed={this.state.collapsed}
                                        toggle={this.toggle}
                                    /> 
                                    
                             }
                            <Content style={{ margin: '32px 32px' }}>
                                <div
                                    style={{
                                        padding: 24,
                                        background: '#E6F7FF',
                                        minHeight: 360,
                                    }}
                                >
                                    {this.props.children}
                                </div>
                            </Content>
                            <Footer style={{ textAlign: 'center' }}>
                                {' '}
                                <Text
                                    style={{ color: '#0D4D9B' }}
                                    strong={true}
                                >
                                    Powered with &hearts; by BSD
                                </Text>
                            </Footer>
                        </Layout>
                    </Layout>
                ) : (
                        <div
                            style={{
                                paddingTop: '10vh',
                                backgroundImage: `url(${LoginBackground})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                height: '100%',
                            }}
                        >
                            {this.props.children}
                        </div>
                    )}
            </div>
        );
    }
}

function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,
    };
}

export default connect(
    mapToStateToProps,
    {
        login,
        logout,
        emptyManagement,
        defaultManagement,
        changeManagement,
        defaultUserCompPermission,
    },
    null,
    { pure: false }
)(LayOut);
