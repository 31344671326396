import React, { Component } from 'react';
import { UserManagementProps } from './modules/interfaces';
import PromoteUserRoleModal from './PromoteUserRoleModal';
import { CheckOutlined, CloseOutlined, ControlOutlined, DeleteOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { List, Avatar, Button, Switch, Select, Form } from 'antd';
const { Option } = Select;

export class UserManagement extends Component<UserManagementProps, any> {

    DisplayButtons(item: any) {
        //if (item.user.firstName == "shared" && item.user.lastName == "content")
        //    return null;
        let ActionItems: any;
        if (this.props.userPermissionVisible) {
            if (item.activeUser) {
                ActionItems = (
                    < List.Item
                    actions = {
                        [
                            <Form.Item>
                                <label>Internal </label>
                                <Switch
                                    checked={item.internal}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    onChange={() => this.props.handleUserInternalToggle(item)}
                                />
                            </Form.Item>,
                            <Form.Item>
                                <Select
                                    style={{ width: '160px' }}
                                    defaultValue={item.role}
                                    onChange={(data: string) =>
                                        this.props.handleUserRoleChange(data, item)
                                    }
                                >
                                    <Option value="">Select One</Option>
                                    {this.props.accountRoles.map((accountRole: any) => (
                                        <Option key={accountRole.id} value={accountRole.name}>
                                            {accountRole.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>,
                            <Button
                                key="edit"
                                icon={<ControlOutlined />}
                                onClick={() =>
                                    this.props.showPermissionModal(item)
                                }
                                style={{color: "#4ECDC4", borderColor: "#4ECDC4" }}
                                className="tealText"
                                ghost
                            >
                                    Edit Services 
                            </Button>,
                            /*<Button
                                type="primary"
                                icon="edit"
                                onClick={() =>
                                    this.props.handleModalPromotion(item)
                                }
                            />,*/
                            <Button
                                key="remove"
                                type="ghost"
                                icon={<DeleteOutlined />}
                                    onClick={() =>
                                    this.props.deleteCompanyConnection(
                                        item.user.id
                                    )
                                }
                                danger
                            />,
                            ]
                        }
                        >
                        <List.Item.Meta
                            //avatar={<Avatar src={item.user.avatarImage} />}
                            title={
                                <a href={`/profile?${item.user.id}`}>
                                    {`${item.user.firstName} ${item.user.lastName}`}
                                </a>
                            }
                            description={`${
                                item.internal ? 'Internal' : ''
                                } ${item.role} ${
                                item.activeUser ? '' : 'Request Sent'}`}
                        />
                    </List.Item >
                )
            }
            else {
                ActionItems = (
                    <List.Item
                        actions={
                            [
                                <Button
                                    key="remove"
                                    type="primary"
                                    icon={<DeleteOutlined />}
                                    onClick={() =>
                                        this.props.deleteCompanyConnection(
                                            item.user.id
                                        )
                                    }
                                />,
                            ]
                        }
                    >
                        <List.Item.Meta
                            //avatar={<Avatar src={item.user.avatarImage} />}
                            title={
                                <a href={`/profile?${item.user.id}`}>
                                    {`${item.user.firstName} ${item.user.lastName}`}
                                </a>
                            }
                            description={`${
                                item.internal ? 'Internal' : ''
                                } ${item.role} ${
                                item.activeUser ? '' : 'Request Sent'}`}
                        />
                    </List.Item>
                )
            }
        }
        else {
            if (item.activeUser) {
                ActionItems = (
                    <List.Item
                        actions={
                            [
                                /*<Button
                                    type="primary"
                                    icon="edit"
                                    onClick={() =>
                                        this.props.handleModalPromotion(item)
                                    }
                                />,*/
                                <Form.Item>
                                    <label>Internal </label>
                                    <Switch
                                        checked={item.internal}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        onChange={() => this.props.handleUserInternalToggle(item)}
                                    />
                                </Form.Item>,
                                <Form.Item>
                                    <Select
                                        style={{ width: '160px' }}
                                        defaultValue={item.role}
                                        onChange={(data: string) =>
                                            this.props.handleUserRoleChange(data, item)
                                        }
                                    >
                                        <Option value="">Select One</Option>
                                        {this.props.accountRoles.map((accountRole: any) => (
                                            <Option key={accountRole.id} value={accountRole.name}>
                                                {accountRole.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>,
                                <Button
                                    key="remove"
                                    type="ghost"
                                    icon={<DeleteOutlined />}
                                    onClick={() =>
                                        this.props.deleteCompanyConnection(
                                            item.user.id
                                        )
                                    }
                                    danger
                                />,
                            ]
                        }
                    >
                        <List.Item.Meta
                            //avatar={<Avatar src={item.user.avatarImage} />}
                            title={
                                <a href={`/profile?${item.user.id}`}>
                                    {`${item.user.firstName} ${item.user.lastName}`}
                                </a>
                            }
                            description={`${
                                item.internal ? 'Internal' : ''
                                } ${item.role} ${
                                item.activeUser ? '' : 'Request Sent'}`}
                        />
                    </List.Item>
                )
            }
            else {
                ActionItems = (
                    <List.Item
                        actions={
                            [
                                <Button
                                    key="remove"
                                    type="primary"
                                    icon={<DeleteOutlined />}
                                    onClick={() =>
                                        this.props.deleteCompanyConnection(
                                            item.user.id
                                        )
                                    }
                                />,
                            ]
                        }
                    >
                        <List.Item.Meta
                            //avatar={<Avatar src={item.user.avatarImage} />}
                            title={
                                <a href={`/profile?${item.user.id}`}>
                                    {`${item.user.firstName} ${item.user.lastName}`}
                                </a>
                            }
                            description={`${
                                item.internal ? 'Internal' : ''
                                } ${item.role} ${
                                item.activeUser ? '' : 'Request Sent'}`}
                        />
                    </List.Item>
                )
            }
        }
        return ActionItems;
    }


    render() {
        return (
            <div>
                <h2 className={(this.props.headerName==='MANAGEMENT')?"orangeText": "darkBlueText"}>{this.props.headerName}</h2>
                <List
                    loading={this.props.initLoading}
                    itemLayout="horizontal"
                    dataSource={this.props.users.filter(
                        (x) => x.user.id !== null && x.user.firstName != "shared" && x.user.lastName != "content"
                    )}
                    renderItem={(item) => (
                        this.DisplayButtons(item)
                    )}
                    style={{padding:"20px"}}
                />
                
                <PromoteUserRoleModal
                    promoteUserVisible={this.props.promoteUserVisible}
                    handleUserPromoteChanges={
                        this.props.handleUserPromoteChanges
                    }
                    handleModalCancel={this.props.handleModalCancel}
                    selectedUser={this.props.selectedUser}
                    handleUserInternalToggle={
                        this.props.handleUserInternalToggle
                    }
                    handleUserRoleChange={this.props.handleUserRoleChange}
                    accountRoles={this.props.accountRoles}
                />
            </div>
        );
    }
}

export default UserManagement;
