import React, { Component } from 'react';
import { List, Avatar, Button } from 'antd';

export class ShareServiceRequests extends Component<any, any> {
    render() {
        return (
            <div>
                <List
                    className="demo-loadmore-list"
                    itemLayout="horizontal"
                    dataSource={this.props.sharedServicesRequests}
                    renderItem={(item: any) => (
                    <List.Item key={item.id}
                        actions={[
                            <Button key="accept" type="primary" onClick={this.props.acceptSharedService({ answer: true, id: item.id})}>Accept</Button>,
                            <Button key="delete" onClick={this.props.acceptSharedService({ answer: false, id: item.id})}>Delete</Button>
                        ]}
                    >
                        <List.Item.Meta
                            avatar={
                                <Avatar src="https://i1.wp.com/bitspacedevelopment.com/wp-content/uploads/2019/02/favicon_bsd_420.png" />
                            }
                            title={<a href={`/company/${item.parentCompany.legalEntityName.replace(' ', '-')}`}>{item.parentCompany.legalEntityName}</a>}
                            description={`Request to share ${item.subscription.service.name} service with ${item.childCompany.legalEntityName}.`}
                        />
                    </List.Item>
                    )}
                />
            </div>
        )
    }
}

export default ShareServiceRequests;