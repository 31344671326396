import setCompToHeader from '../../utils/SetHeaders/compToken';
import { changeManagement } from '../../actions/management-action/management-action';
import { Management } from '../../interfaces/Management';

export function managedOnRefresh(store: any) {
    if (localStorage.management) {
        let Management: Management = JSON.parse(localStorage.management);
        if (Management.managingCompany.id !== '') {
            setCompToHeader(Management.managingCompany);
        }
        store.dispatch(
            changeManagement(
                Management.menuSettings,
                Management.managingCompany
            )
        );
    }
}
