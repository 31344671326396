import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { ENTITY, REQUEST_CONNECT } from '../../components/routes';
import { Skeleton, message } from 'antd';
import { getCurrentDate } from '../../../utils/dateUtils';
import { availableContnet } from '../../../api/SharingManApi/SharingManApi';
import { companySubscriptions } from '../../../api/PaymentApi/SubscriptionApi';
import {
    setCurrentCompany,
    defaultCompany,
} from '../../../actions/company-action/company-action';
import CompanyPageInfo from '../../components/CompanyPage/CompanyPageInfo';
import ServicesBubble from '../../components/CompanyPage/ServicesBubble';
import { getUserPermissions } from '../../../api/ServiceApi/ServiceApi';
import { Permission } from '../../../interfaces/Permission';
import { userCompanyPermission } from '../../../actions/permissionActions';

class CompanyPage extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            company: {},
            subscriptions: [],
            companyName: '',
            management: false,
            loading: true,
        };
        this.onConnect = this.onConnect.bind(this);
        this.setCompany = this.setCompany.bind(this);
    }

    async componentDidMount() {
        if (this.props.management.managingCompany.id !== '') {
            await this.setState({ management: true });
        }

        let company = this.props.match.params.company;
        await axios
            .get(`${ENTITY}${company}`)
            .then(async (res) => {
                await this.setState({ company: res.data, loading: false });
                await this.props.setCurrentCompany(res.data);
            })
            .catch(async (err) => {
                message.error(err.response.data);
            });

        await companySubscriptions(
            this.state.company.id,
            this.availableContentInService
        );
    }

    componentWillUnmount() {
        if (this.props.management.managingCompany.id === '') {
            this.props.defaultCompany();
        } else {
            this.props.setCurrentCompany(this.props.management.managingCompany);
        }
    }

    availableContentInService = async (subscriptions: any) => {
        let subscriptionList: any[] = [];
        for (let i = 0; i < subscriptions.length; i++) {
            let requestPayload = {
                company: subscriptions[i].company.id,
                service: subscriptions[i].service.id,
            };
            await availableContnet(requestPayload, this.saveTempContent);
            let status = true;
            if (!this.state.management && subscriptions[i].service.id === 2) {
                status = false;
            }
            let subscription = {
                id: subscriptions[i].id,
                active: subscriptions[i].active,
                status: status,
                company: subscriptions[i].company,
                service: subscriptions[i].service,
                content: this.state.tempContent,
                startAt: subscriptions[i].startAt,
                endAt: subscriptions[i].endAt,
            };
            subscriptionList.push(subscription);
        }
        await this.setState({ subscriptions: subscriptionList });
    };

    saveTempContent = async (data: any) => {
        await this.setState({ tempContent: data });
    };

    async onConnect(e: any) {
        //e.preventDefault();
        await axios
            .post(REQUEST_CONNECT, {
                company: this.state.companyName,
                submittedAt: getCurrentDate(),
            })
            .catch(async (err) => {
                console.log(err.response);
            });
    }

    setCompany(name: string) {
        this.setState({ companyName: name });
    }

    UserCompanyService(serviceId: number) {
        return {
            params: {
                User: this.props.auth.user.nameid,
                Company: this.state.company.id,
                Service: serviceId,
            },
        };
    }

    viewLandingPage = async (serviceId: number, contentUrl: string) => {
        let userCompService = this.UserCompanyService(serviceId);
        await getUserPermissions(userCompService, this.successViewLandPage);

        const hide = message.loading('Redirecting...', 0);
        // Dismiss manually and asynchronously
        await setTimeout(hide, 2000);

        window.location.href = contentUrl;
    };

    successViewLandPage = async (data: Permission) => {
        let perms: Permission = {
            company: data.company,
            relation: {
                connected: data.relation.connected,
                permissions: data.relation.permissions,
                role: data.relation.role
            },
            service: data.service,
        };
        this.props.userCompanyPermission(perms);
    };

    render() {
        return (
            <div>
                {this.state.loading ? (
                    <Skeleton active />
                ) : (
                    <div>
                        <CompanyPageInfo
                            company={this.state.company}
                            onConnect={this.onConnect}
                            setCompany={this.setCompany}
                            {...this.props}
                        />
                        <ServicesBubble
                            subscriptions={this.state.subscriptions}
                            viewContentWithPermissions={this.viewLandingPage}
                        />
                    </div>
                )}
            </div>
        );
    }
}

function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,
    };
}

export default connect(mapToStateToProps, {
    setCurrentCompany,
    defaultCompany,
    userCompanyPermission
})(CompanyPage);
