import React, { Component } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Checkbox, Button, Modal, Collapse, Row, Col, Table, Select } from 'antd';
import { CHECKBOX_OPTIONS, TABLE_COLUMNS_CONFIG } from './module';

const { Panel } = Collapse;
const { Option } = Select;

class PermissionModal extends Component<any, any> {
    submitPermissions(){
        this.props.submitPermissions(this.props.user);
    }

    render() {
        return (
            <Modal
                title={`${this.props.user?.user?.firstName} ${this.props.user?.user?.lastName}`}
                open={this.props.permissionModalToggle}
                onOk={this.props.closePermissionModal}
                onCancel={this.props.closePermissionModal}
                closable
            >
                <Row style={{ margin: '10px 0'}}>
                    <Col span={18}>
                        Make <strong>{this.props.user?.user?.firstName}</strong> owner of <strong> {this.props.company.legalEntityName} </strong>
                    </Col>
                    <Col span={6}>
                        <Button onClick={() => { this.props.requestOwnerTranswer(this.props.user) }}>Send Request!</Button>
                    </Col>
                </Row>
               <Collapse expandIconPosition='left' style={{ margin: '10px 0'}}>
                    <Panel header="Edit Permissions for Services" key="1" extra={<SettingOutlined />}>
                        <Select style={{ width: '100%', marginBottom: '10px' }} onChange={this.props.handleChange('service')}>
                            {this.props.services.map((data: any) => (
                                <Option value={data.service.id} key={data.service.id}
                                disabled={!data.status}>{data.service.name}</Option>
                            ))}
                        </Select>
                        <Row>
                        {
                            CHECKBOX_OPTIONS.map((data: any) => (
                            <Col span={8} key={data.label}>
                                <Checkbox value={data.value} onChange={this.props.handleChange(data.label)}
                                    checked={this.props[data.label]}>
                                    {data.label.toUpperCase()}
                                </Checkbox>
                            </Col>
                            ))
                        }
                        </Row>
                        <Row>
                            <Col span={20}></Col>
                            <Col>
                                <Button onClick={this.submitPermissions.bind(this)}>Save</Button>
                            </Col>
                        </Row>
                    </Panel>
                </Collapse>
                {
                    (this.props.user !== null && this.props.user?.permissions?.length > 0) ?
                    <Table dataSource={this.props.user.permissions} columns={TABLE_COLUMNS_CONFIG} style={{ margin: '10px 0'}}/>
                    :
                    null
                }
            </Modal>
        );
    }
}

export default PermissionModal