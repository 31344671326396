import React, { Component } from 'react';
import { List, Typography } from 'antd';

class Connections extends Component<any, any> {
    render() {
        return (
            <List
                header={<div>Connections</div>}
                bordered
                dataSource={this.props.connections}
                renderItem={(user: any) => (
                    <List.Item>
                        {//<Typography.Text mark>[{user.user.userName}]</Typography.Text>
                        ` ${user.user.firstName} ${user.user.lastName}, ${user.user.email}`}
                    </List.Item>
                )}
            />
        )
    }
}

export default Connections;