import { getCurrentDate } from '../../../utils/dateUtils';

export interface RootState
{
    id: number;
    name: string;
    description: string;
    dbType: string;
    url: string;
    submittedAt: string;
    services: Array<any>;
    industries: [];
    sizes: [];
    rootUsers: [];
    loading: boolean;
    errors: any;
}

export function createServiceViewModel(data: any)
{
    return {
        code: data.code,
        name: data.name,
        description: data.description,
        dbType: data.dbType,
        url: data.url,
        submittedAt: getCurrentDate()
    };
}

export function updateServiceViewMode(data: any)
{
    return {
        id: data.id,
        name: data.name,
        description: data.description,
        dbType: data.dbType,
        submittedAt: getCurrentDate()
    };
}