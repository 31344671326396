import React, { Component } from 'react';
import { EnvironmentOutlined, HomeOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Descriptions, Avatar, Typography, Row, Col } from 'antd';
import { connect } from 'react-redux';
const { Text } = Typography;

class ProfilePage extends Component<any, any> {
    render() {

        return (
            <React.Fragment>
                <Row justify="space-between">
                    <Col xs={24} sm={24} md={6}></Col>
                    <Col xs={24} sm={24} md={12} style={{ backgroundColor: "#fff", padding: "30px" }}>
                        <Row justify="space-around">
                            <div>
                                <h1 className="darkBlueText" style={{ fontSize: "1.5em" }}>My Profile</h1>
                            </div>
                        </Row>
                        <Row justify="space-around">
                            <div>
                                <Avatar
                                    shape="circle"
                                    src={
                                        this.props.profile.avatarImage
                                    }
                                    size={84}
                                ></Avatar>
                            </div>
                        </Row>
                        <Row justify="space-around">
                            <div className="pb16">
                                <h2 className="darkBlueText">{this.props.profile.firstName + " " + this.props.profile.lastName}</h2>
                            </div>
                        </Row>
                        <Row justify="space-around">
                            <div className="lightbluetext pb16">
                                {this.props.profile.userInformation.jobPosition}
                            </div>
                        </Row>
                        <Row justify="space-around">
                            <div className="lightbluetext pb16">
                                <MailOutlined className="bsd-teal" />{' '}
                                {this.props.profile.email}
                            </div>
                        </Row>
                        <Row justify="space-around">
                            <div className="lightbluetext pb16">
                                <PhoneOutlined className="bsd-teal" />{' '}
                                {this.props.profile.phoneNumber}
                            </div>
                        </Row>
                        <Row justify="space-around">
                            <div className="lightbluetext pb16">
                                <HomeOutlined className="bsd-teal" />{' '}
                                {`${this.props.profile.userInformation.address}`}
                            </div>
                        </Row>
                        <Row justify="space-around">
                            <div className="lightbluetext pb16">
                                <EnvironmentOutlined className="bsd-teal" />{' '}
                                {this.props.profile.userInformation.city +
                                    ', ' +
                                    this.props.profile.userInformation.province}
                            </div>
                        </Row>
                        <Row justify="space-around">
                            <div className="lightbluetext pb16">
                                <EnvironmentOutlined className="bsd-teal" />{' '}
                                {this.props.profile.userInformation.postalCode}
                            </div>
                        </Row>
                        
                    </Col>
                    <Col xs={24} sm={24} md={6}></Col>
                </Row>
            </React.Fragment>
        );
    }
}

function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,
    };
}
export default connect(mapToStateToProps)(ProfilePage);
