import axios from 'axios';
import { ENTITY_INDUSTRIES, ENTITY_SIZES, BSD_ROLE } from '../Routes';

export async function getCompanyIndustries(callback: Function, errorcallback: Function){
    await axios.get( ENTITY_INDUSTRIES )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorcallback != null){
            await errorcallback(err);
        }
    });
}

export async function getCompanySizes(callback: Function, errorcallback: Function){
    await axios.get( ENTITY_SIZES )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorcallback != null){
            await errorcallback(err);
        }
    });
}

export async function getBSDCompanyRole(callback: Function, errorcallback: Function){
    await axios.get( BSD_ROLE )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorcallback != null){
            await errorcallback(err);
        }
    });
}