import axios from 'axios';
import { errorCallback } from '../ErrorCallback';
import { COMMUNICATE_SERVICE_PERMISSION } from '../Routes';

export async function createServicePermission(
    payload: any,
    callback: Function
) {
    await axios
        .post(COMMUNICATE_SERVICE_PERMISSION, payload)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorCallback != null) {
                await errorCallback(err);
            }
        });
}

export async function updateServicePermission(
    payload: any,
    callback: Function
) {
    await axios
        .patch(COMMUNICATE_SERVICE_PERMISSION, payload)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorCallback != null) {
                await errorCallback(err);
            }
        });
}
