import { ADD_FLASH_MESSAGE, DELETE_FLASH_MESSAGE } from './types';
import { Message } from '../interfaces/message'

export function addFlashMessage(message: Message) {
  return {
    type: ADD_FLASH_MESSAGE,
    message
  }
}

export function deleteFlashMessage(id: string) {
  return {
    type: DELETE_FLASH_MESSAGE,
    id
  }
}