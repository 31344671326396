import React, { Component } from 'react';
import { ServiceState } from './modules';
import { connect } from 'react-redux';
import { CheckOutlined, EyeOutlined, PayCircleOutlined } from '@ant-design/icons';
import { Card, Col, Button, message } from 'antd';
import Meta from 'antd/lib/card/Meta';
import axios from 'axios';
import { COMPANY_OWNER, COMPANY } from '../../../app/UserMenuTypes';
import { userCompanyPermission } from '../../../actions/permissionActions';
import { getUserPermissions } from '../../../api/ServiceApi/ServiceApi';
import { Permission } from '../../../interfaces/Permission';
import { PermissionCompany } from '../../../interfaces/PermissionCompany';
import setCompToHeader from '../../../utils/SetHeaders/compToken';
import permission from '../../../store/permission';


class Service extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            url: '',
            service: {
                id: 0,
                name: '',
                description: '',
                url: '',
            },
            compPerms: {
                Id: '',
                Logo: '',
                Banner: '', 
                CompanyName: '',
                Connected: false,
                Permissions: '',
                Role: '', 
                ServiceId: '', 
                ServiceCode: '', 
                Layout: '' 
            }
        };
    }

    async componentDidMount() {
        await this.setState({
            url: this.props.service.service.url.replace('home', ''),
            service: this.props.service.service,
        });
    }

    UserCompanyService() {
        return {
            params: {
                User: this.props.userId,
                Company: this.props.company.id,
                Service: this.state.service.id,
            },
        };
    }

    viewLandingPage = async () => {
        let userCompService = this.UserCompanyService();
        await getUserPermissions(userCompService, this.successViewLandPage);
        
        const hide = message.loading('Redirecting...', 0);
        // Dismiss manually and asynchronously
        await setTimeout(hide, 2000);
        var bearer = localStorage.getItem('token');
        var company = localStorage.getItem('management');
        if (company != null) {
            var companyData = JSON.parse(company);
            //await setCompToHeader(companyData.managingCompany);
        }
        var comp = companyData.managingCompany;
        let CompanyString = JSON.stringify(comp, null, 2);
        var externalToken = {
            Bearer: bearer,
            Company: btoa(CompanyString),
            CompanyId: this.state.compPerms.Id,
            Logo: this.state.compPerms.Logo,
            Banner: this.state.compPerms.Banner,
            CompanyName: this.state.compPerms.CompanyName,
            Connected: this.state.compPerms.Connected,
            Permissions: this.state.compPerms.Permissions,
            Role: this.state.compPerms.Role,
            ServiceId: this.state.compPerms.ServiceId,
            ServiceCode: this.state.compPerms.ServiceCode,
            Layout: this.state.compPerms.Layout 
        }
        delete axios.defaults.headers.common['BSD-Company'];
        await axios.post('/api/v1/external/glt', externalToken)
            .then(async (res) => {
                window.location.href = this.props.service.service.url + "?glt=" + res.data;
        });
    };

    viewSettingPage = async () => {
        let userCompService = this.UserCompanyService();
        await getUserPermissions(userCompService, this.successUserPermissions);

        const hide = message.loading('Redirecting...', 0);
        // Dismiss manually and asynchronously
        await setTimeout(hide, 2000);

        let serviceName = this.props.service.service.url.replace('/home', '');
        window.location.href = `${serviceName}/overview`;
    };

    successUserPermissions = async (data: Permission) => {
        this.props.userCompanyPermission(data);
    };

    successViewLandPage = async (data: PermissionCompany) => {
        let perms: PermissionCompany = {
            company: data.company,
            relation: {
                connected: data.relation.connected,
                permissions: data.relation.permissions,
                role: data.relation.role,
            },
            service: data.service,
        };
        let companyPerms = {
            Id: data.company.id,
            Logo: data.company.logoImage,
            Banner: data.company.bannerImage,
            CompanyName: data.company.legalEntityName,
            Connected: data.relation.connected,
            Permissions: data.relation.permissions,
            Role: data.relation.role,
            ServiceId: data.service.id,
            ServiceCode: data.service.code,
            Layout: data.service.layout
        };
        
        await this.setState({ compPerms: companyPerms });
        //console.log(this.state.compPerms.Permissions);
        this.props.userCompanyPermission(perms);
    };
    render() {
        return (
            <Col span={8} style={{ height: '320px' }}>
                <Card
                    hoverable={true}
                    cover={
                        <img
                            style={{ height: '110px', objectFit: 'contain' }}
                            alt="Bit Space Development Ltd."
                            src="https://janustourbuilder.s3.ca-central-1.amazonaws.com/favicon_bsd_420.png"
                        />
                    }
                    actions={
                        (this.props.managementRole ===
                            COMPANY_OWNER || this.props.managementRole ===
                            COMPANY) ?
                            [
                        <Button
                            type="link"
                            htmlType="button"
                                    icon={
                                        (this.props.service.status) ?
                                            <CheckOutlined />
                                            : <PayCircleOutlined />
                                    }
                            onClick={() => this.props.openPaymentModal(this.props.service.service.id)}
                            disabled={!this.props.service.service.enabled||this.props.service.status}
                        >
                        </Button>, <Button
                            htmlType="button"
                            type="link"
                            icon={<EyeOutlined />}
                            onClick={this.viewLandingPage}
                            disabled={!this.props.service.status}
                        >
                            </Button>
                        ] :[
                        <Button
                            htmlType="button"
                            type="link"
                            icon={<EyeOutlined />}
                            onClick={this.viewLandingPage}
                            disabled={!this.props.service.status}
                        >
                        </Button>,
                    ]}
                >
                    <Meta
                        title={this.props.service.service.name}
                        description={this.props.service.service.description}
                    />
                </Card>
            </Col>
        );
    }
}

function mapToStateToProps(state: any) {
    return {
        userId: state.auth.user.nameid,
    };
}

export default connect(mapToStateToProps, { userCompanyPermission })(Service);
