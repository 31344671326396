import React, { Component } from 'react'
import { BulbOutlined, EnvironmentOutlined, HomeOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Form } from 'antd';

class PersonalInfo extends Component<any, {}> {
    render() {
        return (
            <div>
                <Form.Item validateStatus={this.props.values.errors.jobPosition ? 'error' : ''} help={this.props.values.errors.jobPosition || ''}>
                    <Input
                        prefix={<BulbOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="text" value={this.props.values.jobPosition}
                        placeholder="Job Position" onChange={this.props.handleChange('jobPosition')}
                    />
                </Form.Item>
                <Form.Item validateStatus={this.props.values.errors.address ? 'error' : ''} help={this.props.values.errors.address || ''}>
                    <Input
                        prefix={<HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="text" value={this.props.values.address}
                        placeholder="Address" onChange={this.props.handleChange('address')}
                    />
                </Form.Item>
                <Form.Item validateStatus={this.props.values.errors.city ? 'error' : ''} help={this.props.values.errors.city || ''}>
                    <Input
                        prefix={<EnvironmentOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="text" value={this.props.values.city}
                        placeholder="City" onChange={this.props.handleChange('city')}
                    />
                </Form.Item>
                <Form.Item validateStatus={this.props.values.errors.province ? 'error' : ''} help={this.props.values.errors.province || ''}>
                    <Input
                        prefix={<EnvironmentOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="text" value={this.props.values.province}
                        placeholder="Province" onChange={this.props.handleChange('province')}
                    />
                </Form.Item>
                <Form.Item validateStatus={this.props.values.errors.postalCode ? 'error' : ''} help={this.props.values.errors.postalCode || ''}>
                    <Input
                        prefix={<EnvironmentOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="text" value={this.props.values.postalCode}
                        placeholder="Postal Code" onChange={this.props.handleChange('postalCode')}
                    />
                </Form.Item>
            </div>
        );
    }
}

export default PersonalInfo