import jwtDecode from 'jwt-decode';
import { UserFromToken } from '../../interfaces/UserFromToken';
import { BeginningOfTime } from '../ConstantDirectory';
import setAuthTokenToHeader from '../SetHeaders/authToken';
import { setCurrentUser } from '../../actions/authActions';
import { emptyManagement } from '../../actions/management-action/management-action';

export function authOnRefresh(store: any) {
    let localStorageToken = localStorage.token ? localStorage.token : '';
    if (localStorageToken) {
        let JwToken: UserFromToken = jwtDecode(localStorageToken);
        if (BeginningOfTime > JwToken.exp) {
            localStorage.clear();
            setAuthTokenToHeader(false);
            store.dispatch(setCurrentUser({}));
            store.dispatch(emptyManagement());
        } else {
            setAuthTokenToHeader(localStorageToken);
            store.dispatch(setCurrentUser(JwToken));
            authTokenEventListener(store);
        }
    }
}

function authTokenEventListener(store: any) {
    window.addEventListener(
        'storage',
        () => {
            localStorage.clear();
            setAuthTokenToHeader(false);
            store.dispatch(setCurrentUser({}));
            store.dispatch(emptyManagement());
        },
        false
    );
}
