import React, { Component } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Tree, Collapse, List, Avatar, Button, Modal } from 'antd';

const { Panel } = Collapse;
const { confirm } = Modal;

class AllUsers extends Component<any, any> {

    deleteUser = async (userId: string) => {
        await confirm({
            title: 'Are you sure you want to remove this user from this company?',
            okText: "Delete",
            onOk: async () => {
                await this.props.deleteUser(userId, this.props.companyId)
            },
            onCancel() {
            },
        });
    }

    render() {
        return (
            <Collapse>
                <Panel header="Users" key="Users">
                        <List
                        dataSource={this.props.users.filter((data: any) => (data.firstName !== 'shared' && data.lastName !== 'content' && data.firstName !== null))}
                            renderItem={(user: any) => (
                                <List.Item
                                    actions={[
                                        <Button
                                            key="delete"
                                            type="primary"
                                            danger
                                            icon={<DeleteOutlined />}
                                            onClick={() =>
                                                this.deleteUser(user.id)
                                            }
                                        />,
                                    ]}
                                >
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar src={user.avatarImage} />
                                        }
                                        title={
                                            <a href={`/profile?${user.id}`}>
                                                {`${user.firstName} ${user.lastName} (${user.email})`}
                                            </a>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </Panel>
            </Collapse>
        );
    }
}

export default AllUsers;
