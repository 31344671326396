import React, { Component } from 'react'
import { ArrowUpOutlined, CheckOutlined, CloseOutlined, MailOutlined, ShopOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Button, message, Switch, notification, Form } from 'antd';
import { createInviteCompanyOwnerModel, InviteCompanyOwnerState } from './modules/invite-company-owner';
import { isValid } from '../../../utils/validate';
import { SEND_COMPANY_OWNER_INVITE } from '../routes';
import axios from 'axios';

const { Option } = Select;

class InviteCompanyOwner extends Component<any, InviteCompanyOwnerState> {
    constructor(props: any){
        super(props);
        this.state = {
            email: '',
            entityName: '',
            role: '',
            isOwner: false,
            userRole: '',
            disabled: false,
            roles: [],
            parentCompany: '',
            errors: [],
            loading: false
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount(){
        await this.setState({ userRole: this.props.auth.user.type, isOwner: true, role: 'Company Owner',
            roles: this.props.roles, loading: false, parentCompany: this.props.company.id });
    }

    selectionOfRoles() {
        let items = [];         
        for (let i = 0; i < this.state.roles.length; i++)
        {             
            items.push(
                <Option key={this.state.roles[i].id}
                    value={this.state.roles[i].name}>
                    {this.state.roles[i].name}
                </Option>
            );   
        }
        return items;
    }

    handleChange = (input: any) => async (e: any) => {
        if(typeof e === 'object')
        {
            this.setState({ [input]: e.target.value } as any);
        }
        else
        {
            if(input === 'isOwner')
            {
                if(!this.state.isOwner)
                {
                    await this.setState({ disabled: true, [input]: e, role: 'Company Owner' } as any);
                }
                else
                {
                    await this.setState({ disabled: false, [input]: e, role: 'User' } as any);
                }
            }
            else if(input === 'role')
            {
                if(e === 'Company Owner')
                {
                    await this.setState({ disabled: true, isOwner: true, role: 'Company Owner' } as any);
                }
                else
                {
                    await this.setState({ disabled: false, isOwner: false, role: 'User' } as any);
                }
            }
            else
            {
                await this.setState({ [input]: e } as any);
            }
        }
        let invitation = createInviteCompanyOwnerModel(this.state);
        let checker = isValid(invitation);
        await this.setState({ errors: checker.errors, loading: false });
    }

    async onSubmit(e: any) {
        //e.preventDefault();
        let invitation = createInviteCompanyOwnerModel(this.state);
        let checker = isValid(invitation);
        if(checker.isValid)
        {
            if(this.state.role === 'Company Owner')
            {
                await axios.post(SEND_COMPANY_OWNER_INVITE, invitation)
                .then(async (res) => {
                    notification.open({
                        message: 'Successfully Invited',
                        description:
                            `You have successfully sent an invitation to ${this.state.email}.`,
                        icon: <ArrowUpOutlined style={{ color: '#108ee9' }} />,
                    });
                    await this.setState({ errors: [], email: '', isOwner: false, role: '',
                        entityName: '', disabled: false, loading: false });
                })
                .catch(async (err) => {
                    await this.setState({ loading: false });
                    message.error(err.response.data);
                });
            }
            else
            {
                this.state.errors['role'] = "Needs to be the owner.";
                await this.setState({ errors: this.state.errors, loading: false });
            }
        }
        else
        {
            await this.setState({ errors: checker.errors, loading: false });
        }
    }

    render() {
        return (
            <div>
                <Form onFinish={this.onSubmit} className="login-form">
                    <Form.Item validateStatus={this.state.errors.entityName ? 'error' : ''} help={this.state.errors.entityName || ''}>
                        <Input
                            prefix={<ShopOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="text" value={this.state.entityName}
                            placeholder="Company Name" onChange={this.handleChange('entityName')}
                        />
                    </Form.Item>
                    <Form.Item validateStatus={this.state.errors.email ? 'error' : ''} help={this.state.errors.email || ''}>
                        <Input
                            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="email" value={this.state.email}
                            placeholder="Email" onChange={this.handleChange('email')}
                        />
                    </Form.Item>
                    <Form.Item validateStatus={this.state.errors.role ? 'error' : ''} help={this.state.errors.role || ''}>
                        <Select defaultValue={this.state.role} value={this.state.role} disabled>
                            <Option value="">Select One</Option>
                            {this.selectionOfRoles()}
                        </Select>
                    </Form.Item>
                    <Form.Item validateStatus={this.state.errors.isOwner ? 'error' : ''} help={this.state.errors.isOwner || ''}>
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={this.state.isOwner}
                            onClick={this.handleChange('isOwner')}
                            checked={this.state.isOwner}
                            disabled
                        /> Owner of Company
                    </Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button"
                        loading={this.state.loading}>
                        Invite User
                    </Button>
                </Form>
            </div>
        );
    }
}

export default InviteCompanyOwner
