import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { PROFILE } from '../../components/routes';
import ProfilePage from '../../components/Profile/ProfilePage';
import ResetPasswordLoggedIn from '../../components/Profile/ResetPasswordLoggedIn';
import ProfileSettings from '../../components/Profile/ProfileSettings';
import { Typography, Skeleton, message, Tabs, Row, Col, Descriptions, Avatar } from 'antd';

class Profile extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            profile: {},
            loading: true
        };
    }

    async componentDidMount() {
        const queryString = this.props.location.search.replace('?', '');
        if (this.props.location.search !== "") {
            await axios.get(`${PROFILE}/${queryString}`)
                .then(async (res) => {
                    await this.setState({ profile: res.data[0], loading: false });

                })
                .catch(async (err) => {
                    message.error(`${err.response.status} ${err.response.statusText}`);
                });
        }
        else {
            await axios.get(PROFILE)
                .then(async (res) => {
                    let profile = res.data;
                    profile.id = queryString;
                    await this.setState({ profile: profile, loading: false });
                })
                .catch(async (err) => {
                    message.error(`${err.response.status} ${err.response.statusText}`);
                });
        }
    }

    render() {
        return (
            <div>
                {
                    (this.state.loading) ?
                        <Skeleton active />
                        :
                        <div >
                            <Row>
                                <Col id="main" xs={24} sm={24} md={18} style={{ display: "inline-block", marginBottom: "10px" }}>
                                    <Tabs defaultActiveKey="1"
                                        tabPosition={'left'}
                                        items={[
                                            {
                                                key: "1",
                                                label: "Profile",
                                                children: (
                                                    <ProfilePage
                                                        profile={this.state.profile}
                                                    />
                                                )
                                            },
                                            {
                                                key: "2",
                                                label: "Edit Profile",
                                                children: (
                                                    <ProfileSettings
                                                        profile={this.state.profile}
                                                    />
                                                )
                                            },
                                            {
                                                key: "3",
                                                label: "Reset Password",
                                                children: (
                                                    <ResetPasswordLoggedIn
                                                        profile={this.state.profile}
                                                    />
                                                )
                                            }

                                        ]}
                                    />


                                </Col>

                                <Col xs={24} sm={24} md={6} style={{ display: "inline-block", marginBottom: "10px" }}>

                                    <div style={{ paddingLeft: "30px" }}>
                                        <Descriptions title={this.props.management.managingCompany
                                            .legalEntityName} column={1}>

                                            <Descriptions.Item label="">
                                                <Avatar
                                                    shape="square"
                                                    size={84}
                                                    src={
                                                        this.props.management.managingCompany
                                                            .logoImage
                                                    }
                                                ></Avatar>{' '}
                                                {this.props.management.managingCompany.name}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Description">
                                                {
                                                    this.props.management.managingCompany
                                                        .description
                                                }
                                            </Descriptions.Item>

                                        </Descriptions>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                }
            </div>
        )
    }
}

function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,
    };
}

export default connect(mapToStateToProps)(Profile);