import axios from 'axios';
import { errorCallback } from '../ErrorCallback';
import { LOGIN } from '../../Janus/components/routes';
import { USER_LOGIN_PERMS } from '../Routes';

export async function UserLogin(Model: any, callback: Function) {
    await axios
        .put(LOGIN, {
            email: Model.email,
            password: Model.password,
        })
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorCallback != null) {
                await errorCallback(err);
            }
        });
}

export async function UserLoginPerms(callback: Function, data?: string) {
    if (data == undefined && data == null)
        data = "VRSafety";
    await axios
        .post(USER_LOGIN_PERMS, { service: data })
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data,data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorCallback != null) {
                await errorCallback(err);
            }
        });
}
