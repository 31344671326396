import React, { Component } from 'react';
import ManageCompanies from '../../../components/ManageCompanyCard/ManageCompanies';
import { Skeleton, message } from 'antd';
import axios from 'axios';

interface DefaultProps
{
    history: any;
    location: any;
    match: any;
    staticContext: any;
    changeMenu: any;
}

class OwnedCompanies extends Component<DefaultProps, any> {
    constructor(props: DefaultProps){
        super(props);
        this.state = {
            ownedCompanies: [],
            loading: true
        }
    }

    async componentDidMount(){
        await axios.get('/api/v1/companies/owned')
        .then(async (res) => {
            this.setState({ ownedCompanies: res.data });
        })
        .catch(async (err) => {
            console.log(err.response);
            message.error('Something went wrong check the console.');
        });

        this.setState({ loading: false });
    }

    render() {
        return (
            <div>
                <h1>Owned Companies</h1>
                <Skeleton loading={this.state.loading} active>
                <ManageCompanies
                    companies={this.state.ownedCompanies}
                    menuName={'Company Owner'}
                />
                </Skeleton>
            </div>
        )
    }
}

export default OwnedCompanies;