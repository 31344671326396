import React, { Component } from 'react';
import { Tabs } from 'antd';
import { ServiceTab } from './ServiceTab';

class ServicesOverview extends Component<any, any> {
    render() {
        const items = this.props.subscriptions.map((subscription: any, index: number) => {
            const id = String(index);
            return {
                key: `${subscription.service.id}`,
                label: `${subscription.service.name}`,
                disabled: !subscription.status,
                children: (
                    <ServiceTab
                        service={subscription.service}
                        companyConnections={this.props.companyConnections}
                        companySharingToggle={this.props.companySharingToggle}
                    />
                    )
             }
        }
        )
        return (
            <Tabs
                tabPosition={'left'}
                items={items}
            />
        )
    }
}

export default ServicesOverview;