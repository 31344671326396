import React, { Component } from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Result, Form } from 'antd';
import CompanyInfo from './CompanyInfo';
import CompanyExtraInfo from './CompanyExtraInfo';

class CreateCompanyForm extends Component<any, {}> {
    showComponents(){
        switch(this.props.values.step) {
            case 1:
                return (
                    <div>
                        <CompanyInfo
                            handleChange={this.props.handleChange}
                            values={this.props.values}
                            industries={this.props.industries}
                            sizes={this.props.sizes}
                        />
                        <Button type="primary" onClick={this.props.nextStep}>
                            Continue
                            <RightOutlined />
                        </Button>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <CompanyExtraInfo
                            handleChange={this.props.handleChange}
                            values={this.props.values}
                        />
                        <Button type="primary" onClick={this.props.prevStep}>
                            <LeftOutlined />
                            Take me back
                        </Button>
                        <span>  </span>
                        <Button type="primary" htmlType="submit" className="login-form-button" loading={this.props.values.loading}>
                            Create Company
                        </Button>
                    </div>
                );
            case 3:
              return (
                <Result
                    status="success"
                    title="Successfully Created!"
                    subTitle="Company was created without a problem."
                    extra={[]}
                />
              );
          }
    }

    render() {
        return (
            <Form onFinish={ this.props.onSubmit } className="login-form">
                { this.showComponents() }
            </Form>
        )
    }
}

export default CreateCompanyForm;