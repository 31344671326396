import React, { Component } from 'react';
import qs from 'qs';
import axios from 'axios';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Skeleton, Result, Row, Col, Form } from 'antd';
import { isValid, objectToFormData } from '../../../utils/validate';
import { RESET_PASSWORD, RESET_PASSWORD_TOKEN } from '../../components/routes';
import { Link } from 'react-router-dom';

class ResetPasswordLoggedIn extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            code: '',
            email: '',
            password: '',
            errors: [],
            problem: '',
            step: 1,
            waiting: true,
            loading: true,
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        this.getToken();
    }
    async getToken()
    {
        await axios
            .get(RESET_PASSWORD_TOKEN+"/"+this.props.profile.id)
            .then(async (res) => {
                await this.setState({
                    code: res.data,
                    email:this.props.profile.email
                });
                this.setState({ loading: false, waiting: false });
            })
            .catch(async (err) => {
                console.error(err);
                if (err.hasOwnProperty("response")) {
                    this.setState({
                        problem: err.response.data[0].description,
                        waiting: false,
                        step: 3,
                    });
                }
            });
       
    }

    async onSubmit(e: any) {
        //e.preventDefault();
        this.setState({ waiting: true });
        let resetPasswordModel = this.ResetPasswordViewModel(this.state);
        let checker = isValid(resetPasswordModel);
        if (checker.isValid) {
            let formData = objectToFormData(resetPasswordModel);
            await axios
                .post(RESET_PASSWORD, formData)
                .then(async (res) => {
                    this.setState({ waiting: false, step: 2 });
                })
                .catch(async (err) => {
                    this.setState({
                        problem: err.response.data[0].description,
                        waiting: false,
                        step: 3,
                    });
                });
        }
        this.setState({ errors: checker.errors, waiting: false });
    }

    ResetPasswordViewModel(value: any) {
        return {
            email: value.email,
            token: value.code,
            password: value.password,
        };
    }

    handleChange = (input: any) => (e: any) => {
        this.setState({ [input]: e.target.value } as any);
        let checker = isValid(this.state);
        this.setState({ errors: checker.errors, loading: false });
    };

    showComponent() {
        switch (this.state.step) {
            case 1:
                return (
                    <Form onFinish={this.onSubmit} className="login-form">
                        <Form.Item
                            validateStatus={
                                this.state.errors.email ? 'error' : ''
                            }
                            help={this.state.errors.email || ''}
                        >
                            <Input
                                prefix={
                                    <MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                                }
                                type="text"
                                value={this.state.email}
                                placeholder="Email"
                                onChange={this.handleChange('email')}
                            />
                        </Form.Item>
                        <Form.Item
                            validateStatus={
                                this.state.errors.password ? 'error' : ''
                            }
                            help={this.state.errors.password || ''}
                        >
                            <Input
                                prefix={
                                    <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                                }
                                type="password"
                                value={this.state.password}
                                placeholder="Password"
                                onChange={this.handleChange('password')}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                htmlType="submit"
                                className="bsd-btn-blue"
                                loading={this.state.waiting}
                            >
                                Submit!
                            </Button>
                        </Form.Item>
                    </Form>
                );
            case 2:
                return (
                    <Result
                        status="success"
                        title="Your password has been changed."
                        subTitle="Thank you for changing you password!"
                        
                    />
                );
            case 3:
                return (
                    <Result
                        status="warning"
                        title={this.state.problem}
                        subTitle="Try to reset your password through email."
                        extra={[
                            <Button className="bsd-btn-blue">
                                <Link to={`/forgot-password`}>
                                    Forgot Password
                                </Link>
                            </Button>,
                        ]}
                    />
                );
        }
    }

    render() {
        return (
            <div className="ant-card">
                <Row>
                    <Col span={2}></Col>
                    <Col span={22} style={{ textAlign: 'center' }}>
                        <h1>Reset Password</h1>
                        {this.state.loading ? (
                            <Skeleton active />
                        ) : (
                                this.showComponent()
                            )}
                    </Col>
                    <Col span={2}></Col>
                </Row>
            </div>
        );
    }
}

export default ResetPasswordLoggedIn;
