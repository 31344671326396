import React, { Component } from 'react';
import {
    HomeOutlined,
    LogoutOutlined,
    SettingOutlined,
    StopOutlined,
    UserOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    SmileOutlined
} from '@ant-design/icons';
import { Layout, Button, Menu, message, Typography, Avatar, Dropdown, Row, MenuProps } from 'antd';
import {
    ROOT_ROLE,
    COMPANY_OWNER_LOGIN,
    COMPANY_ADMIN_LOGIN,
    COMPANY_MANAGER_LOGIN,
    VRSAFETY_LOGIN
} from './UserMenuTypes';
import { NavLink } from 'react-router-dom';
import logo from '../Janus/assets/VR_Safety_Header_2_colour.png';

const { Header } = Layout;
const { Text } = Typography;

const UserInfoLayout = (props: any) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                float: 'right',
                paddingTop: '12px',
            }}
        >
            {props.auth.user.avatar !== '' ? (
                <Avatar
                    style={{ marginTop: 'auto' }}
                    size="large"
                    src={props.auth.user.avatar}
                ></Avatar>
            ) : (
                <Avatar
                    style={{ marginTop: 'auto' }}
                    size="large"
                    icon={<UserOutlined />}
                ></Avatar>
            )}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: 1,
                    padding: '0 20px',
                }}
            >
                <Text className={props.fontColor}
                //style={{ color: '#0D4D9B' }}
                >
                    Hello {props.auth.user.display_name}
                </Text>
                <Text strong={true} className={props.fontColor}
                //style={{ color: '#0D4D9B' }}
                >
                    {props.management.managingCompany.legalEntityName === ''
                        ? ''
                        : props.management.managingCompany.legalEntityName}
                </Text>
            </div>
        </div>
    );
};

class Head extends Component<any, {}> {
    constructor(props: any) {
        super(props);
        this.handleMenuClick = this.handleMenuClick.bind(this);

    }

    handleMenuClick = async (e: any) => {
        console.log(e);
        switch (e) {
            case 'placeholder':
                message.info('Placeholder');
                break;
            case 'user':
                this.props.defaultManagement();
                this.props.defaultUserCompPermission();
                break;
            case 'impersonate':
                message.success("Loading root settings!! Please Wait!!");
                await this.props.login(localStorage.getItem("rootToken"));
                await this.props.defaultManagement();
                await this.props.defaultUserCompPermission();
                await window.location.reload();
                break;
            case 'root':
                this.props.changeManagement({ menu: 'Root', role: 'Root' }, {});
                break;
            case 'logout':
                break;
            default:
                message.error('This should not happen.');
                break;
        }
    }

    direct = (location: string) => {
        window.location.assign(location);
    }

    checkIfUserIsRoot(): MenuProps['items'] {
        let menuItems: MenuProps['items'] = [];
        if (this.props.management.menuSettings.menu === 'Default') {
            menuItems.push({
                label: (
                    
                        <NavLink to={'/'} onClick={this.props.logout} className="linkWhiteText widthHeight100">
                           
                            <span>logout</span>
                        </NavLink>
                    
                ),
                key: "logout",
                icon: <LogoutOutlined style={{ margin: '0px 10px' }} />
            }

            );
        } else if (
            this.props.management.menuSettings.menu === COMPANY_OWNER_LOGIN ||
            this.props.management.menuSettings.menu === COMPANY_ADMIN_LOGIN
        ) {
            menuItems.push({
               label:(<NavLink to="/" className="linkWhiteText widthHeight100">                        
                        <span>Home</span>
                    </NavLink>
                ),
                key: "home",
                icon: <HomeOutlined style={{ margin: '0px 10px' }} />
            });
            menuItems.push({
                label:(
                    <NavLink
                        to="/profile"
                        className="linkWhiteText widthHeight100"
                    >
                        
                        <span>Profile</span>
                    </NavLink>
                ),
                key: "profile",
                icon: <UserOutlined style={{ margin: '0px 10px' }} />
            });
            menuItems.push({
                label: (
                    <NavLink to={'/'} onClick={this.props.logout} className="linkWhiteText widthHeight100">
                        <span>logout</span>
                    </NavLink>
                ),
                key: "logout",
                icon:<LogoutOutlined style={{ margin: '0px 10px' }} />
            });
        } else if (this.props.management.menuSettings.menu != COMPANY_OWNER_LOGIN &&
            this.props.management.menuSettings.menu !== COMPANY_ADMIN_LOGIN &&
            this.props.management.menuSettings.menu !== COMPANY_MANAGER_LOGIN &&
            this.props.management.menuSettings.menu !== VRSAFETY_LOGIN) {
            menuItems.push({
                label:("Stop managing"),
                key: "user",
                icon:<UserOutlined />
            });
            menuItems.push({
                label:(
                    <NavLink to={'/'} onClick={this.props.logout} className="linkWhiteText widthHeight100">
                        
                        <span>logout</span>
                    </NavLink>                
                ),
                key: "logout",
                icon:<LogoutOutlined style={{ margin: '0px 10px' }} />

            });
        }
        else {
            menuItems.push({
                label:(
                    <NavLink to={'/'} onClick={this.props.logout} className="linkWhiteText widthHeight100">                       
                        <span>logout</span>
                    </NavLink>
                ),
                key: "logout",
                icon: <LogoutOutlined style={{ margin: '0px 10px' }} />
            });
        }


        if (this.props.auth.user.type.indexOf('root') > -1 && this.props.management.menuSettings.menu != ROOT_ROLE) {
            menuItems.push({
               label:("Root Menu"),
                key: "root",
                icon: <UserOutlined />
            });
        }
        let rootToken = localStorage.getItem("rootToken");

        if (rootToken !== null && !this.props.auth.user.type.includes("root") && this.props.auth.user.type !== "root")
            menuItems.push({
               label:"Stop Impersonating",
                key: "impersonate",
                icon: <StopOutlined />
               }
            );
        return menuItems;
    }

    render() {        
        //const items: MenuProps['items'] = [
        //    {
        //        key: '1',
        //        label: (
                   
        //                "1st menu item"
                  
        //        ),
        //    },
        //    {
        //        key: '2',
        //        label: (
                   
        //                "2nd menu item "
                   
        //        ),
                
                
        //    },
        //    {
        //        key: '3',
        //        label: ("3rd menu item"),
                
        //    },
        //    {
        //        key: '4',
        //        danger: true,
        //        label: 'a danger item',
        //    },
        //];
        const items = this.checkIfUserIsRoot();
        const onClick: MenuProps['onClick'] = ({ key }) => {
            this.handleMenuClick(key);
        };
        const DisplayHeader = () =>
            (this.props.management.menuSettings.menu ===
                COMPANY_OWNER_LOGIN ||
                this.props.management.menuSettings.menu ===
                COMPANY_ADMIN_LOGIN ||
                this.props.management.menuSettings.menu ===
                COMPANY_MANAGER_LOGIN) ?
                <Header className="blackBG orangeText"
                    style={{
                        paddingLeft: 15,
                    }}
                >
                    <button className="StrippedButton" title="Return Home" onClick={() => this.direct("/")}>
                        <img alt="VR Safety"
                            style={{
                                marginRight: 15, fontSize: '20px',
                                height: 40, margin: "12px", padding: 10,
                            }}
                            src={logo}
                        />
                    </button>
                    <Text className="orangeText">Company Management</Text>

                    <button style={{ float: 'right', paddingTop: '12px', margin: "0 12px", }} className="StrippedButton" onClick={() => this.props.logout()}>
                        <Row style={{ lineHeight: 1.5, }} justify="center">
                            <LogoutOutlined style={{ fontSize: '16px' }} />
                        </Row>
                        <Row style={{ lineHeight: 1.5 }}>
                            Log out
                        </Row>
                    </button>
                    <Dropdown menu={{ items, onClick }} trigger={['click']}>
                        <Button
                            type="link"
                            icon={<SettingOutlined />}
                            className="orangeText"
                            style={{
                                fontSize: '20px',
                                margin: '14px 20px',
                                float: 'right',
                            }}
                        ></Button>
                    </Dropdown>

                    <UserInfoLayout {...this.props} fontColor={"orangeText"}></UserInfoLayout>

                </Header> :
                <Header className="blackBG tealText"
                    style={{
                        paddingLeft: 15,
                    }}
                >
                    <button className="StrippedButton" title="Return Home" onClick={() => this.direct("/")}>
                        <img alt="VR Safety"
                            style={{
                                marginRight: 15, fontSize: '20px',
                                height: 40, margin: "12px", padding: 10,
                            }}
                            src={logo}
                        />
                    </button>
                    <Text className="tealText">Company User</Text>

                    <button style={{ float: 'right', paddingTop: '12px', margin: "0 12px", }} className="StrippedButton" onClick={() => this.props.logout()}>
                        <Row style={{ lineHeight: 1.5, }} justify="center">
                            <LogoutOutlined style={{ fontSize: '16px' }} />
                        </Row>
                        <Row style={{ lineHeight: 1.5 }}>
                            Log out
                        </Row>
                    </button>
                    <Dropdown menu={{ items, onClick }} trigger={['click']}>
                        <Button
                            type="link"
                            icon={<SettingOutlined />}
                            className="tealText"
                            style={{
                                fontSize: '20px',
                                margin: '14px 20px',
                                float: 'right',
                            }}
                        ></Button>
                    </Dropdown>

                    <UserInfoLayout {...this.props} fontColor={"tealText"}></UserInfoLayout>
                </Header>
            ;
        return (
            <React.Fragment>
                {this.props.management.menuSettings.menu ===
                    COMPANY_OWNER_LOGIN ||
                    this.props.management.menuSettings.menu ===
                    COMPANY_ADMIN_LOGIN ||
                    this.props.management.menuSettings.menu ===
                    COMPANY_MANAGER_LOGIN ||
                    this.props.management.menuSettings.menu ===
                    VRSAFETY_LOGIN ? <DisplayHeader /> :
                    <Header
                        style={{
                            backgroundColor: 'rgb(230, 247, 255)',
                            color: '#0D4D9B',
                            paddingLeft: 15,
                            height: 'auto',
                        }}
                    >
                        {
                            (this.props.collapsed)

                                ?
                                <MenuUnfoldOutlined
                                    onClick={this.props.toggle}
                                    style={{ marginRight: 15, fontSize: '20px' }} />
                                :
                                <MenuFoldOutlined onClick={this.props.toggle}
                                    style={{ marginRight: 15, fontSize: '20px' }} />
                        }
                        <Text style={{ color: '#0D4D9B' }}>Janus View</Text>
                        <UserInfoLayout {...this.props}></UserInfoLayout>

                        <Dropdown menu={{ items, onClick }} trigger={['click']}>                           
                            <Button
                                type="link"
                                icon={<SettingOutlined />}
                                className="bsd-dark-blue"
                                style={{
                                    fontSize: '20px',
                                    margin: '14px 20px',
                                    float: 'right',
                                    }}
                                    onClick={e => e.preventDefault()}
                                ></Button>                                
                        </Dropdown>
                    </Header>
                }

            </React.Fragment>
        );
    }
}

export default Head;
