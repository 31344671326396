import React, { Component } from 'react';
import { MenuState } from './modules';
import { LayoutProps } from '../app/modules';
import { ReturnRoutes } from './returnRoutes';
import MenuList from './MenuList';
import { Spin } from 'antd';
import './NavMenu.css';
import {getBSDCompanyRole} from '../api/ApiGet/CompanyApiGet';
import axios from 'axios';
import { DM_SUBSCRIPTION } from '../Janus/components/routes';
import { getCurrentDate } from '../utils/dateUtils';

class NavMenu extends Component<LayoutProps, MenuState> {
    constructor(props: LayoutProps) {
        super(props);
        this.state = {
            isOpen: false,
            collapsed: false,
            links: [],
            loading: true,
            bsdCompany: []
        };
        this.logout = this.logout.bind(this);
    }

    async componentDidMount() {
        await this.setState({
            loading: false,
        });
        getBSDCompanyRole(
            this.bsdCompanySuccess,
            ((data:any)=>{}));
    }

    bsdCompanySuccess=async(data:any)=>{
        let bsdCompany= data.data[0];
        
        await axios
                .get(DM_SUBSCRIPTION, {
                    params: { company: bsdCompany.id, date: getCurrentDate(), serviceId:bsdCompany.serviceId },
                })
                .then(async (res) => {
                    bsdCompany.serviceUrl=res.data.url;
                    this.setState({bsdCompany:bsdCompany});
                })
                .catch(async (err) => {
                    console.log(err.response);
                });
    }

    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    async logout(e: any) {
        await this.props.emptyManagement();
        await this.props.logout();
    }

    render() {
        const { isAuthenticated } = this.props.auth;
        return (
            <div>
                {isAuthenticated ? (
                    this.state.loading ? (
                        <Spin />
                    ) : (
                        <MenuList
                            links={ReturnRoutes(
                                this.props.management.menuSettings.menu
                            )}
                            logout={this.logout}
                            bsdCompany={this.state.bsdCompany}
                        />
                    )
                ) : null}
            </div>
        );
    }
}

export default NavMenu;
