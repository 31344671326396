import { getCurrentDate } from '../../../../utils/dateUtils';

export interface CompanyUserRegister
{
    parentEntity: string;
    businessNumber: string;
    legalName: string;
    companySize: number;
    entityIndustry: number;
    phoneNumber: string;
    address: string;
    city: string;
    province: string;
    postalCode: string;
    numberOfEmployees: number;
    userName: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    jobPosition: string;
    userPhoneNumber: string;
    userAddress: string;
    userCity: string;
    userProvince: string;
    userPostalCode: string;
    role: string;
    isOwner: boolean;
    parent: string;
    submittedAt: string;
    step: number;
    industries: [],
    sizes: [],
    errors: any;
    loading: boolean;
    sameAsCompany: boolean;
    lockoutEnabled: boolean;
    timeZone: string;
}

function createCompanyUserInvitationModel(state: any){
    return {
        parentEntity: state.parentEntity,
        businessNumber: state.legalName.replace(/\s+/g, ''),
        legalName: state.legalName,
        companySize: parseInt(state.companySize, 10),
        entityIndustry: parseInt(state.entityIndustry, 10),
        phoneNumber: state.phoneNumber,
        address: state.address,
        city: state.city,
        province: state.province,
        postalCode: state.postalCode,
        numberOfEmployees: parseInt(state.numberOfEmployees, 10),
        userName: state.email,
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        password: state.password,
        jobPosition: state.jobPosition,
        userPhoneNumber: state.userPhoneNumber,
        userAddress: state.userAddress,
        userCity: state.userCity,
        userProvince: state.userProvince,
        userPostalCode: state.userPostalCode,
        role: state.role,
        isOwner: state.isOwner,
        parent: state.parent,        
        submittedAt: getCurrentDate(),
        lockoutEnabled: false,
        timeZone: state.timeZone,
        service: "default"
    }
}

export { createCompanyUserInvitationModel };