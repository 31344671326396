import axios from 'axios';
import { errorCallback } from '../ErrorCallback';
import {
    CONNECTED_COMPANIES,
    ALL_COMPANIES_CONNECTIONS,
    STAFF_NORMAL_USER_IN_COMP,
    UPDATE_USER_INTERNAL_CONNECTION,
    UPDATE_USER_ROLE_IN_COMP,
} from '../Routes';

export async function ConnectedCompanies(param: string, callback: Function) {
    await axios
        .get(CONNECTED_COMPANIES, {
            params: {
                Company: param,
            },
        })
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorCallback != null) {
                await errorCallback(err);
            }
        });
}

export async function Companies(params: string, callback: Function) {
    await axios
        .get(`${ALL_COMPANIES_CONNECTIONS}${params}`)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorCallback != null) {
                await errorCallback(err);
            }
        });
}

export async function UsersStaffInCompany(params: string, callback: Function) {
    await axios
        .get(`${STAFF_NORMAL_USER_IN_COMP}${params}`)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorCallback != null) {
                await errorCallback(err);
            }
        });
}

export async function UpdateUserInternalConnection(
    payload: any,
    callback: Function
) {
    await axios
        .patch(UPDATE_USER_INTERNAL_CONNECTION, payload)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorCallback != null) {
                await errorCallback(err);
            }
        });
}

export async function UpdateUserRoleInCompany(
    payload: any,
    callback: Function
) {
    await axios
        .patch(UPDATE_USER_ROLE_IN_COMP, payload)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorCallback != null) {
                await errorCallback(err);
            }
        });
}
