import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { isValid } from '../../../utils/validate';
import { login } from '../../../actions/authActions';
import { defaultManagement } from '../../../actions/management-action/management-action';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Layout, Form } from 'antd';
import {
    defaultUserCompPermission,
    userCompanyPermission,
} from '../../../actions/permissionActions';
import { UserLogin } from '../../../api/LoginApi/LoginApi';

const { Content } = Layout;

interface Props {
    login: (data: string) => {};
    defaultManagement: Function;
    defaultUserCompPermission: Function;
}

interface State {
    email: string;
    password: string;
    errors: any;
    loading: boolean;
}

class LoginRootForm extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            errors: [],
            loading: true,
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        this.setState({ loading: false });
    }

    async onSubmit(e: any) {
        this.setState({ loading: true });
        //e.preventDefault();
        let checker = isValid({
            email: this.state.email,
            password: this.state.password,
        });
        if (checker.isValid) {
            await UserLogin(
                {
                    email: this.state.email,
                    password: this.state.password,
                },
                this.UserLoginSuccess
            );
        }
        this.setState({ errors: checker.errors, loading: false });
    }

    UserLoginSuccess = async (data: any) => {
        await this.props.login(data);
        await this.props.defaultUserCompPermission();
        await this.props.defaultManagement();
    };

    handleChange = (input: any) => (e: any) => {
        this.setState({ [input]: e.target.value } as any);
    };

    render() {
        return (
            <Content style={{ padding: '0 50px' }}>
                <div
                    style={{
                        textAlign: 'center',
                        minHeight: 280,
                        maxWidth: 300,
                        margin: '0 auto',
                    }}
                >
                    <h1 className={`bsd-text-teal`}>Login</h1>
                    <Form onFinish={this.onSubmit} className="login-form">
                        <Form.Item
                            validateStatus={
                                this.state.errors.email ? 'error' : ''
                            }
                            help={this.state.errors.email || ''}
                        >
                            <Input
                                prefix={
                                    <MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                                }
                                type="email"
                                value={this.state.email}
                                placeholder="Email"
                                onChange={this.handleChange('email')}
                            />
                        </Form.Item>
                        <Form.Item
                            validateStatus={
                                this.state.errors.password ? 'error' : ''
                            }
                            help={this.state.errors.password || ''}
                        >
                            <Input
                                prefix={
                                    <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                                }
                                type="password"
                                value={this.state.password}
                                placeholder="Password"
                                onChange={this.handleChange('password')}
                            />
                        </Form.Item>
                        <Form.Item>
                            {this.state.loading ? (
                                <Button type="primary" loading>
                                    Loading
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className={`bsd-btn-teal`}
                                >
                                    Log in
                                </Button>
                            )}
                            <span className={"bsd-off-white"}>
                                {' '}
                                Or <Link className={`bsd-text-teal`} to={`/register`}>register now!</Link>
                            </span>
                            <br />
                            <Link className={`bsd-text-teal`} to={`/forgot-password`}>Forgot password</Link>
                        </Form.Item>
                    </Form>
                </div>
            </Content>
        );
    }
}

export default connect(null, {
    login,
    defaultManagement,
    defaultUserCompPermission,
    userCompanyPermission,
})(LoginRootForm);
