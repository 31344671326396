import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserAddOutlined } from '@ant-design/icons';
import { Row, Col, Spin, message, Button } from 'antd';
import { ManagementUserState } from './modules/interfaces';
import UserManagement from '../../components/ManagementUsers/UserManagement';
import {
    UsersStaffInCompany,
    UpdateUserInternalConnection,
    UpdateUserRoleInCompany,
} from '../../../api/CompanyApi/CompanyApi';
import { AccountRoles } from '../../../api/AccountAPI/AccountAPI';
import { getCurrentDate } from '../../../utils/dateUtils';
import PermissionModal from '../../components/ManagementUsers/PermissionModal';
import AddUserModal from '../../components/ManagementUsers/AddUserModal';
import { DeleteUserConnectionAndPermissionsFromCompany } from './modules/API';
// import { getServicesInCompany } from '../../api/ApiGet/UserManageApiGet';
import {
    requestOwnerTransfer,
    submitPermissionsChange,
} from '../../../api/ApiPost/UserManageApiPost';
import { isValid } from '../../../utils/validate';

export class ManagementUsers extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            normalUsers: [],
            staffUsers: [],
            modalVisible: false,
            selectedUser: {},
            accountRoles: [],
            permissionModalToggle: false,
            modalUser: {},
            create: false,
            read: false,
            update: false,
            delete: false,
            select_all: false,
            public: false,
            private: false,
            internal: false,

            addUserModalToggle: false,
        };
        this.submitPermissions = this.submitPermissions.bind(this);
    }

    async componentDidMount() {
        await UsersStaffInCompany(
            this.props.currentCompany.id,
            this.successUsersStaffInCompany
        );
        await AccountRoles(this.successAccountRoles);
        // await getServicesInCompany(
        //     this.props.currentCompany.id,
        //     this.successServicesInCompany
        // );
        await this.setState({ loading: false });
    }

    successAccountRoles = async (data: any) => {
        await this.setState({ accountRoles: data });
    };

    successUsersStaffInCompany = async (data: any) => {
        await this.setState({
            normalUsers: data.users,
            staffUsers: data.staffs,
        });
    };

    // successServicesInCompany = async (res: any) => {
    //     await this.setState({ services: res.data });
    // };

    handleModalCancel = async () => {
        await this.setState({ modalVisible: false });
    };

    handleModalPromotion = async (user: any) => {
        await this.setState({ selectedUser: user });
        await this.setState({ modalVisible: true });
    };

    /*handleUserInternalToggle = async (data: any) => {
        let newUser = this.state.selectedUser;
        newUser.internal = !newUser.internal;
        await this.setState({ selectedUser: newUser });
    };*/

    handleUserInternalToggle = async (data: any) => {
        let newUser = data;
        newUser.internal = !newUser.internal;
        await UpdateUserInternalConnection(
            {
                internal: newUser.internal,
                company: this.props.currentCompany.id,
                user: newUser.user.id,
                submittedAt: getCurrentDate(),
            },
            this.successUpdateInternalConnection
        );
        await UsersStaffInCompany(
            this.props.currentCompany.id,
            this.successUsersStaffInCompany
        );
    };

    handleUserPromoteChanges = async () => {
        await this.setState({ loading: true });
        await UpdateUserInternalConnection(
            {
                internal: this.state.selectedUser.internal,
                company: this.props.currentCompany.id,
                user: this.state.selectedUser.user.id,
                submittedAt: getCurrentDate(),
            },
            this.successUpdateInternalConnection
        );

        await UpdateUserRoleInCompany(
            {
                user: this.state.selectedUser.user.id,
                company: this.props.currentCompany.id,
                role: this.state.selectedUser.role,
                submittedAt: getCurrentDate(),
            },
            this.successUpdateUserRoleInCompany
        );

        await UsersStaffInCompany(
            this.props.currentCompany.id,
            this.successUsersStaffInCompany
        );
        await this.setState({ loading: false, modalVisible: false });
    };

    refreshListsOnUpdate = async () => {
        await UsersStaffInCompany(
            this.props.currentCompany.id,
            this.successUsersStaffInCompany
        );
    }

    successUpdateInternalConnection = async (data: string) => {
        message.success(data);
    };

    successUpdateUserRoleInCompany = async (data: string) => {
        message.success(data);
    };

    /*handleUserRoleChange = async (data: any) => {
        let newUser = this.state.selectedUser;
        newUser.role = data;
        await this.setState({ selectedUser: newUser });
    };*/

    handleUserRoleChange = async (data: any, item: any) => {
        let newUser = item;
        newUser.role = data;
        //await this.setState({ selectedUser: newUser });

        await UpdateUserRoleInCompany(
            {
                user: item.user.id,
                company: this.props.currentCompany.id,
                role: item.role,
                submittedAt: getCurrentDate(),
            },
            this.successUpdateUserRoleInCompany
        );

        await UsersStaffInCompany(
            this.props.currentCompany.id,
            this.successUsersStaffInCompany
        );
    };

    deleteCompanyConnection = async (userId: string) => {
        await this.setState({ loading: true });
        await DeleteUserConnectionAndPermissionsFromCompany(
            userId,
            this.props.currentCompany.id,
            this.successDelete
        );
        await UsersStaffInCompany(
            this.props.currentCompany.id,
            this.successUsersStaffInCompany
        );
        await this.setState({ loading: false });
    };

    successDelete = async (data: any) => {
        message.success(data);
    };

    showPermissionModal = async (user: any) => {
        let data = {
            id: user.id,
            user: user.user,
            role: user.role,
            permissions: user.permissions.map((item: any) => ({
                key: item.id,
                service: item.service,
                create: item.create ? 'True' : 'False',
                read: item.read ? 'True' : 'False',
                update: item.update ? 'True' : 'False',
                delete: item.delete ? 'True' : 'False',
            })),
        };
        await this.setState({ permissionModalToggle: true, modalUser: data });
    };

    handleChange = (input: any) => async (e: any) => {
        if (e.hasOwnProperty('target')) {
            if (input === 'select_all') {
                await this.setState({
                    create: !this.state.select_all,
                    read: !this.state.select_all,
                    update: !this.state.select_all,
                    delete: !this.state.select_all,
                    select_all: !this.state.select_all,
                } as any);
            } else if (['public', 'internal', 'private'].includes(input)) {
                switch (input) {
                    case 'public':
                        await this.setState({
                            public: true,
                            private: false,
                            internal: false,
                        });
                        break;
                    case 'private':
                        await this.setState({
                            public: false,
                            private: true,
                            internal: false,
                        });
                        break;
                    case 'internal':
                        await this.setState({
                            public: false,
                            private: false,
                            internal: true,
                        });
                        break;
                    default:
                        break;
                }
            } else {
                await this.setState({ [input]: !this.state[input] } as any);
            }
        } else {
            await this.setState({ [input]: e } as any);
        }
    };

    closePermissionModal = () => {
        this.setState({
            permissionModalToggle: !this.state.permissionModalToggle,
        });
    };

    async requestOwnerTranswer(data: any) {
        let request = {
            user: data.user.id,
            company: this.props.currentCompany?.legalEntityName,
            submittedAt: getCurrentDate(),
        };
        await requestOwnerTransfer(request, this.successOwnerTransfer);
    }

    successOwnerTransfer = async (res: any) => {
        message.success(res.data);
    };

    async submitPermissions(data: any) {
        let permissionChange = this.createPermission(data.user);
        let checker = isValid(permissionChange);
        if (checker.isValid) {
            await submitPermissionsChange(
                permissionChange,
                this.successPermissionChange
            );
        } else {
            message.error('Please select a service.');
        }
    }

    createPermission(user: any) {
        const { service, create, read, update, delete: boolean } = this.state;
        return {
            user: user.id,
            service,
            company: this.props.currentCompany.id,
            create,
            read,
            update,
            delete: boolean,
            submittedAt: getCurrentDate(),
        };
    }

    successPermissionChange = async (res: any) => {
        let permission = {
            key: res.data.id,
            service: res.data.service,
            create: res.data.create ? 'True' : 'False',
            read: res.data.read ? 'True' : 'False',
            update: res.data.update ? 'True' : 'False',
            delete: res.data.delete ? 'True' : 'False',
        };
        let user = this.state.modalUser;

        if (user.permissions.length > 0) {
            if (user.permissions.findIndex((oldpermission: any) => oldpermission.service == permission.service) == -1) {
                user.permissions.push(permission);
            }
            else {
                user.permissions = user.permissions.map((item: any) => {

                    if (item.service === permission.service) {
                        return permission;
                    }
                    return item;
                });
            }

        } else {
            user.permissions.push(permission);
        }
        this.setState({ user });
        message.success('Permissions Changed!');
    };

    actionAddUserModal = async () => {
        await this.setState({
            addUserModalToggle: !this.state.addUserModalToggle,
        });
    };

    render() {
        return (
            <div>
                {this.state.loading ? (
                    <div style={{ textAlign: 'center' }}>
                        <Spin size="large" />
                    </div>
                ) : (
                    <div>
                        <Row style={{ textAlign: "right", marginBottom: "20px", justifyContent: "end" }}>
                            <Button
                                type="primary"
                                icon={<UserAddOutlined />}
                                onClick={() => this.actionAddUserModal()}
                                shape="round"
                                size="large"
                            >
                                Add/Invite New Teammate
                            </Button>
                        </Row>


                        <Row style={{ background: "#fff", padding: "8px" }}>
                            <Col xs={24} sm={12} md={12}>
                                <UserManagement
                                    headerName="MANAGEMENT"
                                    initLoading={this.state.loading}
                                    users={this.state.staffUsers}
                                    promoteUserVisible={this.state.modalVisible}
                                    handleModalCancel={this.handleModalCancel}
                                    handleModalPromotion={
                                        this.handleModalPromotion
                                    }
                                    selectedUser={this.state.selectedUser}
                                    handleUserInternalToggle={
                                        this.handleUserInternalToggle
                                    }
                                    handleUserPromoteChanges={
                                        this.handleUserPromoteChanges
                                    }
                                    accountRoles={this.state.accountRoles}
                                    handleUserRoleChange={
                                        this.handleUserRoleChange
                                    }
                                    deleteCompanyConnection={
                                        this.deleteCompanyConnection
                                    }
                                    userPermissionVisible={true}
                                    showPermissionModal={
                                        this.showPermissionModal
                                    }
                                //showAddUserModal={this.actionAddUserModal}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={12}>
                                <UserManagement
                                    headerName="TEAMMATES"
                                    initLoading={this.state.loading}
                                    users={this.state.normalUsers}
                                    promoteUserVisible={this.state.modalVisible}
                                    handleModalCancel={this.handleModalCancel}
                                    handleModalPromotion={
                                        this.handleModalPromotion
                                    }
                                    selectedUser={this.state.selectedUser}
                                    handleUserInternalToggle={
                                        this.handleUserInternalToggle
                                    }
                                    handleUserPromoteChanges={
                                        this.handleUserPromoteChanges
                                    }
                                    accountRoles={this.state.accountRoles}
                                    handleUserRoleChange={
                                        this.handleUserRoleChange
                                    }
                                    deleteCompanyConnection={
                                        this.deleteCompanyConnection
                                    }
                                    userPermissionVisible={false}
                                    showPermissionModal={
                                        this.showPermissionModal
                                    }
                                //showAddUserModal={this.actionAddUserModal}
                                />
                            </Col>

                        </Row>
                    </div>
                )}
                {this.state.permissionModalToggle ? (
                    <PermissionModal
                        permissionModalToggle={this.state.permissionModalToggle}
                        closePermissionModal={this.closePermissionModal}
                        requestOwnerTranswer={this.requestOwnerTranswer}
                        submitPermissions={this.submitPermissions}
                        handleChange={this.handleChange}
                        services={this.props.services}
                        company={this.props.currentCompany}
                        user={this.state.modalUser}
                        create={this.state.create}
                        read={this.state.read}
                        update={this.state.update}
                        delete={this.state.delete}
                        select_all={this.state.select_all}
                    />
                ) : null}
                {this.state.addUserModalToggle ? (
                    <AddUserModal
                        company={this.props.currentCompany}
                        auth={this.props.auth}
                        roles={this.state.accountRoles}
                        closeAddUserModal={this.actionAddUserModal}
                        addUserModalToggle={this.state.addUserModalToggle}
                        refreshListsOnUpdate={this.refreshListsOnUpdate}
                    />
                ) : null}
            </div>
        );
    }
}

function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,
        currentCompany: state.currentCompany,
    };
}

export default connect(mapToStateToProps)(ManagementUsers);
