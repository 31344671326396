const months =["January","February","March","April","May","June","July","August","September","October","November","December"];
function getCurrentDate(){
    var today = new Date();
    var date = `${today.getFullYear()}-${twoDigits(today.getMonth()+1)}-${twoDigits(today.getDate())}`;
    var time = `${twoDigits(today.getHours())}:${twoDigits(today.getMinutes())}:${twoDigits(today.getSeconds())}`;
    var dateTime = `${date}T${time}`;
    return dateTime;
}

function twoDigits(number: number) {
    if(0 <= number && number < 10){
        return `0${number.toString()}`;
    }
    if(-10 < number && number < 0){
        return `-0${(-1*number).toString()}`;
    }
    return number.toString();
}

function betterDateFormat(dataDate: string){
    var date: any = dataDate.split("T")[0].split("-");
    date = `${date[0]}-${date[1]}-${date[2]}`;
    return date;
}

function getMonthAndYear(dataDate: string){
    var date = new Date(dataDate);
    return months[date.getMonth()] + " " + date.getFullYear(); 
}

function canadaDateFormat(dataDate: string){
    var date = mysqlToJsDate(dataDate);
    return `${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()} - ${formatTimeShow(date.getHours(), date.getMinutes())}`; 
}

function formatTimeShow(hours: number, minutes: number) {
    var h = hours % 12;
    return (hours < 10 ? '0' : '') + h + `:${minutes} ${(hours < 12 ? 'am' : 'pm')}`;
}

function mysqlToJsDate(date: string){
    let newDate = date.replace(/:/g, '-').replace("T", "-");
    let dateParts = newDate.split("-").map((item: string) => (parseInt(item, 10)));
    return new Date(dateParts[0], dateParts[1]-1, dateParts[2], dateParts[3], dateParts[4], dateParts[5]);
}

function createMySqlDate(dataDate:string){
    var today = new Date(dataDate);
    return today.getUTCFullYear() + "-" + twoDigits(1 + today.getUTCMonth()) + "-" + twoDigits(today.getUTCDate()) + " " + twoDigits(today.getUTCHours()) + ":" + twoDigits(today.getUTCMinutes()) + ":" + twoDigits(today.getUTCSeconds());
}

export { getCurrentDate, betterDateFormat, getMonthAndYear, canadaDateFormat, createMySqlDate };