import React, { Component } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Divider, Typography, Collapse, List } from 'antd';
import AvailableContent from './AvailableContent';
import SharedContent from './SharedContent';

const { Panel } = Collapse;

class ServicesBubble extends Component<any, any> {
    render() {
        return (
            <div>
                <Divider />
                <Collapse>
                    {this.props.subscriptions.map(
                        (subscription: any, index: any) => (
                            <Panel
                                header={subscription.service.name}
                                key={subscription.startAt}
                                //disabled={}
                                collapsible={(!subscription.status)?"disabled":"header"}
                                extra={
                                    <SettingOutlined
                                        onClick={(event: any) => {                                            
                                        }} />
                                }
                            >
                                <AvailableContent
                                    subscription={subscription}
                                    header="Available Content"
                                    content={subscription.content.content}
                                    viewContentWithPermissions={this.props.viewContentWithPermissions}
                                />
                                <Divider orientation="left">
                                    Unavailable content{' '}
                                    {subscription.content.unavailable}
                                </Divider>
                                <SharedContent
                                    subscription={subscription}
                                    header="Shared Content"
                                    content={subscription.content.sharedContent}
                                />
                            </Panel>
                        )
                    )}
                </Collapse>
            </div>
        );
    }
}

export default ServicesBubble;
