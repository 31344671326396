import React, { Component } from 'react';
import Service from './Service';
import { Row } from 'antd';

class AllServices extends Component<any, {}> {
    render() {
        return (
            <Row gutter={[8, 10]}>
                {this.props.services.map((service: any) => (
                    <Service
                        key={service.service.id}
                        service={service}
                        history={this.props.history}
                        payment={this.props.payment}
                        company={this.props.company}
                        openPaymentModal={this.props.openPaymentModal}
                        managementRole={this.props.managementRole}
                    />
                ))}
            </Row>
        );
    }
}

export default AllServices;
