import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isValid } from '../../../utils/validate';
import { message, Modal, Skeleton, Tabs } from 'antd';
import { getCompanyById, getCompanyUsers,
    getServicesInCompany, getUserConnections
} from '../../../api/ApiGet/UserManageApiGet';
import { requestOwnerTransfer, submitPermissionsChange,
    deleteUserRoleFromCompany, deleteUserConnectionFromCompany
} from '../../../api/ApiPost/UserManageApiPost';
import { getCurrentDate } from '../../../utils/dateUtils';
import PrivilegedUsers from '../../components/UserManagePermissions/PrivilegedUsers';
import ConnectedUsers from '../../components/UserManagePermissions/ConnectedUsers';
import PermissionModal from '../../components/UserManagePermissions/PermissionModal';
import ServicePermissionModal from '../../components/UserManagePermissions/ServicePermissionModal';
import { createServicePermission, updateServicePermission } from '../../../api/UserPermApi/UserPermApi';

const { confirm } = Modal;

export class UserManagePermissions extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            company: {},
            users: [],
            services: [],
            user: {},
            service: 0,
            create: false,
            read: false,
            update: false,
            delete: false,
            select_all: false,
            public: false,
            internal: false,
            private: false,
            permissionModalToggle: false,
            servicePermissionModalToggle: false,
            servicePermission: {},
            loading: true
        };
        this.submitPermissions = this.submitPermissions.bind(this);
        this.requestServicePermission = this.requestServicePermission.bind(this);
        this.successCreatedServicePermission = this.successCreatedServicePermission.bind(this);
    }

    async componentDidMount(){
        await getCompanyById(this.props.management.managingCompany.id, this.successCompanyById);
        await getCompanyUsers(this.state.company.id, this.successCompanyUsers);
        await getServicesInCompany(this.state.company.id, this.successServicesInCompany);
        await getUserConnections(this.state.company.id, this.successUserConnections);
        await this.setState({ loading: false });
    }

    successCompanyById = async (data: any) => {
        await this.setState({ company: data });
    }

    successCompanyUsers = async (res: any) => {
        await this.setState({ users: res.data.users });
    }

    successServicesInCompany = async (res: any) => {
        await this.setState({ services: res.data });
    }

    successUserConnections = async (res: any) => {
        await this.setState({ requests: res.data.requests, connectedUsers: res.data.connections });
    }

    showPermissionModal = async (user: any) => {
        let data = {
            id: user.id,
            user: user.user,
            role: user.role,
            permissions: user.permissions.map((item: any) => ({
                key: item.id,
                service: item.service,
                create: (item.create) ? 'True': 'False',
                read: (item.read) ? 'True': 'False',
                update: (item.update) ? 'True': 'False',
                delete: (item.delete) ? 'True': 'False'
            }))
        };
        await this.setState({ permissionModalToggle: true, user: data });
    };

    closePermissionModal = () => {
        this.setState({ permissionModalToggle: !this.state.permissionModalToggle });
    };

    handleChange = (input: any) => async (e: any) => {
        if(e.hasOwnProperty('target')) {
            if(input === 'select all'){
                await this.setState({
                    create: !this.state.create, read: !this.state.read,
                    update: !this.state.update, delete: !this.state.delete,
                    select_all: !this.state.select_all
                } as any);
            } else if(["public", "internal", "private"].includes(input)){
                switch(input){
                    case 'public':
                        await this.setState({ public: true, private: false, internal: false });
                        break;
                    case 'private':
                        await this.setState({ public: false, private: true, internal: false });
                        break;
                    case 'internal':
                        await this.setState({ public: false, private: false, internal: true });
                        break;
                    default:
                        break;
                }
            } else {
                await this.setState({ [input]: !this.state[input] } as any);
            }
        } else {            
            await this.setState({ [input]: e} as any);
        }
    }

    async requestOwnerTranswer(data: any){
        let request = {
            user: data.user.id,
            company: this.state.company?.legalEntityName,
            submittedAt: getCurrentDate()
        };
        await requestOwnerTransfer(request, this.successOwnerTransfer);
    }

    successOwnerTransfer = async (res: any) => {
        message.success(res.data);
    }

    async deleteRoleUserCompany(id: number) {
        confirm({
            title: 'Are you sure delete all of this users permissions?',
            content: 'There will be no permissions associated with this user. If you only meant to revoke one service you will need to readd the permissions individually',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk:() =>{
                deleteUserRoleFromCompany(id, this.successDelete);
            },
            onCancel() {
                
            },
        });
        
    }

    async deleteCompanyConnection(id: number){
        await deleteUserConnectionFromCompany(id, this.successDelete);
    }

    successDelete = async (data: any) => {
        //alert("returned from delete");
        //console.log(data);
        message.success(data);
    }

    async submitPermissions(data: any){
        let permissionChange = this.createPermission(data.user);
        let checker = isValid(permissionChange);
        if(checker.isValid)
        {
            await submitPermissionsChange(permissionChange, this.successPermissionChange);
        } else {
            message.error('Please select a service.');
        }
    }

    createPermission(user: any){
        const { service, create, read, update, delete: any, company } = this.state;
        return {
            user: user.id,
            service,
            company: company.id,
            create,
            read,
            update,
            delete: any,
            submittedAt: getCurrentDate()
        };
    }

    successPermissionChange = async (res: any) => {
        let permission = {
            key: res.data.id,
            service: res.data.service,
            create: (res.data.create) ? 'True': 'False',
            read: (res.data.read) ? 'True': 'False',
            update: (res.data.update) ? 'True': 'False',
            delete: (res.data.delete) ? 'True': 'False'
        }
        let user = this.state.user;
        if(user.permissions.length > 0){
            user.permissions = user.permissions.map((item: any) => {
                if(item.service === permission.service){
                    return permission;
                }
                return item;
            })
        } else {
            user.permissions.push(permission);
        }
        this.setState({ user });
        message.success('Permissions Changed!');
    }

    showServicePermissionModal = async (payload: any) => {
        await this.setState({ private: false, public: false, internal: false});
        let data = {
            id: payload.id,
            user: payload.user,
            role: payload.role,
            permissions: payload.permissions.map((item: any) => ({
                key: item.id,
                service: item.service,
                create: (item.create) ? 'True': 'False',
                read: (item.read) ? 'True': 'False',
                update: (item.update) ? 'True': 'False',
                delete: (item.delete) ? 'True': 'False'
            }))
        };
        let permission = {
            user: data.user.id,
            company: this.state.company.id,
            accessLevel: 0,
            submittedAt: getCurrentDate()
        };          
        await createServicePermission(permission, this.successCreatedServicePermission);
        await this.setState({ servicePermissionModalToggle: true, user: data });
    }

    async successCreatedServicePermission(data: any){
        switch(data.accessLevel)
        {
            case 0:
                await this.setState({ public: true });
                break;
            case 1:
                await this.setState({ internal: true });
                break;
            case 2:
                await this.setState({ private: true });
                break;
            default:
                break;
        }
        await this.setState({ servicePermission: data });
    }

    closeServicePermissionModal = () => {
        this.setState({ servicePermissionModalToggle: !this.state.servicePermissionModalToggle });
    };

    async requestServicePermission(data: any) {
        let accessLevel = 0;
        if(this.state.internal){
            accessLevel = 1
        } else if(this.state.private){
            accessLevel = 2
        }
        let payload = {
            user: data.user.id,
            company: this.state.company.id,
            accessLevel: accessLevel,
            submittedAt: getCurrentDate()
        };
        await updateServicePermission(payload, this.successCreatedServicePermission);
    }

    render() {
        return (
            <div>
                {
                    (this.state.loading) ?
                    <Skeleton active />
                    :
                    <div>
                        <h1>Manage Users Permissions</h1>
                            <Tabs
                                defaultActiveKey="1"
                                tabPosition='left'
                                items={[
                                    {
                                        label:"Authorized Users",
                                        key:"1",
                                        children:(
                                            <PrivilegedUsers
                                            users={this.state.users}
                                            showPermissionModal={this.showPermissionModal}
                                            vrsafetySub={this.state.services.find((service:any)=>service.service.id==1)}
                                            showServicePermissionModal={this.showServicePermissionModal}
                                            delete={this.deleteRoleUserCompany}
                                        />)
                                    },
                                    {
                                        label:`Connected Users`,
                                        key:"2",
                                        children:(
                                            <ConnectedUsers
                                                company={this.state.company}
                                                connectedUsers={this.state.connectedUsers}
                                                delete={this.deleteCompanyConnection}
                                            />)
                                    },
                                    
                                    ]}
                            
                        />
                    </div>
                }
                {(this.state.permissionModalToggle) ?
                    <PermissionModal
                        permissionModalToggle={this.state.permissionModalToggle}
                        closePermissionModal={this.closePermissionModal}
                        requestOwnerTranswer={this.requestOwnerTranswer}
                        submitPermissions={this.submitPermissions}
                        handleChange={this.handleChange}
                        services={this.state.services}
                        company={this.state.company}
                        user={this.state.user}
                        create={this.state.create}
                        read={this.state.read}
                        update={this.state.update}
                        delete={this.state.delete}
                    />
                    :
                    null
                }
                {(this.state.servicePermissionModalToggle) ?
                    <ServicePermissionModal
                        servicePermissionModalToggle={this.state.servicePermissionModalToggle}
                        closeServicePermissionModal={this.closeServicePermissionModal}
                        requestServicePermission={this.requestServicePermission}
                        servicePermission={this.state.servicePermission}
                        handleChange={this.handleChange}
                        user={this.state.user}
                        public={this.state.public}
                        internal={this.state.internal}
                        private={this.state.private}
                    />
                    :
                    null
                }
            </div>
        )
    }
}

function mapToStateToProps(state: any){
    return {
        auth: state.auth,
        management: state.management
    };
}

export default connect(mapToStateToProps, {})(UserManagePermissions);