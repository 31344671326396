import { SET_CURRENT_COMPANY, DEFUALT_CURRENT_COMPANY } from '../../actions/types';
import { CompanyConnection } from '../../interfaces/CompanyConnection';

interface ActionCompanyConnection{
    type: string;
    company: CompanyConnection;
}

const initialState: CompanyConnection = {
    id: '',
    logoImage: '',
    bannerImage: '',
    description: '',
    businessNumber: '',
    legalEntityName: '',
    connected: false,
    internal: false
}

export default (state: CompanyConnection = initialState, action: ActionCompanyConnection) => {
    switch (action.type) {
        case SET_CURRENT_COMPANY:
            return {
                id: action.company.id,
                logoImage: action.company.logoImage,
                bannerImage: action.company.bannerImage,
                description: action.company.description,
                businessNumber: action.company.businessNumber,
                legalEntityName: action.company.legalEntityName,
                connected: action.company.connected,
                internal: action.company.internal
            };
        case DEFUALT_CURRENT_COMPANY:
            return {
                id: action.company.id,
                logoImage: action.company.logoImage,
                bannerImage: action.company.bannerImage,
                description: action.company.description,
                businessNumber: action.company.businessNumber,
                legalEntityName: action.company.legalEntityName,
                connected: action.company.connected,
                internal: action.company.internal
            };
        default:
            return state;
    }
};
