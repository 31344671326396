import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './store/configureStore';
import { Router } from 'react-router';
import { managedOnRefresh } from './utils/OnRefresh/managedOnRefresh';
import { authOnRefresh } from './utils/OnRefresh/authOnRefresh';

const history = require('history').createBrowserHistory();

authOnRefresh(store);
managedOnRefresh(store);

const rootElement = document.getElementById('root');

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <App />
        </Router>
    </Provider>,
    rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
// serviceWorker.unregister();
