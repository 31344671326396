import axios from 'axios';
import { getCurrentDate } from '../../utils/dateUtils';
import { errorCallback } from '../ErrorCallback';
import { FULL_ENTITY, USER_COMPANY_USERS, USER_COMPANY_CONNECTIONS,
    COMPAY_SUBSCRIPTIONS, ENTITY } from '../Routes';

export async function getCompanyByName(payload: any, callback: Function){
    await axios.get( `${FULL_ENTITY}${payload}` )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}

export async function getCompanyById(payload: any, callback: Function){
    await axios.get( `${ENTITY}${payload}` )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res.data);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}

export async function getCompanyUsers(payload: any, callback: Function){
    await axios.get( USER_COMPANY_USERS,
        { params: { company: payload } } )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}

export async function getUserConnections(payload: any, callback: Function){
    await axios.get(USER_COMPANY_CONNECTIONS,
        { params: { company: payload } })
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}

export async function getServicesInCompany(companyId: string, callback: Function){
    await axios.get(COMPAY_SUBSCRIPTIONS, {
        params: { company: companyId, date: getCurrentDate() } })
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}