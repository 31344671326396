import axios from 'axios';
import { errorCallback } from '../ErrorCallback';
import { TRANSFER_OWNER, USER_PERMISSION_CHANGE,
    USER_PERMISSION_DELETE, USER_CONNECTION_DELETE } from '../Routes';

export async function requestOwnerTransfer(payload: object, callback: Function){
    await axios.post( TRANSFER_OWNER, payload )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}

export async function submitPermissionsChange(payload: object, callback: Function){
    await axios.post( USER_PERMISSION_CHANGE, payload )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}

export async function deleteUserRoleFromCompany(id: number, callback: Function){
    await axios.delete( `${USER_PERMISSION_DELETE}${id}` )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}

export async function deleteUserConnectionFromCompany(id: number, callback: Function){
    await axios.delete( `${USER_CONNECTION_DELETE}${id}` )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}