import axios from 'axios';
import { errorCallback } from '../ErrorCallback';
import { REQUEST_COMPANY_CONNECT, COMPANY_CONNECT_REQUESTS,
    COMPANY_CONNECT_ACTION, ALL_COMPANIES_CONNECTIONS } from '../Routes';

export async function RequestCompanyConnect(payload: any, callback: Function){
    await axios.post( REQUEST_COMPANY_CONNECT, payload )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res.data);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}

export async function CompanyConnectRequests(company: string, callback: Function){
    await axios.get( `${COMPANY_CONNECT_REQUESTS}${company}` )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res.data);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}

export async function CompanyConnectAction(payload: any, callback: Function){
    await axios.post( COMPANY_CONNECT_ACTION, payload )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res.data);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}

export async function CompanyConnections(company: string, callback: Function){
    await axios.get( `${ALL_COMPANIES_CONNECTIONS}${company}` )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res.data);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}