import React, { Component } from 'react';
import { SettingOutlined, ShopOutlined } from '@ant-design/icons';
import { List, Avatar, Button } from 'antd';

export class ServiceTabWithMe extends Component<any, any> {
    ShareServiceWithCompany(company: any){
        this.props.companySharingToggle(company, this.props.service.id);
    }

    render() {
        return (
            <List
                itemLayout="horizontal"
                dataSource={this.props.companyConnections}
                renderItem={(company: any) => (
                    <List.Item
                        actions={[
                            <Button
                            icon={<SettingOutlined />}
                            onClick={this.ShareServiceWithCompany.bind(this, company)}>
                                Manage
                            </Button>
                        ]}
                    >
                        <List.Item.Meta
                            avatar={
                                (company.logoImage === null) ?
                                <Avatar shape="square" size={64} icon={<ShopOutlined />} /> :
                                <Avatar shape="square" size={64}  src={company.logoImage} />
                            }
                            title={company.legalEntityName}
                            description={company.description}
                        />
                    </List.Item>
                )}
            />
        );
    }
}

export default ServiceTabWithMe