import React, { Component } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Result, Form } from 'antd';
import { Link } from 'react-router-dom';
import UserInfo from './UserInfo';
import PersonalInfo from './PersonalInfo';

class UserForm extends Component<any, {}> {
    showComponents() {
        switch (this.props.values.step) {
            case 1:
                return (
                    <div>
                        <UserInfo
                            handleChange={this.props.handleChange}
                            values={this.props.values}
                        />
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                className="bsd-btn-teal"
                                onClick={this.props.nextStep}
                            >
                                Continue
                                <RightOutlined />
                            </Button>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <PersonalInfo
                            handleChange={this.props.handleChange}
                            values={this.props.values}
                        />
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                className="bsd-btn-teal"
                                onClick={this.props.prevStep}
                                style={{ marginRight: '20px' }}
                            >
                                <LeftOutlined />
                                Take me back
                            </Button>
                            <Button
                                htmlType="submit"
                                className="bsd-btn-teal"
                                loading={this.props.values.loading}
                            >
                                Register
                            </Button>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <Result
                        status="success"
                        title="Successfully Registered!"
                        subTitle="Check your email for a confirmation email."
                        extra={[
                            <Link to={`/`} style={{ color: '#fff' }} key="1">
                                <Button type="primary">Login now!</Button>
                            </Link>,
                        ]}
                    />
                );
        }
    }

    render() {
        return (
            <Form onFinish={this.props.onSubmit} className="login-form">
                {this.showComponents()}
            </Form>
        );
    }
}

export default UserForm;
