import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Avatar, List } from 'antd';

export default function SharedContent(props: any) {
    return (
        <List
            header={<div>{props.header}</div>}
            dataSource={props.content}
            renderItem={(content: any) => (
                <List.Item key={content.id} actions={[]}>
                    <List.Item.Meta
                        avatar={
                            <Avatar
                                shape="square"
                                size={64}
                                icon={<InfoCircleOutlined />}
                            />
                        }
                        title={
                            <a href={content.contentLink}>
                                {content.contentId}
                            </a>
                        }
                        description="Place holder for now!"
                    />
                </List.Item>
            )}
        />
    );
}
