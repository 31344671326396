import { menuItem } from "../../interfaces/MenuSettings";
import { CompanyIcon, NotifCenterIcon, ProfileIcon } from "../icons/icons";


export const DefaultUser: menuItem[] = [
    {
        name: 'profile',
        url: 'profile',
        icon: 'ProfileIcon',
    },
    {
        name: 'notification-center',
        url: 'notification-center',
        icon: 'NotifCenterIcon',
    },
    {
        name: 'companies',
        url: 'companies',
        icon: 'CompanyIcon',
    },
    {
        name: 'Manage',
        subMenu: [
            {
                name: 'Owned Companies',
                url: 'owned-companies',
                icon: 'CompanyIcon',
            },
            {
                name: 'Admin Companies',
                url: 'admin-companies',
                icon: 'CompanyIcon',
            },
            {
                name: 'Managed Companies',
                url: 'managed-companies',
                icon: 'CompanyIcon',
            },
        ],
    },
];
