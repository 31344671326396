import { menuItem } from "../../interfaces/MenuSettings";

export const CompanyOwner: menuItem[] = [
    {
        name: 'profile',
        url: 'profile',
        icon: 'ProfileIcon',
    },
    {
        name: 'services',
        url: 'services',
        icon: 'ServicesIcon',
    },
    {
        name: 'company',
        url: 'company',
        icon: 'CompanyIcon',
    },
    {
        name: 'Manage User Permissions',
        url: 'manage/users/permissions',
        icon: 'CompanyIcon',
    },
    {
        name: 'Management',
        subMenu: [
            {
                name: 'Invitations Management',
                url: 'management/invitations',
                icon: 'CompanyIcon',
            },
            {
                name: 'Users Management',
                url: 'management/users',
                icon: 'CompanyIcon',
            },
        ],
    },
    {
        name: 'Sharing Centre',
        subMenu: [
            {
                name: 'Share With',
                url: 'sharing-centre/share-with',
                icon: 'CompanyIcon',
            },
            {
                name: 'Shared with me',
                url: 'sharing-centre/shared-with-me',
                icon: 'CompanyIcon',
            },
        ],
    },
];
