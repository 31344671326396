import React, { Component } from 'react'
import { LeftOutlined, RightOutlined, SmileOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Layout, message, notification, Result, Form } from 'antd';
import { connect } from 'react-redux';
import { addFlashMessage } from '../../../actions/flashMessage';
import { isValid } from '../../../utils/validate';
import axios from 'axios';
import { REGISTER } from '../routes';
import { Link } from 'react-router-dom';
import { getCurrentDate } from '../../../utils/dateUtils';
import UserInfo from './UserInfo';
import PersonalInfo from './PersonalInfo';

const { Content } = Layout;

interface Props {
    changeUrl: any
}

interface State {
    userName: string,
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    jobPosition: string,
    address: string,
    city: string,
    province: string,
    postalCode: string,
    submittedAt: string,
    errors: any,
    loading: boolean;
    timeZone: string;
    step: number;
}

class RegisterForm extends Component<Props, State> {
    constructor(props: Props){
        super(props);
        this.state = {
            userName: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            jobPosition: '',
            address: '',
            city: '',
            province: '',
            postalCode: '',
            submittedAt: '',
            timeZone:'',
            errors: [],
            loading: true,
            step: 1
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.showForm = this.showForm.bind(this);
        this.showButtons = this.showButtons.bind(this);
    }

    async componentDidMount(){
        this.setState({ loading: false });
    }

    // Proceed to next step
    nextStep = () => {        
        this.setState({ step: this.state.step + 1 });
    };

    // Go back to prev step
    prevStep = () => {
        this.setState({ step: this.state.step - 1 });
    };

    async onSubmit(e: any) {
        this.setState({ loading: true });
        let register = this.createRegisterModel(this.state);
        let checker = isValid(register);
        if(checker.isValid)
        {
            await axios.put(REGISTER, register)
            .then(async (res) => {
                notification.open({
                    message: 'Successfully Registered',
                    description:
                        `You have successfully register. Welcome ${res.data.username}, check your email ${res.data.email} for a confirmation email.`,
                    icon: <SmileOutlined style={{ color: '#108ee9' }} />,
                });
                this.setState({ loading: false, step: this.state.step + 1,
                    userName: '', firstName: '', lastName: '', email: '',
                    password: '', jobPosition: '', address: '', city: '',
                    province: '', postalCode: '', submittedAt: '', timeZone:'', errors: [] });
            })
            .catch(async (err) => {
                this.setState({ loading: false });
                message.error(err.response.data);
            });
        }
        this.setState({ errors: checker.errors, loading: false });
    }

    createRegisterModel(state: any){
        return {
            userName: state.userName || state.firstName + state.lastName,
            firstName: state.firstName,
            lastName: state.lastName,
            email: state.email,
            password: state.password,
            jobPosition: state.jobPosition,
            address: state.address,
            city: state.city,
            province: state.province,
            postalCode: state.postalCode,
            submittedAt: getCurrentDate(),
            service: "Janus",
            timezone: state.timeZone
        }
    }

    handleChange = (input: any) => (e: any) => {
        if (typeof e === 'object') {
            console.log(e);
            this.setState({ [input]: e.target.value } as any);
        }
        else {
            this.setState({ [input]: e } as any);
        }
        //this.setState({ [input]: e.target.value } as any);
        let register = this.createRegisterModel(this.state);
        let checker = isValid(register);
        this.setState({ errors: checker.errors, loading: false });
    }

    showForm(){
        switch(this.state.step) {
            case 1:
                return (
                    <UserInfo
                        handleChange={this.handleChange}
                        values={this.state}
                    />
                );
            case 2:
                return (
                    <PersonalInfo
                        handleChange={this.handleChange}
                        values={this.state}
                    />
                );
            case 3:
              return (
                <Result
                    status="success"
                    title="Successfully Registered!"
                    subTitle="Check your email for a confirmation email."
                    extra={[
                        <Link to={`/login`} style={{ color: '#fff' }} key='1'>
                            <Button type="primary">
                                Login now!
                            </Button>
                        </Link>
                    ]}
                />
              );
          }
    }

    showButtons(){
        switch(this.state.step) {
            case 1:
                return (
                    <div>
                        <Button type="primary" onClick={this.nextStep}>
                            Continue
                            <RightOutlined />
                        </Button>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <Button type="primary" onClick={this.prevStep}>
                            <LeftOutlined />
                            Take me back
                        </Button>
                        <span>  </span>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            {'Register'}
                        </Button>
                    </div>
                );
          }
    }

    render() {
        return (
            <Content style={{ padding: '0 20px' }}>
                <div style={{ padding: 20, minHeight: 240, maxWidth: 300, 
                    margin: '0 auto', paddingBottom: '0px' }}>
                    <Form onFinish={this.onSubmit} className="login-form">
                        {this.showForm()}
                        <Form.Item>
                            {
                            (this.state.loading) ?
                            <Button type="primary" loading>
                                Loading
                            </Button>
                            :
                                this.showButtons()
                            }
                            <span><Link to={`/forgot-password`}>Forgot password</Link></span>
                        </Form.Item>
                    </Form>
                </div>
            </Content>
        )
    }
}

export default connect(null,{ addFlashMessage })(RegisterForm);