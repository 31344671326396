import React, { Component } from 'react';
import { ArrowUpOutlined, CheckOutlined, CloseOutlined, MailOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Button, message, notification, Switch, Form } from 'antd';
import { createInvitationModel, SendInviteState } from './modules/send-invite';
import { isValid } from '../../../utils/validate';
import { SEND_INVITE } from '../routes';
import axios from 'axios';

const { Option } = Select;

class SendInvite extends Component<any, SendInviteState> {
    constructor(props: any){
        super(props);
        this.state = {
            internal: false,
            errors: [],
            email: '',
            isOwner: false,
            role: "",
            roles: [],
            company: "",
            confirmed: false,
            loading: true,
            disabled: false
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount(){
        await this.setState({ roles: this.props.roles, company: this.props.company.id });
        await this.setState({ loading: false })
    }

    selectionOfRoles() {
        let items = [];         
        for (let i = 0; i < this.state.roles.length; i++)
        {             
            items.push(
                <Option key={this.state.roles[i].id}
                    value={this.state.roles[i].name}>
                    {this.state.roles[i].name}
                </Option>
            );   
        }
        return items;
    }

    handleChange = (input: any) => async (e: any) => {
        if(typeof e === 'object')
        {
            await this.setState({ [input]: e.target.value } as any);
        }
        else
        {
            await this.setState({ [input]: e } as any);
        }
        let invitation = await createInvitationModel(this.state);
        let checker = await isValid(invitation);
        await this.setState({ errors: checker.errors, loading: false });
    }

    async onSubmit(e: any) {
        this.setState({ loading: true });
        //e.preventDefault();
        let invitation = createInvitationModel(this.state);
        let checker = isValid(invitation);
        if(checker.isValid)
        {
            await axios.post(SEND_INVITE, invitation)
            .then(async (res) => {
                notification.open({
                    message: 'Successfully Invited',
                    description:
                        `You have successfully sent an invitation to ${res.data.email}.`,
                    icon: <ArrowUpOutlined style={{ color: '#108ee9' }} />,
                });
                this.setState({ errors: [], email: '', isOwner: false, 
                    company: "", confirmed: false, loading: true });
                    //add call here
                    if (typeof this.props.refreshListsOnUpdate === "function") {
                        // safe to use the function
                        this.props.refreshListsOnUpdate();
                    }
                    
            })
            .catch(async (err) => {
                this.setState({ loading: false });
                console.log(err);
                message.error(err.response.data);
            });
        }
        this.setState({ errors: checker.errors, loading: false });

    }

    render() {
        return (
            <div>
            {
                (this.state.loading) ?
                <h1>Loading</h1>
                :
                <Form onFinish={this.onSubmit} className="login-form">
                    <Form.Item validateStatus={this.state.errors.email ? 'error' : ''} 
                        help={this.state.errors.email || ''} label='Email'>
                        <Input
                            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="email" value={this.state.email}
                            placeholder="Email" onChange={this.handleChange('email')}
                        />
                    </Form.Item>
                    {/* Feature was not fully implemented*/}
                    {/*<Form.Item label='Or'>*/}
                    {/*    <Select defaultValue="">*/}
                    {/*        <Option value=''>Select User to invite(doesnt do anything yet)</Option>*/}

                    {/*    </Select>*/}

                    {/*</Form.Item>*/}
                    <Form.Item validateStatus={this.state.errors.role ? 'error' : ''}
                        help={this.state.errors.role || ''} label='Role'>
                            
                        <Select defaultValue="" onChange={this.handleChange('role')}
                            disabled={this.state.disabled} >
                            <Option value="">Select One</Option>
                            {this.selectionOfRoles()}
                        </Select>
                    </Form.Item>
                    <Form.Item validateStatus={this.state.errors.role ? 'error' : ''}
                        help={this.state.errors.role || ''} label='Access Level Internal?'>
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={this.state.internal}
                            onChange={this.handleChange('internal')}
                        />
                    </Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button"
                        loading={this.state.loading}>
                        Invite User 
                    </Button>
                </Form>
            }
            </div>
        );
    }
}

export default SendInvite;