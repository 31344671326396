import React, { Component, Props } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Icon, { HomeOutlined, LogoutOutlined, ScanOutlined, SwitcherFilled } from '@ant-design/icons';
import { Menu, MenuProps, message } from 'antd';
import {
    HomeIcon,
    CompanyIcon,
    IncognitoIcon,
    ProfileIcon,
    NotifCenterIcon,
    ServicesIcon,
    ToursIcon,
} from './icons/icons';
import SubMenu from 'antd/lib/menu/SubMenu';
import axios from 'axios';
import { menuItem } from '../interfaces/MenuSettings';
import { bsdCompany } from '../interfaces/BSDCompany';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}
interface props {
    links: menuItem[],
    bsdCompany: bsdCompany,
    logout: any
}
class MenuList extends Component<props, {}> {
    capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    getAvatar(name: string) {
        switch (name) {
            case 'HomeIcon':
                return <HomeIcon/>;

            case 'CompanyIcon':
                return <CompanyIcon/>;

            case 'IncognitoIcon':
                return <IncognitoIcon/>;

            case 'ProfileIcon':
                return <ProfileIcon/>;

            case 'NotifCenterIcon':
                return <NotifCenterIcon/>;

            case 'ServicesIcon':
                return <ServicesIcon/>;

            case 'ToursIcon':
                return <ToursIcon/>;

            default:
                return <HomeIcon/>;
        }
    }

    viewLandingPage = async () => {
        const hide = message.loading('Redirecting...', 0);
        // Dismiss manually and asynchronously
        await setTimeout(hide, 2000);
        var bearer = localStorage.getItem('token');
        // var company = localStorage.getItem('management');
        // if (company != null) {
        //     var companyData = JSON.parse(company);
        //     //await setCompToHeader(companyData.managingCompany);
        // }
        
        var comp = {
            Id: this.props.bsdCompany.id,
            BusinessNumber: this.props.bsdCompany.businessNumber,
            LegalEntityName: this.props.bsdCompany.legalEntityName,
            LogoImage: this.props.bsdCompany.logoImage,
            Description: this.props.bsdCompany.description
        };
        let CompanyString = JSON.stringify(comp, null, 2);
        var externalToken = {
            Bearer: bearer,
            Company: btoa(CompanyString),
            CompanyId: this.props.bsdCompany.id,
            Logo: this.props.bsdCompany.logoImage,
            Banner: "",
            CompanyName: this.props.bsdCompany.legalEntityName,
            Role: this.props.bsdCompany.role,
            ServiceId: this.props.bsdCompany.serviceId,
            ServiceCode: this.props.bsdCompany.serviceCode,
            Connected:true,
            Permissions:""
        }       
        delete axios.defaults.headers.common['BSD-Company'];
        await axios.post('/api/v1/external/glt', externalToken)
            .then(async (res) => {
                window.location.href = this.props.bsdCompany.serviceUrl + "?glt=" + res.data;
        });
    };
    getMenuItems() {
        let currentMenuItems = [];
        for (let i = 0; i < this.props.links.length; i++) {
            if (this.props.links[i].subMenu) {
                let submenu = [];//[getItem('Option 7', '7'), getItem('Option 8', '8')]
                for (let j = 0; j < this.props.links[i].subMenu!.length; j++) {
                    submenu.push(getItem(<NavLink to={this.props.links[i].subMenu![j].url}>{this.capitalizeFirstLetter(this.props.links[i].subMenu![j].name)}</NavLink>, '00' + (i + 2) + j, this.getAvatar(this.props.links[i].subMenu![j].icon)));
                }
                //currentMenuItems(getItem('Submenu', 'sub3', this.props.links[i], submenu));
                currentMenuItems.push(getItem(this.capitalizeFirstLetter(this.props.links[i].name), '00' + (i + 2), this.getAvatar(this.props.links[i].icon), submenu));
            }
            else {
                currentMenuItems.push(getItem(<NavLink to={this.props.links[i].url!}>{this.capitalizeFirstLetter(this.props.links[i].name)}</NavLink>, '00' + (i + 2), this.getAvatar(this.props.links[i].icon)));
            }
        }
        return currentMenuItems;
    }
    render() {
        const realx = { background: "#0D2340"}
        const janus = { background: "#001529" }
        let menuItems = [
            getItem(<NavLink to="/">Home</NavLink>, '001', <HomeIcon />),
        ];

        menuItems = menuItems.concat(this.getMenuItems());
        if (this.props.bsdCompany.id) {
            menuItems.push(getItem(<NavLink to={'/'} onClick={() => this.viewLandingPage()} className="linkWhiteText widthHeight100">
                <span>Device Management</span>
            </NavLink>, "009", <ScanOutlined style={{ color: '#fff' }} />));
        }
        menuItems.push(getItem(<NavLink to={'/'} onClick={()=>this.props.logout()} className="linkWhiteText widthHeight100">
            <span>logout</span>
        </NavLink>, "010", <LogoutOutlined style={{ color: '#fff' }} />));
        return (
            <Menu style={(process.env.REACT_APP_FRONT_END === "janus")?janus:realx} defaultSelectedKeys={['1']} theme="dark" mode="inline" items={menuItems}/>
               )                
    }
}
function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,
    };
}

export default connect(
    mapToStateToProps,
    {
    },
    null,
    { pure: false }
)(MenuList);