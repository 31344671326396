export const PROFILE = '/api/v1/account/profile';
export const PROFILE_WITH_ID = '/api/v1/account/anyProfile';
export const REGISTER = '/api/v1/auth/register';
export const LOGIN = '/api/v1/auth/login';
export const CREATE_ACCOUNT = '/api/v1/auth/account';
export const UPADATE_COMPANY = '/api/v1/entity/company';
export const CREATE_COMPANY = '/api/v1/entity/create-company';
export const ROLES = '/api/v1/account/roles';
export const SEND_INVITE = '/api/v1/account/send-invite';
export const GET_INVITE = '/api/v1/account/invite-link';
export const ENTITY_INDUSTRIES = '/api/v1/entity/industries';
export const ENTITY_SIZES = '/api/v1/entity/sizes';
export const SEND_COMPANY_OWNER_INVITE = '/api/v1/invitation/invite-company-owner';
export const ACCEPT_COMPANY_OWNER_INVITE = '/api/v1/invitation/accept-company-owner';
export const ACCEPT_INVITE = '/api/v1/account/accept-invitation';
export const ACCEPT_COMMON_INVITE = '/api/v1/account/accept-invitation-common';
export const CONFIRM_EMAIL = '/api/v1/account/confirm-email';
export const FORGOT_PASSWORD = '/api/v1/account/forgot-password';
export const RESET_PASSWORD = '/api/v1/account/reset-password';
export const RESET_PASSWORD_TOKEN = '/api/v1/account/profile-token';
export const ENTITY = '/api/v1/entity/';
export const FULL_ENTITY = '/api/v1/entity/full/';
export const COMPANY_ENTITY = '/api/v1/entity/company/';
export const ENTITIES = '/api/v1/entity/companies';
export const CONNECT_ME = '/api/v1/connect/connect';
export const REQUEST_CONNECT = '/api/v1/connect/request';
export const SIGNAL_SERVER = '/signal-server';
export const ACCEPT_REQUEST_CONNECT = '/api/v1/connect/accept-connect';
export const USER_NOTIFICATIONS = '/api/v1/connect/notifications/user';
export const REQUESTS = '/api/v1/connect/requests';
export const IMAGE_UPLOAD = '/api/v1/file/upload';
export const PROFILE_IMAGE_UPLOAD = '/api/v1/file/upload/profile/image';
export const COMPANY_AVATAR_IMAGE_UPLOAD = '/api/v1/file/upload/company/avatar/image';
export const COMPANY_BANNER_IMAGE_UPLOAD = '/api/v1/file/upload/company/banner/image';
export const ALL_SERVICES = '/api/v1/services/services';
export const PAY_FOR_SERVICE = '/api/v1/payment/subscribe';
export const PAYMENT_SUBSCRIPTIONS = '/api/v1/payment/subscriptions/';
export const COMPAY_SUBSCRIPTIONS = '/api/v1/payment/subscriptions';
export const DM_SUBSCRIPTION = '/api/v1/payment/dmServiceURL';
export const USER_COMPANY_USERS = '/api/v1/user-company/users';
export const USER_COMPANY_CONNECTIONS = '/api/v1/user-company/user-connections'
export const ACCESS_LEVELS = '/api/v1/accesslevel/levels';
export const TRANSFER_OWNER = '/api/v1/user-company/owner-transfer-request';
export const TRANSFER_OWNER_REQUESTS = '/api/v1/user-company/owner-transfer-requests';
export const TRANSFER_OWNER_ACTION = '/api/v1/user-company/owner-transfer-action';
export const CREATE_SHARED_TOKEN = '/api/v1/shared-auth/create-share-token';
export const SHORT_URL_AUTHENTICATION = '/api/v1/url-shortener/auth-short-url';
export const SHORT_URL = '/api/v1/url-shortener/create-short-invite-url';
export const DECOMPOSE_LINK = '/api/v1/url-shortener/decompose-link';