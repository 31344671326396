import React, { Component } from 'react';
import { ApiTwoTone, InfoCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Card, Avatar, Button } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { NavLink } from 'react-router-dom';

class Suggestion extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            companyConnected: false,
            loadingConnect: true
        }
    }

    componentDidMount(){
        this.setState({
            companyConnected: this.props.company.connected,
            loadingConnect: false,
        });
    }

    async onConnect(legalName: string){
        this.setState({ loadingConnect: true });
        await this.props.onConnect(legalName);
        this.setState({
            companyConnected: true,
            loadingConnect: false
        });
    }

    render() {
        const connect = (this.state.companyConnected) ?
            <ApiTwoTone twoToneColor="#52c41a" />
            :
            //<Button
            //    type="primary"
            //    icon="api"
            //    loading={this.state.loadingConnect}
            //    onClick={() => this.onConnect(this.props.company.legalEntityName)}
            //>
            //Connect!
            //</Button>;
            null
        return (
            <Card
                size="small"
                actions={[
                    connect,
                    <NavLink to={`/company/${this.props.company.url}`} className='widthHeight100'>
                        <InfoCircleOutlined />
                    </NavLink>,
                ]}
                style={{ marginBottom: '10px' }}
            >
                <Card.Grid style={{ width: '100%', textAlign: 'left' }} hoverable={false}>
                    <Meta
                        avatar={
                            (this.props.company.logo === null) ?
                            <Avatar shape="square" size={64} icon={<UserOutlined />} />
                            :
                            <Avatar shape="square" size={64} src={this.props.company.logo} />
                        }
                        title={this.props.company.legalEntityName}
                        description={this.props.company.description}
                    />
                </Card.Grid>
            </Card>
        );
    }
}

export default Suggestion