import React, { Component } from 'react';
import { CheckOutlined, CloseOutlined, LinkOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Button, message, notification, Switch, Modal, Form } from 'antd';
import { createInvitationLinkModel, GetInviteLinkState } from './modules/get-invite';
import { isValid } from '../../../utils/validate';
import { GET_INVITE, SHORT_URL } from '../routes';
import axios from 'axios';

const { Option } = Select;

class GetInviteLink extends Component<any, GetInviteLinkState> {
    constructor(props: any) {
        super(props);
        this.state = {
            internal: false,
            errors: [],
            role: "",
            roles: [],
            company: "",
            confirmed: false,
            loading: true,
            disabled: false,
            link: '',
            showLinkModal: false
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        await this.setState({ roles: this.props.roles, company: this.props.company.id });
        await this.setState({ loading: false })
        
    }

    selectionOfRoles() {
        let items = [];
        for (let i = 0; i < this.state.roles.length; i++) {
            items.push(
                <Option key={this.state.roles[i].id}
                    value={this.state.roles[i].name}>
                    {this.state.roles[i].name}
                </Option>
            );
        }
        return items;
    }

    handleChange = (input: any) => async (e: any) => {
        if (typeof e === 'object') {
            await this.setState({ [input]: e.target.value } as any);
        }
        else {
            await this.setState({ [input]: e } as any);
        }
        let invitation = await createInvitationLinkModel(this.state);
        let checker = await isValid(invitation);
        await this.setState({ errors: checker.errors, loading: false });
    }

    async onSubmit(e: any) {
        this.setState({ loading: true });
        //e.preventDefault();
        let invitation = createInvitationLinkModel(this.state);
        let checker = isValid(invitation);
        var link= '';
        if (checker.isValid) {
            await axios.post(GET_INVITE, invitation)
                .then(async (res: any) => {                    
                    notification.open({
                        message: 'Invitation Created',
                        description:
                            `${res.data}.`,
                    });

                    let obj = {
                        currentUser: true,
                        fullUrl: res.data,
                        loginProvider: "VrSafety",
                        tokenName: "AuthTokenInviteLink",
                    }


                    await axios.post(SHORT_URL, obj)
                        .then(async (data: any) => {
                            link = data.data;
                        })
                        .catch(async (error: any) => {
                            this.setState({ loading: false });
                            message.error(error.response.data);
                        });

                    this.setState({
                        errors: [],
                        link: link,
                        company: "",
                        confirmed: false,
                        loading: true,
                        showLinkModal: true,
                    });
                    //add call here
                    this.props.refreshListsOnUpdate();
                })
                .catch(async (err: any) => {
                    this.setState({ loading: false });
                    message.error(err.response.data);
                });
        }
        this.setState({ errors: checker.errors, loading: false });

    }

    handleClose = () => {
        this.setState({
            showLinkModal: false
        })
    }

    render() {
        return (
            <div>
                {
                    (this.state.loading) ?
                        <h1>Loading</h1>
                        :
                        <Form onFinish={this.onSubmit} className="login-form">
                            <Form.Item validateStatus={this.state.errors.role ? 'error' : ''}
                                help={this.state.errors.role || ''} label='Role'>

                                <Select defaultValue="" onChange={this.handleChange('role')}
                                    disabled={this.state.disabled} >
                                    <Option value="">Select One</Option>
                                    {this.selectionOfRoles()}
                                </Select>
                            </Form.Item>
                            <Form.Item  label='Access Level Internal?'>
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={this.state.internal}
                                    onChange={this.handleChange('internal')}
                                />
                            </Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button"
                                loading={this.state.loading}>
                                Get the link
                            </Button>
                        </Form>
                }
                <Modal
                    title={"The generated invitation link is: "}
                    open={this.state.showLinkModal}
                    onCancel={this.handleClose}
                    footer={[
                        <Button key="back" onClick={this.handleClose}>
                            Close
                </Button>]}
                >
                    <div style={{ marginBottom: 16 }}>
                        <Input value={this.state.link}
                            addonAfter={<Button onClick={() => { navigator.clipboard.writeText(this.state.link) }}>Copy <LinkOutlined /></Button>}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}

export default GetInviteLink;