import React, { Component } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Collapse, List, Button, message, DatePicker, Row, Col, Modal, Alert } from 'antd';
import { getServices, getCompanyInfo } from '../../../api/ApiGet/RootApiGet';
import LayoutOptions from './LayoutOptions';
import DateChange from './DateChange';
import axios from 'axios';
import { CREATE_ACCOUNT, CREATE_SHARED_TOKEN, PAY_FOR_SERVICE } from '../routes';
import { createMySqlDate, getCurrentDate } from '../../../utils/dateUtils';
import { randomString } from '../../../utils/helperFunctions';
import { editEndDate, refreshSharedToken } from '../../../api/ApiPost/RootApiPost';
const { Panel } = Collapse;

interface State {
    activeServices: any,
    company:any,
    serviceToChange:number,
    dateModal:boolean,
    deactivateModal:boolean,
    endDate:string
}
class AllSubscriptions extends Component<any, State> {
    state: State = {
        activeServices: [],
        company:[],
        serviceToChange:0,
        dateModal:false,
        deactivateModal:false,
        endDate:""
    }

    public constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        getServices(this.props.companyId, this.successGet, this.error);
        getCompanyInfo(this.props.companyId, this.successCompany, this.error);
        
    }

    successGet = (data: any) => {
        //console.log(data);
        let today= new Date();
        let services=[];
        for(let i=0;i<data.data.length;i++){
            //let date= new Date(data.data[i].endAt)
            //if(date>=today)
                services.push(data.data[i]);
        }
        this.setState({ activeServices: services });
    };

    successCompany = (data: any) => {
        this.setState({ company: data.data });
    };

    activateService=async()=> {
        let payload = {
            companyId: this.state.company.id,
            serviceId: this.state.serviceToChange,
            period: "",
            endAt:this.state.endDate,
            submittedAt: getCurrentDate(),
        }

        let sharedUser={
            userName: randomString(8),
            firstName: "shared",
            lastName: "content",
            email: `usertoshare@${this.state.company.businessNumber}.gl`,
            password: randomString(8),
            jobPosition: "sharecontent",
            address: this.state.company.companyInformation.address,
            city: this.state.company.companyInformation.city,
            province: this.state.company.companyInformation.province,
            postalCode: this.state.company.companyInformation.postalCode,
            role: "User",
            internal:false,
            company: this.state.company.id,
            submittedAt: getCurrentDate(),
            timeZone:"America/Winnipeg"

        }

        await axios
            .post(PAY_FOR_SERVICE, payload)
            .then(async (res) => {
                message.success("Service activated");
                this.setState({
                    dateModal: !this.state.dateModal,
                });
                await this.createSharedUser(sharedUser);
                await getServices(this.props.companyId, this.successGet, this.error);
            })
            .catch(async (err) => {
                message.error(
                    'Something went wrong, try again later. Thank you.'
                );
            });
    }

    createSharedUser = async (user:any) =>
    {
        await axios
        .post(CREATE_ACCOUNT, user)
        .then(async (res) => {
                        
            if(res.data==="Already existing shared user"){
                await refreshSharedToken({companyId:this.props.companyId}, this.successRefresh, this.error)
            }
            else{
                let email =
                {
                    email:res.data.email
                }
                await axios
                .post(CREATE_SHARED_TOKEN, email)
                    .then((data: any) => { })//console.log(data)})
                    .catch((error)=>{console.error(error)});
            }
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    error = (data: any) => {
        console.log(data);
        message.error("Oops!! Failed to get services!!");
    }

    selectEndDate=(serviceId:any)=>{
        this.setState({serviceToChange:serviceId, dateModal:true});
    }

    toggleModal=()=>{
        this.setState({dateModal: !this.state.dateModal})
    }

    toggleDeactivateModal=()=>{
        this.setState({deactivateModal: !this.state.deactivateModal})
    }

    changeEndDate=(date:any)=>{
        let endDate= createMySqlDate(date.format('YYYY-MM-DD'));
        this.setState({endDate:endDate});
    }

    deactivateModal=(serviceId:any)=>{
        this.setState({deactivateModal:true, serviceToChange: serviceId});
    }

    deactivateService=()=>{
        let today = new Date()
        let yesterday = new Date(today)

        yesterday.setDate(yesterday.getDate() - 1)
        let endDate= createMySqlDate(yesterday.toDateString());
        editEndDate(endDate, this.props.companyId, this.state.serviceToChange,
            this.deactivateSuccess,
            this.error);
    }

    deactivateSuccess=(data:any)=>{
        let services= this.state.activeServices
        let index= services.findIndex((service:any)=>service.serviceId==data.data.serviceId);
        services.splice(index,1)
        this.setState({deactivateModal:false, activeServices: services});
    }

    refreshToken=()=>{
        refreshSharedToken({companyId:this.props.companyId}, this.successRefresh, this.error)
    }

    successRefresh=(data:any)=>{
        message.success("Token refreshed successfully");
    }
    lessThanDayCheck(endDate: string,days:number) {
        let d = new Date(endDate)
        if (d.setDate(d.getDate() - days) < Date.now()) {
            return true;
        }
        return false
    }
    render() {
        const layouts = ["VRSafety", "RealX"];
        return (
            <div>
                <Collapse>
                <Panel header="Services" key="Services">

                    <Row style={{paddingBottom:"10px",borderBottom:"2px solid #999"}}>
                        <Button type="primary" onClick={()=>this.refreshToken()}>Refresh token</Button>
                    </Row>
                    <List
                        dataSource={this.props.services}
                        renderItem={(service: any) => (
                            <List.Item
                                actions={[
                                    (this.state.activeServices.some((data: any) => data.serviceId === service.id && Date.parse(data.endAt) > Date.now())) ?
                                    <Button 
                                        icon={<CloseOutlined />}
                                        onClick={
                                            ()=>this.deactivateModal(service.id)
                                        }
                                            type="primary"
                                            danger
                                    >
                                        Deactivate
                                    </Button>
                                    :
                                    <Button
                                        icon={<CheckOutlined />}
                                        onClick={
                                            ()=>this.selectEndDate(service.id)
                                        }
                                        type="primary"
                                    >
                                        Activate
                                    </Button>
                                ]}
                            >
                                <List.Item.Meta
                                    title={
                                        `${service.name}`
                                    }
                                />
                                {(this.state.activeServices.some((data: any) => data.serviceId === service.id)) ?
                                    <div>
                                        { 
                                            
                                            (this.lessThanDayCheck(this.state.activeServices.find((data: any) => data.serviceId === service.id).endAt, 30) && Date.parse(this.state.activeServices.find((data: any) => data.serviceId === service.id).endAt) > Date.now() ) ?
                                                <div>
                                                    {
                                                        (this.lessThanDayCheck(this.state.activeServices.find((data: any) => data.serviceId === service.id).endAt, 7)) ?
                                                            <Alert type="error" showIcon message={"Service will expire in less than 7 days on: "+this.state.activeServices.find((data: any) => data.serviceId === service.id).endAt}/>
                                                        :
                                                            <Alert type="warning" showIcon message={"Service will expire in less than 30 days on: "+this.state.activeServices.find((data: any) => data.serviceId === service.id).endAt}/>
                                                    }
                                                    <DateChange
                                                        companyId={this.props.companyId}
                                                        serviceId={service.id}
                                                        endDate={this.state.activeServices.filter((data: any) => data.serviceId === service.id)[0].endAt}
                                                    />
                                                    <Row style={{ marginTop: "10px" }}>
                                                        <LayoutOptions
                                                            companyId={this.props.companyId}
                                                            serviceId={service.id}
                                                            layout={this.state.activeServices.filter((data: any) => data.serviceId === service.id)[0].layout}
                                                        />
                                                    </Row>
                                                </div> :
                                                <div>
                                                    {
                                                        (this.lessThanDayCheck(this.state.activeServices.find((data: any) => data.serviceId === service.id).endAt,0)) ?
                                                            <Alert type="info" showIcon message={"Service expired on: " + this.state.activeServices.find((data: any) => data.serviceId === service.id).endAt} /> :
                                                            <>
                                                            <DateChange
                                                                companyId={this.props.companyId}
                                                                serviceId={service.id}
                                                                endDate={this.state.activeServices.filter((data: any) => data.serviceId === service.id)[0].endAt}
                                                            />
                                                            <Row style={{ marginTop: "10px" }}>
                                                                <LayoutOptions
                                                                    companyId={this.props.companyId}
                                                                    serviceId={service.id}
                                                                    layout={this.state.activeServices.filter((data: any) => data.serviceId === service.id)[0].layout}
                                                                />
                                                            </Row>
                                                            </>
                                                    }
                                                </div>
                                            }
                                   
                                </div>
                                :
                                null
                                }
                            </List.Item>
                            
                        )}
                    />
                </Panel>
                
            </Collapse>
            <Modal
                title= "Select service end date"
                open={this.state.dateModal}
                onCancel={this.toggleModal}
                onOk={this.activateService}
            >
                <span>End Date: </span>
                <DatePicker 
                    onChange={this.changeEndDate}
                />
            </Modal>
            <Modal
                    title= "Are you sure you want to proceed"
                    open={this.state.deactivateModal}
                    onCancel={this.toggleDeactivateModal}
                    onOk={this.deactivateService}
                    okText="Yes"
                    cancelText="No"
                >
                <p>Are you sure you want to proceed to deactivate this service?</p>
            </Modal>
        </div>
        );
    }
}

export default AllSubscriptions;
