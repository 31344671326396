import { SET_CURRENT_COMPANY, DEFUALT_CURRENT_COMPANY } from '../types';
import { CompanyConnection } from '../../interfaces/CompanyConnection';
import setCompToHeader from '../../utils/SetHeaders/compToken';

const CompanyInitialState: CompanyConnection = {
    id: '',
    logoImage: '',
    bannerImage: '',
    description: '',
    businessNumber: '',
    legalEntityName: '',
    connected: false,
    internal: false
}

function currentCompany(company: CompanyConnection, type: string){
    return {
        type: type,
        company: company
    }
}

export function defaultCompany() {
    return async (dispatch: any) => {
        localStorage.setItem(
            'company',
            JSON.stringify(CompanyInitialState, null, 2)
        );
        setCompToHeader(false);
        dispatch(currentCompany(CompanyInitialState, DEFUALT_CURRENT_COMPANY));
    };
}

export function setCurrentCompany(company: CompanyConnection) {
    return async (dispatch: any) => {
        localStorage.setItem(
            'company',
            JSON.stringify(company, null, 2)
        );
        setCompToHeader(company);
        dispatch(currentCompany(company, SET_CURRENT_COMPANY));
    };
}