import React, { Component } from 'react';
import { ControlOutlined, UserOutlined } from '@ant-design/icons';
import { Button, List, Avatar } from 'antd';
import { PrivilegedUsersProps } from './module';

export class PrivilegedUsers extends Component<PrivilegedUsersProps, {}> {
    render() {
        return (
            <List
                bordered
                dataSource={this.props.users}
                renderItem={(item: any) => (
                    <List.Item key={item.user.id}
                    
                        actions={[
                            (this.props.vrsafetySub.active)?<Button key='edit' title="VRsafety Public Access" icon={<ControlOutlined />} onClick={() => this.props.showServicePermissionModal(item)} />:null,
                            <Button key='edit' title="Service CRUD Permissions" type="primary" icon={<ControlOutlined />} onClick={() => this.props.showPermissionModal(item)} />,
                            //<Button key='remove' title="Remove Users Permissions" type="danger" icon="delete" onClick={() => this.props.delete(item.id)} />
                    ]}>
                        <List.Item.Meta
                            avatar={ (item.user.avatarImage === null) ? <Avatar icon={<UserOutlined />} /> : <Avatar src={item.user.avatarImage} />}
                            title={`[${item.user.firstName}] ${item.user.firstName} ${item.user.lastName}`}
                            description={item.role}
                        />
                    </List.Item>
                )}
            />
        );
    }
}

export default PrivilegedUsers;