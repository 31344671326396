import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addFlashMessage } from '../actions/flashMessage';
import { Redirect } from 'react-router-dom';

interface Props {
    history: {};
    location: {};
    match: {};
    staticContext: {};
    isAuthenticated: boolean;
    addFlashMessage: (message: any) => {};
}

export default function (ComposedComponent: any) {
    class Authenticate extends Component<Props, {}> {
        componentDidMount() {}

        componentDidUpdate(nextProps: any) {
            if (!nextProps.isAuthenticated) {
                return <Redirect to="/" />;
            }
        }

        render() {
            if (this.props.isAuthenticated) {
                return <Redirect to="/" />;
            } else {
                return <ComposedComponent {...this.props} />;
            }
        }

        public static propTypes = {
            isAuthenticated: PropTypes.bool.isRequired,
            addFlashMessage: PropTypes.func.isRequired,
        };
    }

    Authenticate.propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
        addFlashMessage: PropTypes.func.isRequired,
    };

    function mapStateToProps(state: any) {
        return {
            isAuthenticated: state.auth.isAuthenticated,
        };
    }

    return connect(mapStateToProps, { addFlashMessage })(Authenticate);
}
