import React, { Component } from 'react'
import { ShopOutlined } from '@ant-design/icons';
import { List, Avatar, Button, message } from 'antd';
import { getCurrentDate } from '../../../utils/dateUtils';
import { isValid } from '../../../utils/validate';

class CompanySharing extends Component<any, any> {
    constructor(props: any){
        super(props);
        let content = {};
        if(this.props.content.hasOwnProperty('ownedContent')){
            content = this.props.content.ownedContent;
        } else {
            content = this.props.content;
        }
        this.state = {
            content: content
        };
        this.unshareContent = this.unshareContent.bind(this);
    }

    GoBackToCompanyList(){
        this.props.companySharingToggle({}, 0);
    }

    ShareServiceWithCompany(content: any) {
        let payload = {
            contentOwner: this.props.contentOwner,
            contentSharedTo: this.props.shareToCompany.id,
            content: content.id,
            service: this.props.service,
            submittedAt: getCurrentDate()
        };
        let check = isValid(payload);
        if(check.isValid){
            this.props.shareContentWithCompany(payload);
        } else {
            message.error("something went wrong.");
        }
    }

    unshareContent = (content: any) => {
        this.props.unshareContent(content.id);
    }

    render() {
        return (
            <div>
                <h1>Company selected: {this.props.shareToCompany.legalEntityName}</h1>
                <Button onClick={this.GoBackToCompanyList.bind(this)}>
                    Go Back!
                </Button>
                <List
                    itemLayout="horizontal"
                    dataSource={this.state.content}
                    renderItem={(content: any) => (
                        <List.Item
                            actions={[ (content.available !== undefined) ?
                                <Button onClick={() => this.unshareContent(content)}>
                                    Unshare
                                </Button>
                                :
                                <Button onClick={this.ShareServiceWithCompany.bind(this, content)}>
                                    Share
                                </Button>,
                            ]}
                        >
                            <List.Item.Meta
                                avatar={<Avatar shape="square" size={64} icon={<ShopOutlined />} />}
                                title={content.id}
                                description={(content.name === undefined) ? content.tourName : content.name}
                            />
                        </List.Item>
                    )}
                />
            </div>
        );
    }
}

export default CompanySharing