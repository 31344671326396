import React, { Component } from 'react';
import Suggestion from './Suggestion';
import { Empty } from 'antd';


class Suggestions extends Component<any, {}> {
    render() {
        return (
            <div>
            {
                (this.props.companies.length > 0) ?
                this.props.companies.map((company: any) => (
                    <Suggestion
                        key={company.id}
                        company={company}
                        onConnect={this.props.onConnect}
                    />
                ))
                :
                <Empty description="No companies were found." />
            }
            </div>
        )
    }
}

export default Suggestions