import axios from 'axios';
import { getCurrentDate } from '../../utils/dateUtils';
import { errorCallback } from '../ErrorCallback';
import { COMPAY_SUBSCRIPTIONS } from '../Routes';


export async function companySubscriptions(payload: any, callback: Function){
    await axios.get( COMPAY_SUBSCRIPTIONS, {
        params: {
            company: payload,
            date: getCurrentDate()
        }
    })
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res.data);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorCallback != null){
            await errorCallback(err);
        }
    });
}