import React, { Component } from 'react';
import CreateCompanyForm from './CreateCompany/CreateCompanyForm';
import { SmileOutlined } from '@ant-design/icons';
import { Skeleton, notification, message } from 'antd';
import { isValid } from '../../../utils/validate';
import { getCurrentDate } from '../../../utils/dateUtils';
import axios from 'axios';
import { CREATE_COMPANY } from '../routes';

class CreateCompanies extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            businessNumber: '',
            legalName: '',
            companySize: '',
            entityIndustry: '',
            phoneNumber: '',
            address: '',
            city: '',
            province: '',
            postalCode: '',
            numberOfEmployees: '',
            submittedAt: '',
            step: 1,
            loading: true,
            errors: [],
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        await this.setState({ loading: false });
    }

    handleChange = (input: any) => (e: any) => {
        if (typeof e === 'object') {
            this.setState({ [input]: e.target.value } as any);
        } else {
            this.setState({ [input]: e });
        }
        let createCompany = this.createCompanyViewModel(this.state);
        let checker = isValid(createCompany);
        this.setState({ errors: checker.errors });
    };

    createCompanyViewModel(value: any) {
        return {
            businessNumber: value.legalName.replace(/[^A-Z0-9]/ig, ""),
            legalName: value.legalName,
            companySize: value.companySize,
            entityIndustry: value.entityIndustry,
            phoneNumber: value.phoneNumber,
            address: value.address,
            city: value.city,
            province: value.province,
            postalCode: value.postalCode,
            numberOfEmployees: value.numberOfEmployees,
            parentCompany: this.props.company.id,
            submittedAt: getCurrentDate(),
        };
    }

    async onSubmit(e: any) {
        //e.preventDefault();
        await this.setState({ loading: true });
        let createCompany = this.createCompanyViewModel(this.state);
        let checker = isValid(createCompany);
        if (checker.isValid) {
            await axios
                .post(CREATE_COMPANY, createCompany)
                .then(async (res) => {
                    notification.open({
                        message: 'Successfully Created a new Company!',
                        description: `You have successfully created a new company! ${this.state.legalName}`,
                        icon: (
                            <SmileOutlined style={{ color: '#108ee9' }} />
                        ),
                    });
                    this.setState({
                        loading: false,
                        step: this.state.step + 1,
                        businessNumber: '',
                        legalName: '',
                        companySize: '',
                        entityIndustry: '',
                        phoneNumber: '',
                        address: '',
                        city: '',
                        province: '',
                        postalCode: '',
                        numberOfEmployees: '',
                        submittedAt: '',
                        errors: [],
                    });
                })
                .catch(async (err) => {
                    this.setState({
                        loading: false,
                        businessNumber: '',
                        legalName: '',
                        companySize: '',
                        entityIndustry: '',
                        phoneNumber: '',
                        address: '',
                        city: '',
                        province: '',
                        postalCode: '',
                        numberOfEmployees: '',
                        submittedAt: '',
                        errors: [],
                    });
                    message.error(err.response.data);
                });
        }
        this.setState({ errors: checker.errors, loading: false });
    }

    // Proceed to next step
    nextStep = () => {
        this.setState({ step: this.state.step + 1 });
    };

    // Go back to prev step
    prevStep = () => {
        this.setState({ step: this.state.step - 1 });
    };

    render() {
        return (
            <div>
                {this.state.loading ? (
                    <Skeleton active />
                ) : (
                    <CreateCompanyForm
                        values={this.state}
                        handleChange={this.handleChange}
                        onSubmit={this.onSubmit}
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        industries={this.props.industries}
                        sizes={this.props.sizes}
                    />
                )}
            </div>
        );
    }
}

export default CreateCompanies;
