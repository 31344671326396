import React, { Component } from 'react';

import qs from 'qs';
import axios from 'axios';
import { connect } from 'react-redux';
import { login, logout, setCurrentUser } from '../../../actions/authActions';
import { changeManagement, emptyManagement } from '../../../actions/management-action/management-action';
import { isValid } from '../../../utils/validate';
import { LoadingOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Layout, message, Result } from 'antd';
import {
    defaultUserCompPermission,
    userCompanyPermission,
} from '../../../actions/permissionActions';
import { UserLoginPerms } from '../../../api/LoginApi/LoginApi';
import { SHORT_URL_AUTHENTICATION } from '../../components/routes';
import { DECOMPOSE_LINK } from '../../components/routes';
import { getUserPermissions } from '../../../api/ServiceApi/ServiceApi';
import { Permission } from '../../../interfaces/Permission';
import ContentSharedWithMe from '../SharingCentre/ContentSharedWithMe';
import setAuthTokenToHeader from '../../../utils/SetHeaders/authToken';

interface Props {
    login: (data: string) => {};
    logout: Function;
    changeManagement: Function;
    defaultUserCompPermission: Function;
    userCompanyPermission: Function;
    location: any;
}

interface State {
    success: boolean;
    errors: any;
    loading: boolean;
    status: string;
    title: string;
    description: string;
    token: string;
    fullUrl: string;
    loginProvider: string;
    tokenName: string;
    logout: boolean
}
class UrlShortenerForm extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            success: false,
            errors: [],
            loading: true,
            status: '',
            title: '',
            description: '',
            token: "",
            fullUrl: "",
            loginProvider: "",
            tokenName: "",
            logout: false,
        };
    }


    async componentDidMount() {
        this.logout();
        this.setState({ logout: true });
        
        await this.setState({ loading: false });
        const queryString = this.props.location.search.replace('?', '');        
        const params = await qs.parse(queryString, {
            strictNullHandling: true,
        });
       
        await axios
            .post(
                `${DECOMPOSE_LINK}/${params.linkId}`
            ).then(async (res) => {
                this.setState({ token: res.data.token, fullUrl: res.data.fullUrl, loginProvider: res.data.loginProvider, tokenName: res.data.tokenName });

                if (res.data.tokenName === "AuthTokenShareContent") {
                   await this.LinkDecomposedSuccesfully();
                }
                else {
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    this.logoutAndRedirect(res.data.fullUrl);
                    
                }

                await this.LinkDecomposedSuccesfully();
            })
            .catch(async (err) => {
                let status: any = 'warning';
                let title = 'Bad Request!';
                let description = 'Bad Request!';
                if (err.response.status >= 500) {
                    status = 500;
                    title = 'Server Error!';
                    description =
                        'Sorry, something went wrong with the server. Try again later.';
                } else if (err.response.status === 400) {
                    title = 'Warning!';
                    if (typeof err.response.data === 'object') {
                        description = err.response.data[0].description;
                    } else {
                        description = err.response.data;
                    }
                }
                this.setState({
                    loading: false,
                    success: false,
                    status: status,
                    title: title,
                    description: description,
                });
            });
    }

    logout() {
        
        return async (dispatch: any) => {
            localStorage.clear();
            setAuthTokenToHeader(false);
            dispatch(setCurrentUser({}));
            dispatch(emptyManagement());
        };
    }


    logoutAndRedirect = (link: string) => {
        window.location.href = `${link}`;
    }

    async LinkDecomposedSuccesfully() {
        
        let loginInfo = {
            loginProvider: this.state.loginProvider,
            tokenName: this.state.tokenName,
        };

        await axios
            .post(SHORT_URL_AUTHENTICATION, loginInfo, {
                headers: {
                    'Authorization': `Bearer ${this.state.token}`
                }
            })
            .then(async (res) => {
                this.setState({ loading: false, success: true });
                
                await this.UserLoginSuccess(res.data);
            })
            .catch(async (err) => {
                let status: any = 'warning';
                let title = 'Bad Request!';
                let description = 'Bad Request!';
                if (err.response.status >= 500) {
                    status = 500;
                    title = 'Server Error!';
                    description =
                        'Sorry, something went wrong with the server. Try again later.';
                } else if (err.response.status === 400) {
                    title = 'Warning!';
                    if (typeof err.response.data === 'object') {
                        description = err.response.data[0].description;
                    } else {
                        description = err.response.data;
                    }
                }
                this.setState({
                    loading: false,
                    success: false,
                    status: status,
                    title: title,
                    description: description,
                });
            });
    }

    UserLoginSuccess = async (data: any) => {
        await this.props.login(data);
        await UserLoginPerms(this.successUserLoginPerms);
        
    };

    successUserLoginPerms = async (data: any) => {
        let userCompService = {
            params: {
                User: data.userId,
                Company: data.companyId,
                Service: data.serviceId,
                Role: 'User',
            },
        };

        await getUserPermissions(
            userCompService,
            async (data: any) =>
                await this.successUserPermissions(data, data.company)
        );
    };

    successUserPermissions = async (data: Permission, company: any) => {
        let perms: Permission = {
            company: {
                id: company.id,
                logo: company.logo,
                banner: company.banner,
                name: company.name,
            },
            relation: {
                connected: data.relation.connected,
                permissions: data.relation.permissions,
                role: data.relation.role,
            },
            service: data.service,
        };

        await this.props.changeManagement(
            {
                menu: 'VRSafety ShareContent Login',
                role: data.relation.role,
            },
            company
        );

        await this.props.userCompanyPermission(perms);
        window.location.href = `${this.state.fullUrl}`;
    };

    handleChange = (input: any) => (e: any) => {
        this.setState({ [input]: e.target.value } as any);
    };

    render() {
        return (
            <div
                style={{
                    textAlign: 'center',
                    minHeight: 280,
                    maxWidth: 300,
                    margin: '0 auto',
                }}
            >
                <Result
                    icon={<LoadingOutlined style={{ color: "#4ECDC4" }} />}
                    title="Loading content"
                />
            </div>
        );
    }
}

export default connect(null, {
    login,
    logout,
    changeManagement,
    defaultUserCompPermission,
    userCompanyPermission,
})(UrlShortenerForm);
