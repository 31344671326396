import LoginRoot from '../../Janus/pages/LoginRoot/LoginRoot';
import Register from '../../Janus/pages/Register/Register';
import ManagementInvite from '../../Janus/pages/ManagementInvite/ManagementInvite';
import ManagementUsers from '../../Janus/pages/ManagementUsers/ManagementUsers';
import AcceptInvitation from '../../Janus/pages/AcceptInvitation/AcceptInvitation';
import ConfirmEmail from '../../Janus/pages/ConfirmEmail/ConfirmEmail';
import ForgotPassword from '../../Janus/pages/ForgotPassword/ForgotPassword';
import ResetPassword from '../../Janus/pages/ResetPassword/ResetPassword';
import Profile from '../../Janus/pages/Profile/Profile';
import ProfileSettings from '../../Janus/pages/ProfileSettings/ProfileSettings';
import Services from '../../Janus/pages/Services/Services';
import UserManagePermissions from '../../Janus/pages/UserManagePermissions/UserManagePermissions';
import CompanyPage from '../../Janus/pages/CompanyPage/CompanyPage';
import Company from '../../Janus/pages/Company/Company';
import Companies from '../../Janus/pages/Companies/Companies';
import Root from '../../Janus/pages/Root/Root';
import NotificationCenter from '../../Janus/pages/NotificationCenter/NotificationCenter';
import OwnedCompanies from '../../Janus/pages/Manage/OwnedCompanies/OwnedCompanies';
import AdminCompanies from '../../Janus/pages/Manage/AdminCompanies/AdminCompanies';
import ManagedCompanies from '../../Janus/pages/Manage/ManagedCompanies/ManagedCompanies';
import OtherCompanies from '../../Janus/pages/OtherCompanies/OtherCompanies';
import ShareWith from '../../Janus/pages/SharingCentre/ShareWith';
import SharedWithMe from '../../Janus/pages/SharingCentre/SharedWithMe';
import UrlShortener from '../../Janus/pages/UrlShortenerHandler/UrlShortener';
import ManagementService from '../../Janus/pages/ManagementService/ManagementService';

export interface PathComponent {
    path: string;
    component: any;
}

export const loggedInRoutes: Array<PathComponent> = [
    { path: '/', component: Services },
    { path: '/profile', component: Profile},
    { path: '/account/settings', component: ProfileSettings },
    { path: '/owned-companies', component: OwnedCompanies },
    { path: '/admin-companies', component: AdminCompanies },
    { path: '/managed-companies', component:  ManagedCompanies },
    { path: '/companies', component: Companies },
    { path: '/notification-center', component: NotificationCenter },
];

export const ownerRoutes: Array<PathComponent> = [
    { path: '/', component: ManagementService },
    { path: '/profile', component: Profile },
    { path: '/company', component: Company },
    { path: '/companies', component: OtherCompanies},
    { path: '/services', component: Services  },
    { path: '/manage/users/permissions', component: UserManagePermissions },
    { path: '/management/invitations', component: ManagementInvite },
    { path: '/management/users', component: ManagementUsers },
    { path: '/sharing-centre/share-with', component: ShareWith },
    { path: '/sharing-centre/shared-with-me', component: SharedWithMe },
];

export const adminRoutes: Array<PathComponent> = [
    { path: '/', component: ManagementService },
    { path: '/profile', component: Profile },
    { path: '/company', component: Company},
    { path: '/companies', component: OtherCompanies },
    { path: '/services', component: Services },
    { path: '/manage/users/permissions', component: UserManagePermissions },
    { path: '/management/invitations', component: ManagementInvite },
    { path: '/management/users', component: ManagementUsers },
];
export const managerRoutes: Array<PathComponent> = [
    { path: '/', component: Services },
    { path: '/profile', component: Profile },
    { path: '/company', component: Company },
    { path: '/companies', component: OtherCompanies },
    { path: '/services', component: Services },
    { path: '/manage/users/permissions', component: UserManagePermissions },
    { path: '/management/invitations', component: ManagementInvite },
    { path: '/management/users', component: ManagementUsers },
];

export const managerVRSafetyRoutes: Array<PathComponent> = [
    { path: '/', component: Services },
    { path: '/profile', component: Profile },
    { path: '/company', component: Company },
    { path: '/companies', component: OtherCompanies },
    { path: '/services', component: Services },
    { path: '/management/invitations', component: ManagementInvite },
];

export const rootRoutes: Array<PathComponent> = [
    { path: '/root', component: Root },
    { path: '/profile', component: Profile },
];

export const notLoggedInRoutes: Array<PathComponent> = [
    { path: '/', component: LoginRoot },
    { path: '/register', component: Register },
    { path: '/forgot-password', component: ForgotPassword },
    { path: '/reset-password', component: ResetPassword },
    
];

export const globalRoutes: Array<PathComponent> = [
    // { path: '/home', component: Home },
    { path: '/company/:company', component: CompanyPage },
    { path: '/accept-invitation', component: AcceptInvitation },
    { path: '/confirm-email', component: ConfirmEmail },
    { path: '/link', component: UrlShortener },
];

export const vrsafetyRoutes: Array<PathComponent> = [
    { path: '/', component: Services },
    { path: '/profile', component: Profile },
];
export const vrsafetyShareContentRoutes: Array<PathComponent> = [
    { path: '/', component: UrlShortener },
];