import React, { Component } from 'react'
import { CheckOutlined, CloseOutlined, LockOutlined, MailOutlined, SmileOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Switch, Form } from 'antd';
import { timeZonesNames } from "@vvo/tzdb";
const { Option } = Select;

const timeZones = timeZonesNames.filter((zone: any) => zone.includes("America"));
class UserInfo extends Component<any, {}> {
    constructor(props: any){
        super(props);
        this.selectionOfRoles = this.selectionOfRoles.bind(this);
    }

    selectionOfRoles() {
        let items: any = [];         
        for (let i = 0; i < this.props.roles.length; i++)
        {
            items.push(
                <Option key={this.props.roles[i].id}
                    value={this.props.roles[i].name}>
                    {this.props.roles[i].name}
                </Option>
            );
        }
        return items;
    }

    onSearch=(val:any)=> {
        
    }
    selectTime=(e:any)=>{
        
    }

    render() {
        return (
            <div>
                <Form.Item validateStatus={this.props.values.errors.firstName ? 'error' : ''} help={this.props.values.errors.firstName || ''}>
                    <Input
                        prefix={<SmileOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="text" value={this.props.values.firstName}
                        placeholder="First Name" onChange={this.props.handleChange('firstName')}
                    />
                </Form.Item>
                <Form.Item validateStatus={this.props.values.errors.lastName ? 'error' : ''} help={this.props.values.errors.lastName || ''}>
                    <Input
                        prefix={<SmileOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="text" value={this.props.values.lastName}
                        placeholder="Last Name" onChange={this.props.handleChange('lastName')}
                    />
                </Form.Item>
                <Form.Item validateStatus={this.props.values.errors.email ? 'error' : ''} help={this.props.values.errors.email || ''}>
                    <Input
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="email" value={this.props.values.email}
                        placeholder="Email" onChange={this.props.handleChange('email')}
                    />
                </Form.Item>
                <Form.Item validateStatus={this.props.values.errors.password ? 'error' : ''} help={this.props.values.errors.password || ''}>
                    <Input
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password" value={this.props.values.password}
                        placeholder="Password"  onChange={this.props.handleChange('password')}
                    />
                </Form.Item>
                <Form.Item validateStatus={this.props.values.errors.role ? 'error' : ''} help={this.props.values.errors.role || ''}>
                    <Select defaultValue="" onChange={this.props.handleChange('role')} >
                        <Option value="">Select One</Option>
                        { this.selectionOfRoles() }
                    </Select>
                </Form.Item>
                <Form.Item validateStatus={this.props.values.errors.time ? 'error' : ''} help={this.props.values.errors.time || ''}>
                    <Select 
                    showSearch
                    style={{ width: 200 }}
                    placeholder="TimeZone"
                    optionFilterProp="children"
                    onSearch={this.onSearch}
                    onChange={this.props.handleChange('timeZone')}
                    filterOption={(input:any, option:any) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    >
                        <Option value="">Select One</Option>
                        { 
                            timeZones.map((zone:any)=>
                                
                                <Option key={zone}
                                    value={zone}>
                                    {zone}
                                </Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item validateStatus={this.props.values.errors.internal ? 'error' : ''}
                    help={this.props.values.errors.internal || ''} label='Access Level Internal?'>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={this.props.values.internal}
                        onChange={this.props.handleChange('internal')}
                    />
                </Form.Item>
            </div>
        );
    }
}

export default UserInfo