import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import PageNotFound from '../Janus/components/Errors/PageNotFound';
import { routeSelector } from './modules/componentBuilder';
export const history = require('history').createBrowserHistory();

// Instead of BrowserRouter, we use the regular router,
// but we pass in a customer history to it.
class AppRouter extends Component<any, {}> {
    render() {
       
        return (
            <Switch>
                {routeSelector(this.props.management.menuSettings.menu)}
                <Redirect from="*" to="/" />
            </Switch>
        );
    }
}

function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,
    };
}

export default connect(mapToStateToProps, {})(AppRouter);
