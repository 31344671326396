import React, { Component } from 'react';
import { connect } from 'react-redux';
import AllServices from '../../components/Services/AllServices';
import { PAY_FOR_SERVICE, COMPAY_SUBSCRIPTIONS, CREATE_ACCOUNT, CREATE_SHARED_TOKEN } from '../../components/routes';
import { getCurrentDate } from '../../../utils/dateUtils';
import { Skeleton, message, Modal, Button, Radio, Spin, Result } from 'antd';
import axios from 'axios';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import {
    StripeCustomer,
    StripePrices,
    PayForSubscription,
} from '../../../api/StripeAPI/StripeAPI';
import { getCompanyById } from '../../../api/ApiGet/UserManageApiGet';
import { randomString } from '../../../utils/helperFunctions';

const stripePromise = loadStripe(
    'pk_test_51GxyitCddPc0snUQvJS9ItQCKeAjW7EuGBZwHGBA81q59H0qR6TLjdfDnUisGyMhgWoKdG32Ta3gHiPS5cVdeTjk00HcEC2W10'
);

class Services extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            services: [],
            loading: true,
            paymentModalToggel: false,
            stripeCustomerId: '',
            stripeProducts: [],
            stripePrices: [],
            selectedProduct: '',
            stripePriceId: '',
            notPaid: true,
            serviceId:0,
            success:false,
            company:{}
        };
        this.payment = this.payment.bind(this);
    }

    async componentDidMount() {
        this.getServices();
        let company = this.props.management.managingCompany;
        if (company.id.length > 0) {
            getCompanyById(this.props.management.managingCompany.id, this.getCompanySuccess)
        }
        await StripeCustomer(
            this.props.auth.user.nameid,
            this.successStripeCustomer
        );

        await StripePrices(this.successStripePrices);
        
    }

    getServices=async()=>{
        let company = this.props.management.managingCompany;
        if (company.id.length > 0) {
            await this.setState({ company: company });
            let companyId = company.id;
            await axios
                .get(COMPAY_SUBSCRIPTIONS, {
                    params: { company: companyId, date: getCurrentDate() },
                })
                .then(async (res) => {
                    let services;
                    if(this.props.servicesPublic)
                        services= res.data.filter((service:any)=>service.service.public &&service.service.enabled);
                    else
                        services= res.data.filter((service:any)=>service.status &&service.service.enabled);
                    await this.setState({ services: services }); 
                    this.props.getCompaySubscriptions();
                })
                .catch(async (err) => {
                    console.log(err.response);
                });
               
        } 
        await this.setState({ loading: false });
    }

    successStripeCustomer = async (data: any) => {
        await this.setState({ stripeCustomerId: data });
    };

    successStripePrices = async (data: any) => {
        //console.log(data);
        await this.setState({ stripeProducts: data });
    };
    getCompanySuccess=(data:any)=>
    {
        //console.log(data);
        this.setState({company:data});
    }

    async payment(data: any) {       
        // if payed == true, then proceed, otherwise throw error message
        if (this.state.notPaid) {
            this.setState({ paymentModalToggel: false });
        }
        else {
            let price = this.state.stripePrices.find((price:any)=>price.id===this.state.stripePriceId);
            let payload = {
                companyId: this.state.company.id,
                serviceId: this.state.serviceId,
                period: price.recurring.interval,
                submittedAt: getCurrentDate(),
            }
            //console.log(payload);

            let sharedUser={
                userName: randomString(8),
                firstName: "shared",
                lastName: "content",
                email: `usertoshare@${this.state.company.businessNumber}.gl`,
                password: randomString(8),
                jobPosition: "sharecontent",
                address: this.state.company.companyInformation.address,
                city: this.state.company.companyInformation.city,
                province: this.state.company.companyInformation.province,
                postalCode: this.state.company.companyInformation.postalCode,
                role: "User",
                internal:false,
                company: this.props.management.managingCompany.id,
                submittedAt: getCurrentDate(),
                timeZone:"America/Winnipeg"
            }

            await axios
                .post(PAY_FOR_SERVICE, payload)
                .then(async (res) => {
                    message.success(res.data);
                    this.setState({
                        paymentModalToggel: !this.state.paymentModalToggel,
                    });
                    await this.createSharedUser(sharedUser,);
                    await this.getServices();
                })
                .catch(async (err) => {
                    message.error(
                        'Something went wrong, try again later. Thank you.'
                    );
                });
        }
    }
    createSharedUser = async (user:any) =>
    {
        await axios
        .post(CREATE_ACCOUNT, user)
        .then(async (res) => {
            //console.log(res);
            let email =
            {
                email:res.data.email
            }
            await axios
            .post(CREATE_SHARED_TOKEN, email)
                .then((data: any) => { })//console.log(data)})
                .catch((error)=>{console.error(error)});
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    SavePaymentMethod = async (data: any) => {
        if (data.error) {
            message.error(data.error.message);
        } else {
            this.setState({loading:true});
            let payload = {
                paymentMethod: data.paymentMethod.id,
                customer: this.state.stripeCustomerId,
                price: this.state.stripePriceId,
                company: this.props.currentCompany.id,
            };
            this.createSubscription(payload);
        }
    };

    createSubscription = async (data: any) => {
        await PayForSubscription(data, this.successPayForSubscription);
    };

    successPayForSubscription = async (data: any) => {
        //message.success(data);
        // Set payed to true
        this.setState({ notPaid: false, loading:false, success:true});
    };

    actionPaymentModal = async (serviceId:number) => {
        await this.setState({
            paymentModalToggel: !this.state.paymentModalToggel,
            serviceId:serviceId
        });
    };

    selectPricesForProducts = async (event: any) => {
        let productId: string = event.target.value;
        //console.log(productId);

        let prices = this.state.stripeProducts.filter(
            (item: any) => item.product.id == productId
        );

        await this.setState({
            selectedProduct: productId,
            stripePrices: prices[0].prices,
        });
    };

    selectPriceFromProduct = async (event: any) => {
        //console.log(event.target.value);
        let priceId: string = event.target.value;

        await this.setState({
            stripePriceId: priceId,
        });
    };

    render() {
        return (
            <div>
                <Modal
                    title="Payment Method"
                    open={this.state.paymentModalToggel}
                    // onOk={this.handleOk}
                    // onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.payment}>
                            Return
                        </Button>,
                        <Button onClick={this.payment}
                            key="submit"
                            type="primary"
                            disabled={this.state.notPaid}
                            // loading={loading}
                            // onClick={this.handleOk}
                        >
                            Enable Service
                        </Button>,
                    ]}
                >
                    <Spin spinning={this.state.loading} > 
                        {
                            (this.state.success)?
                            <Result
                                status="success"
                                subTitle="Service Purchased"
                            />
                            :
                            <React.Fragment>
                            <Radio.Group
                                size="large"
                                onChange={(data: any) =>
                                    this.selectPricesForProducts(data)
                                }
                            >
                                {this.state.stripeProducts.map((item: any) => (
                                    <Radio.Button key={item.product.id} value={item.product.id}>
                                        {item.product.name}
                                    </Radio.Button>
                                ))}
                            </Radio.Group>
                            {this.state.selectedProduct === '' ? null : (
                                <Radio.Group
                                    size="large"
                                    onChange={(data: any) =>
                                        this.selectPriceFromProduct(data)
                                    }
                                >
                                    {this.state.stripePrices.map((price: any) => (
                                        <Radio.Button key={price.id} value={price.id}>
                                            {price.recurring.interval}
                                            {/*console.log(price)*/}
                                        </Radio.Button>
                                    ))}
                                </Radio.Group>
                            )}
                            <Elements stripe={stripePromise}>
                                <CheckoutForm
                                    SavePaymentMethod={this.SavePaymentMethod}
                                    NotPaid={this.state.notPaid}
                                    loading={this.state.loading}
                                    success={this.state.success}
                                />
                            </Elements>
                        </React.Fragment>
                        }
                    </Spin>
                </Modal>

                <Skeleton loading={this.state.loading} avatar active>
                    <AllServices
                        services={this.state.services}
                        history={this.props.history}
                        payment={this.payment}
                        company={this.state.company}
                        openPaymentModal={this.actionPaymentModal}
                        managementRole={this.props.managementRole}
                    />
                </Skeleton>
            </div>
        );
    }
}

function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,
        currentCompany: state.currentCompany,
    };
}

export default connect(mapToStateToProps, {})(Services);
