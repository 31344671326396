import React, { Component } from 'react';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Tabs, Skeleton, message, Modal, Input, Button, Spin, Form, Row, Col } from 'antd';
import Services from '../../components/Root/Services';
import AllCompanies from '../../components/Root/AllCompanies';
import Users from '../../components/Root/Users';
import axios from 'axios';
import { isValid, objectToFormData } from '../../../utils/validate';
import { createServiceViewModel } from './module';
import CreateCompanies from '../../components/Management/CreateCompanies';
import { getRootUsers, getRootServices, getAllUsers, getAllLayouts } from '../../../api/ApiGet/RootApiGet';
import { editServiceVisibility, addNewLayout, editLayout } from '../../../api/ApiPost/RootApiPost';
import { FORGOT_PASSWORD, RESET_PASSWORD, RESET_PASSWORD_TOKEN } from '../../components/routes';
import {
    getBSDCompanyRole,
    getCompanyIndustries,
    getCompanySizes,
} from '../../../api/ApiGet/CompanyApiGet';
import { createRootService } from '../../../api/ApiPost/RootApiPost';
import { DeleteUser, DeleteUserFromComp, DeleteCompany } from '../../../api/AuthAPI/AuthAPI';
import Layouts from '../../components/Root/Layouts';

const { confirm } = Modal;
const { Search } = Input;

export interface RootState {
    id: number;
    name: string;
    description: string;
    dbType: string;
    url: string;
    submittedAt: string;
    services: Array<any>;
    industries: [];
    sizes: [];
    rootUsers: [];
    searchedCompanies: [];
    allUsers: any[];
    allSearchedUsers: [];
    loading: boolean;
    errors: any;
    modal: boolean;
    userToReset: any;
    resetPassword: string;
    code: string;
    userProcessing: boolean;
    bsdCompany: {},
    allLayouts: []
}

class Root extends Component<any, RootState> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: 0,
            name: '',
            description: '',
            dbType: '',
            url: '',
            submittedAt: '',
            services: [],
            industries: [],
            sizes: [],
            rootUsers: [],
            searchedCompanies: [],
            allUsers: [],
            allSearchedUsers: [],
            loading: true,
            errors: [],
            modal: false,
            userToReset: {},
            resetPassword: '',
            code: '',
            userProcessing: false,
            bsdCompany: {},
            allLayouts: []
        };
        this.addService = this.addService.bind(this);
    }

    async componentDidMount() {
        await getRootServices(this.successRootServices, this.error);
        await getCompanyIndustries(this.successCompanyIndustries, this.error);
        await getCompanySizes(this.successCompanySizes, this.error);
        await getRootUsers(this.successRootUsers, this.error);
        await getAllUsers(this.successAllUsers, this.error);
        await getBSDCompanyRole(this.bsdCompanySuccess, this.error);
        await getAllLayouts(this.allLayoutsSuccess, this.error);
        await this.setState({ loading: false });
    }

    allLayoutsSuccess = (data: any) => {
        this.setState({ allLayouts: data.data })
    }

    bsdCompanySuccess = async (data: any) => {
        let bsdCompany = data.data[0];
        this.setState({ bsdCompany: bsdCompany });
    }

    successRootUsers = async (data: any) => {
        await this.setState({ rootUsers: data.data, searchedCompanies: data.data });
    };

    successAllUsers = async (data: any) => {

        let dataSorted = data.data.sort((a: any, b: any) => { return (a.firstName.toLowerCase() > b.firstName.toLowerCase()) ? 1 : -1 });
        await this.setState({ allUsers: data.data, allSearchedUsers: data.data, userProcessing: false });
    };

    successRootServices = async (data: any) => {
        await this.setState({ services: data.data });
    };

    successCompanyIndustries = async (data: any) => {
        await this.setState({ industries: data.data });
    };

    successCompanySizes = async (data: any) => {
        await this.setState({ sizes: data.data });
    };

    openModal = (user: any) => {
        this.setState((state) => ({ modal: true, userToReset: user }));
        this.getToken(user.id);

    };
    async getToken(userid: string) {
        await axios
            .get(RESET_PASSWORD_TOKEN + "/" + userid)
            .then(async (res) => {
                await this.setState({
                    code: res.data,
                });
            })
            .catch(async (err) => {
                console.error(err);
                if (err.hasOwnProperty("response")) {
                    message.error(err.response.data[0].description);
                }
            });

    }
    error = (error: any) => {
        console.log(error);
        message.error(
            'Not logged in as root'
        );
        if (error.hasOwnProperty('response')) {
            console.error(error.response);
        } else {
            console.error(error);
        }
    };

    successCreateRootService = async (data: any) => {
        message.success('Service created successfully.');
        await this.setState({ services: [...this.state.services, data.data] });
    };

    async addService(e: any) {
        //e.preventDefault();
        let serviceViewModel = createServiceViewModel(this.state);
        let checker = isValid(serviceViewModel);
        if (checker.isValid) {
            await createRootService(
                serviceViewModel,
                this.successCreateRootService,
                this.error
            );
        }
        this.setState({ errors: checker.errors, loading: false });
    }
    //Reset password by email
    resetPassword = async (userEmail: string) => {
        alert(userEmail);

        await confirm({
            title: 'Do you want to reset the password for this user?',
            onOk: async () => {
                let formData = objectToFormData({ email: userEmail });
                await axios
                    .post(FORGOT_PASSWORD, formData)
                    .then(async (res) => {
                        message.success(res.data);
                    })
                    .catch(async (err) => {
                        message.error(err.response.data);
                    });
            },
            onCancel() {
                message.info("Password Reset Email was not sent")
            },
        });

    }

    handleChange = (input: any) => async (e: any) => {
        if (typeof e === 'object') {
            await this.setState({ [input]: e.target.value } as any);
        } else {
            await this.setState({ [input]: e } as any);
        }
    };

    deleteUser = async (userId: string) => {
        this.setState({ userProcessing: true });
        await DeleteUser(userId, this.successDeleted);
    };

    successDeleted = async (data: any) => {
        await message.success(data.message);
        await this.setState({
            rootUsers: data.payload,
            searchedCompanies: data.payload,
            allUsers: data.userRoles,
            allSearchedUsers: data.userRoles
        });
        this.setState({ userProcessing: false });
    };

    deleteUserFromCompany = async (userId: string, companyId: string) => {
        this.setState({ userProcessing: true });
        await DeleteUserFromComp(userId, companyId, this.successDeleted);
    };

    deleteFullCompany = async (companyId: string) => {
        this.setState({ userProcessing: true });
        await DeleteCompany(companyId, this.successDeleted);
    };

    handleOk = (e: any) => {
        this.setState({
            modal: false,
        });
    };

    handleCancel = (e: any) => {
        this.setState({
            modal: false,
        });
    };
    onSubmit = async (e: any) => {
        //e.preventDefault();
        let resetPasswordModel = this.ResetPasswordViewModel();
        let checker = isValid(resetPasswordModel);
        if (checker.isValid) {
            let formData = objectToFormData(resetPasswordModel);
            await axios
                .post(RESET_PASSWORD, formData)
                .then(async (res) => {
                    message.success(res.data);
                    this.handleCancel(e);
                })
                .catch(async (err) => {
                    if (err.hasOwnProperty("response")) {
                        message.error(err.response.data[0].description,);
                        this.handleCancel(e);
                    }
                    else { console.error(err); }
                });
        }
        this.setState({ errors: checker.errors });
    }

    ResetPasswordViewModel() {
        return {
            email: this.state.userToReset.email,
            token: this.state.code,
            password: this.state.resetPassword,
        };
    }

    editService = (serviceId: number, field: string) => {
        editServiceVisibility(field, serviceId, this.successFieldEdit, this.error);
    }

    successFieldEdit = (data: any) => {

        message.success("Service edited");
        getRootServices(this.successRootServices, this.error);
    }

    successBlock = (data: any) => {
        let users: any = this.state.allUsers;
        let index = users.findIndex((user: any) => user.id === data.data.id);
        let user: any = users[index];
        for (let i = 0; i < user.companies.length; i++) {
            user.companies[i].user = data.data
        }
        users[index] = user;
        this.setState({ allUsers: users, allSearchedUsers: users });
        message.success("User lockout settings changed");
    }

    successRoleChange = async () => {
        this.setState({ userProcessing: true });
        await getAllUsers(this.successAllUsers, this.error);
    }

    EditLayout = (postObject: any) => {
        editLayout(postObject, this.editLayoutSuccess, this.error);
    }

    AddLayout = async (postObject: any) => {
        await addNewLayout(postObject, this.addLayoutSuccess, this.error);
    }

    editLayoutSuccess = (data: any) => {
        let layouts: any = this.state.allLayouts;
        let index = layouts.findIndex((layout: any) => layout.id == data.data.id);
        layouts.splice(index, 1, data.data);
        this.setState({ allLayouts: layouts });
        message.success("Layout properties edited successfully");
    }

    addLayoutSuccess = (data: any) => {


        getAllLayouts(this.allLayoutsSuccess, this.error);
        message.success("Layout added successfully");
    }
    searchUsers = (e: any) => {
        let searchedUsers: any = this.state.allUsers.filter((user: any) => { return user.firstName.toLowerCase().includes(e.target.value) || user.lastName.toLowerCase().includes(e.target.value) || (user.firstName.toLowerCase() + " " + user.lastName.toLowerCase()).includes(e.target.value) || user.email.toLowerCase().includes(e.target.value) });

        this.setState({ allSearchedUsers: searchedUsers });
    }
    searchCompanies = (e: any) => {
        let searchedComp: any = this.state.rootUsers.filter((user: any) => { return user.company.toLowerCase().includes(e.target.value) });
        this.setState({ searchedCompanies: searchedComp });
    }

    render() {
        return (
            <div>
                {this.state.loading ? (
                    <Skeleton active />
                ) : (
                    <Spin spinning={this.state.userProcessing}>
                        <Tabs defaultActiveKey="1"
                            items={[
                                {
                                    label: `Services`,
                                    key: "1",
                                    children: (
                                        <Services
                                            services={this.state.services}
                                            addService={this.addService}
                                            handleChange={this.handleChange}
                                            createService={this.state}
                                            editService={this.editService}
                                        />
                                    )
                                },
                                {
                                    label: `Companies`,
                                    key: "2",
                                    children: (<>
                                        <Row>
                                            <Col></Col>
                                            <Col>
                                                <Search
                                                    placeholder="Search Companies"
                                                    onChange={value => this.searchCompanies(value)}

                                                /></Col>
                                        </Row>
                                        <AllCompanies
                                            rootUsers={this.state.searchedCompanies}
                                            deleteUser={this.deleteUserFromCompany}
                                            services={this.state.services}
                                            deleteCompany={this.deleteFullCompany}
                                        />
                                    </>)
                                },
                                {
                                    label: `Create Company`,
                                    key: "3",
                                    children: (
                                        <CreateCompanies
                                            industries={this.state.industries}
                                            sizes={this.state.sizes}
                                            company={this.state.bsdCompany}
                                        />
                                    )
                                },
                                {
                                    label: `Users`,
                                    key: "4",
                                    children: (
                                        <>
                                            <Row>
                                                <Col></Col>
                                                <Col>
                                                    <Search
                                                        placeholder="Search Users"
                                                        onChange={value => this.searchUsers(value)}

                                                    /></Col>
                                            </Row>

                                            <Users
                                                allUsers={this.state.allSearchedUsers}
                                                deleteUser={this.deleteUser}
                                                resetEmail={this.resetPassword}
                                                openPasswordResetModal={this.openModal}
                                                successBlock={this.successBlock}
                                                successRoleChange={this.successRoleChange}
                                            />
                                        </>
                                    )
                                },
                                {
                                    label: `Layouts`,
                                    key: "5",
                                    children: (
                                        <Layouts
                                            allLayouts={this.state.allLayouts}
                                            EditLayout={this.EditLayout}
                                            AddLayout={this.AddLayout}
                                        />
                                    )
                                },
                            ]}
                        />

                    </Spin>
                )
                }
                <Modal
                    title="Basic Modal"
                    open={this.state.modal}
                    onOk={this.onSubmit}
                    okText="Update Password"
                    onCancel={this.handleCancel}
                >
                    <Form className="login-form">
                        <Form.Item
                            validateStatus={
                                this.state.errors.email ? 'error' : ''
                            }
                            help={this.state.errors.email || ''}
                        >
                            <Input
                                prefix={
                                    <MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                                }
                                type="text"
                                value={this.state.userToReset.email}
                                placeholder="Email"
                                onChange={this.handleChange('email')}
                            />
                        </Form.Item>
                        <Form.Item
                            validateStatus={
                                this.state.errors.password ? 'error' : ''
                            }
                            help={this.state.errors.password || ''}
                        >
                            <Input
                                prefix={
                                    <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                                }
                                type="password"
                                value={this.state.resetPassword}
                                placeholder="Password"
                                onChange={this.handleChange('resetPassword')}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </div >
        );
    }
}

export default Root;
