import React, { Component } from 'react';
import axios from 'axios';
import { Skeleton, message } from 'antd';
import ManageCompanies from '../../../components/ManageCompanyCard/ManageCompanies';

class AdminCompanies extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            adminCompanies: [],
            loading: true
        }
    }

    async componentDidMount(){
        await axios.get('/api/v1/companies/admin')
        .then(async (res) => {
            this.setState({ adminCompanies: res.data });
        })
        .catch(async (err) => {
            console.log(err.response);
            message.error('Something went wrong check the console.');
        });
        this.setState({ loading: false });
    }

    render() {
        return (
            <div>
                <h1>Admin Companies</h1>
            {
                (this.state.loading) ?
                <Skeleton active />
                :
                <ManageCompanies
                    companies={this.state.adminCompanies}
                    menuName={'Company Admin'}
                />
            }
            </div>
        )
    }
}

export default AdminCompanies;