import axios from 'axios';
import { ROOT_USERS, ROOT_SERVICES, FULL_ENTITY, All_USERS, All_LAYOUTS, USER_TOKEN } from '../Routes';

export async function getRootUsers(callback: Function, errorcallback: Function){
    await axios.get( ROOT_USERS )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorcallback != null){
            await errorcallback(err);
        }
    });
}

export async function getAllUsers(callback: Function, errorcallback: Function) {
    await axios.get(All_USERS)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorcallback != null) {
                await errorcallback(err);
            }
        });
}

export async function getRootServices(callback: Function, errorcallback: Function){
    await axios.get( ROOT_SERVICES )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorcallback != null){
            await errorcallback(err);
        }
    });
}

export async function getLayout(companyId: string, serviceId: number, callback: Function, errorcallback: Function) {
    await axios.get(`api/v1/root/getLayout/${companyId}/${serviceId}`)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorcallback != null) {
                await errorcallback(err);
            }
        });
}

export async function getServices(companyId: string, callback: Function, errorcallback: Function) {
    await axios.get(`api/v1/root/getServices/${companyId}`)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorcallback != null) {
                await errorcallback(err);
            }
        });
}

export async function getCompanyInfo(companyId: string, callback: Function, errorcallback: Function) {
    await axios.get(FULL_ENTITY+companyId)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorcallback != null) {
                await errorcallback(err);
            }
        });
}

export async function getAllLayouts(callback: Function, errorcallback: Function) {
    await axios.get(All_LAYOUTS)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorcallback != null) {
                await errorcallback(err);
            }
        });
}

export async function getUserToken(id:string, callback: Function, errorcallback: Function) {
    await axios.get(USER_TOKEN+id)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorcallback != null) {
                await errorcallback(err);
            }
        });
}