import { SET_CURRENT_USER_PERMISSIONS } from '../actions/types';
import { Permission } from '../interfaces/Permission';

const initialState: Permission = {
    company: {
        id: '',
        logo: '',
        banner: '',
        name: '',
    },
    relation: {
        connected: false,
        permissions: '01000',
        role: 'User',
    },
    service: {
        id: '',
        code: '',
        layout: ''
    },
};

export default (state: Permission = initialState, action: any = {}) => {
    switch (action.type) {
        case SET_CURRENT_USER_PERMISSIONS:
            return {
                company: action.company,
                relation: action.relation,
                service: action.service,
            };
        default:
            return state;
    }
};
