import { SET_CURRENT_USER_PERMISSIONS } from './types';
import { Permission } from '../interfaces/Permission';
const defaultUserCompPerms: Permission = {
    company: {
        id: '',
        logo: '',
        banner: '',
        name: '',
    },
    relation: {
        connected: false,
        permissions: '01000',
        role: 'User',
    },
    service: {
        id: '',
        code: '',
        layout: '',
    },
};

function userCompanyPermissions(permissions: Permission, type: string) {
    return {
        type: type,
        company: permissions.company,
        relation: permissions.relation,
        service: permissions.service,
    };
}

export function defaultUserCompPermission() {
    return async (dispatch: any) => {
        localStorage.setItem(
            'user-company-permissions',
            JSON.stringify(defaultUserCompPerms, null, 2)
        );
        dispatch(userCompanyPermissions(defaultUserCompPerms, ''));
    };
}

export function userCompanyPermission(permission: Permission) {
    return async (dispatch: any) => {
        localStorage.setItem(
            'user-company-permissions',
            JSON.stringify(permission, null, 2)
        );
        dispatch(userCompanyPermissions(permission, SET_CURRENT_USER_PERMISSIONS));
    };
}