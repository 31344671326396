import validator from 'validator';

export function isValid(data: any){
  let errors: any = {};
  for (var key in data) {
    if (data.hasOwnProperty(key)) {
      if(data[key] === null || data[key] === '' || data[key] === 0){
        errors[key] = "This field is required";
      }
      if(key === 'email'){
        if (!validator.isEmail(data['email'])) {
          errors['email'] = 'Invalid email';
        }
      }
      if(key === 'userName'){
        if (data['userName'].includes(' ')) {
          errors['userName'] = 'UserName cannot have white spaces';
        }
      }
    }
  }
  return {
    errors,
    isValid: (Object.keys(errors).length === 0) ? true : false
  };
}

export function objectToFormData(obj: any) {
  let form = new FormData();

  for(var property in obj)
  {
    if(obj.hasOwnProperty(property))
    {
      form.append(property, obj[property]);
    }
  }
  return form;
}