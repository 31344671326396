export interface PrivilegedUsersProps
{
    users: [];
    showPermissionModal: (item: any) => {};
    showServicePermissionModal: (item: any) => {};
    vrsafetySub: {
        active: boolean, service: {id:number,name:string}};
    delete: (id: number) => {};
}

export interface ConnectedUsersProps
{
    company: [];
    connectedUsers: [];
    delete: (id: number) => {};
}

export const CHECKBOX_OPTIONS = [
    { label: 'create', value: true },
    { label: 'read', value: true },
    { label: 'update', value: true },
    { label: 'delete', value: true },
    { label: 'select all', value: true }
];

export const TABLE_COLUMNS_CONFIG = [
    { title: 'Service', dataIndex: 'service', key: 'service' },
    { title: 'Create', dataIndex: 'create', key: 'create' },
    { title: 'Read', dataIndex: 'read', key: 'read' },
    { title: 'Update', dataIndex: 'update', key: 'update' },
    { title: 'Delete', dataIndex: 'delete', key: 'delete' }
];

export const SERVICE_CHECKBOX_OPTIONS = [
    { label: 'public', value: true },
    { label: 'internal', value: true },
    { label: 'private', value: true }
];