import { menuItem } from "../../interfaces/MenuSettings";

export const CompanyManager: menuItem[] = [
    {
        name: 'profile',
        url: 'profile',
        icon: 'ProfileIcon',
    },
    {
        name: 'services',
        url: 'services',
        icon: 'ServicesIcon',
    },
    {
        name: 'company',
        url: 'company',
        icon: 'CompanyIcon',
    },
    {
        name: 'Management',
        subMenu: [
            {
                name: 'Invitations Management',
                url: 'management/invitations',
                icon: 'CompanyIcon',
            },
            {
                name: 'Users Management',
                url: 'management/users',
                icon: 'CompanyIcon',
            },
        ],
    },
];
