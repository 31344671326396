import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs, Skeleton, message } from 'antd';
import CreateUsers from '../../components/Management/CreateUsers';
import SendInvite from '../../components/Management/SendInvite';
import CreateCompanies from '../../components/Management/CreateCompanies';
import {
    ROLES,
    ENTITY_SIZES,
    ENTITY_INDUSTRIES,
} from '../../components/routes';
import axios from 'axios';
import InviteCompanyOwner from '../../components/Management/InviteCompanyOwner';

interface ManagementState {
    roles: [];
    connections: [];
    industries: [];
    sizes: [];
    company: {};
    loading: boolean;
}

class ManagementInvite extends Component<any, ManagementState> {
    constructor(props: any) {
        super(props);
        this.state = {
            roles: [],
            connections: [],
            industries: [],
            sizes: [],
            company: {},
            loading: true,
        };
    }

    async componentDidMount() {
        await this.setState({ company: this.props.management.managingCompany });
        await axios
            .get(ROLES)
            .then(async (result) => {
                await this.setState({ roles: result.data });
            })
            .catch(async (err) => {
                message.error(
                    `${err.response.status} | ${err.response.statusText}`
                );
            });

        await axios.get(ENTITY_INDUSTRIES).then(async (result) => {
            await this.setState({ industries: result.data });
        });

        await axios.get(ENTITY_SIZES).then(async (result) => {
            await this.setState({ sizes: result.data });
        });

        await this.setState({ loading: false });
    }

    render() {
        return (
            <div>
                {this.state.loading ? (
                    <Skeleton active />
                ) : (
                    <Tabs defaultActiveKey='1'
                        items={[
                            {
                                key: "1",
                                label: "Send Invite",
                                children: (
                                    <SendInvite
                                        company={this.state.company}
                                        auth={this.props.auth}
                                        roles={this.state.roles}
                                    />)
                            },
                            {
                                key: "2",
                                label: "Create Users",
                                children: (
                                    <CreateUsers
                                        company={this.state.company}
                                        roles={this.state.roles}
                                    />)
                            },
                            {
                                key: "3",
                                label: "Create Company",
                                children: (
                                    <CreateCompanies
                                        industries={this.state.industries}
                                        sizes={this.state.sizes}
                                        company={this.state.company}
                                    />)
                            },
                            {
                                key: "4",
                                label: "Invite Company with Owner",
                                children: (
                                    <InviteCompanyOwner
                                        auth={this.props.auth}
                                        company={this.state.company}
                                        roles={this.state.roles}
                                    />)
                            },
                        ]}
                    />

                )}
            </div>
        );
    }
}

function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,
    };
}

export default connect(mapToStateToProps, {})(ManagementInvite);
