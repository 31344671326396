import React, { Component } from 'react';
import { Modal, Tabs } from 'antd';
import SendInvite from '../Management/SendInvite';
import CreateUsers from '../Management/CreateUsers';
import GetInviteLink from '../Management/GetInviteLink';

export class AddUserModal extends Component<any, any> {
    render() {
        return (
            <Modal
                title="Add new User"
                open={this.props.addUserModalToggle}
                onOk={this.props.closeAddUserModal}
                onCancel={this.props.closeAddUserModal}
                closable
            >
                <Tabs defaultActiveKey="1"
                    items={[
                        {
                            label: "Send Single Invite",
                            key: "1",
                            children: (<SendInvite
                                company={this.props.company}
                                auth={this.props.auth}
                                roles={this.props.roles}
                                refreshListsOnUpdate={this.props.refreshListsOnUpdate}
                            />)
                        },
                        {
                            label: "Create Users",
                            key: "2",
                            children: (<CreateUsers
                                company={this.props.company}
                                roles={this.props.roles}
                                refreshListsOnUpdate={this.props.refreshListsOnUpdate}
                            />)
                        },
                        {
                            label: "Send Common Invite",
                            key: "3",
                            children: (<GetInviteLink
                                company={this.props.company}
                                auth={this.props.auth}
                                roles={this.props.roles}
                                refreshListsOnUpdate={this.props.refreshListsOnUpdate}
                            />)
                        },
                        ]}  
                />
            </Modal>
        );
    }
}

export default AddUserModal;
