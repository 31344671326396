import React, { Component } from 'react';
import { Collapse, List, Button, message } from 'antd';
import { addLayout } from '../../../api/ApiPost/RootApiPost';
import { CheckOutlined, EyeOutlined } from '@ant-design/icons';
const { Panel } = Collapse;

interface State {
    layout:string
}
class LayoutOptions extends Component<any, State> {   
    state: State = {
        layout: ""
    }

    public constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.setState({ layout: this.props.layout});
    }

    successGetLayout = (data: any)=> {
        this.setState({ layout: data.data });
    };

    successAddLayout = (data: any) => {
        this.setState({ layout: data.data.layout });
    };

    error = (data: any) => {
        console.log(data);
        message.error("Oops!! Failed to update Layout!!");
    }

    handleLayoutChange = (layout:any) => {
        addLayout(layout, this.props.companyId, this.props.serviceId, this.successAddLayout, this.error);
    }

    render() {
        const layouts = ["VRSafety", "RealX"];
        return (
            <Collapse>
                <Panel header="Layouts" key="Layout">
                    <List
                        dataSource={layouts}
                        renderItem={(layout: any) => (
                            <List.Item
                                actions={[
                                    <Button
                                        className={(layout === this.state.layout) ? "greenButton" : "redButton"}
                                        key="ResetManually"
                                        type="link"
                                        icon={
                                           (layout === this.state.layout) ?
                                                <CheckOutlined />
                                                :
                                                <EyeOutlined />
                                           }
                                        onClick={(layout !== this.state.layout) ? () =>
                                            this.handleLayoutChange(layout) : () => {}
                                        }
                                    >
                                        {
                                            (layout === this.state.layout) ?
                                                "Applied"
                                            :
                                                "Apply"
                                        }
                                    </Button>,
                                ]}
                            >
                                <List.Item.Meta
                                    title={
                                        `${layout}`
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </Panel>
            </Collapse>
        );
    }
}

export default LayoutOptions;
