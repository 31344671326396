import React, { Component } from 'react'
import { PhoneOutlined, ShopOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Form } from 'antd';
const { Option } = Select;

class CompanyInfo extends Component<any, {}> {
    selectionOfIndustries(){
        let items = [];         
        for (let i = 0; i < this.props.industries.length; i++)
        {             
            items.push(
                <Option key={this.props.industries[i].id}
                    value={this.props.industries[i].id}>
                    {`${this.props.industries[i].sectionNumber} ${this.props.industries[i].name}`}
                </Option>
            );   
        }
        return items;
    }

    selectionOfSizes(){
        let items = [];         
        for (let i = 0; i < this.props.sizes.length; i++)
        {             
            items.push(
                <Option key={this.props.sizes[i].id}
                    value={this.props.sizes[i].id}>
                    {`${this.props.sizes[i].name} (${this.props.sizes[i].range})`}
                </Option>
            );   
        }
        return items;
    }

    render() {
        return (
            <div>
                {/*<Form.Item validateStatus={this.props.values.errors.businessNumber ? 'error' : ''} help={this.props.values.errors.businessNumber || ''}>*/}
                {/*    <Input*/}
                {/*        prefix={<Icon type="number" style={{ color: 'rgba(0,0,0,.25)' }} />}*/}
                {/*        type="text" value={this.props.values.businessNumber}*/}
                {/*        placeholder="Business Number" onChange={this.props.handleChange('businessNumber')}*/}
                {/*    />*/}
                {/*</Form.Item>*/}
                <Form.Item validateStatus={this.props.values.errors.legalName ? 'error' : ''} help={this.props.values.errors.legalName || ''}>
                    <Input
                        prefix={<ShopOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="text" value={this.props.values.legalName}
                        placeholder="Legal Name" onChange={this.props.handleChange('legalName')}
                    />
                </Form.Item>
                <Form.Item validateStatus={this.props.values.errors.entityIndustry ? 'error' : ''} help={this.props.values.errors.entityIndustry || ''}>
                    <Select defaultValue="" onChange={this.props.handleChange('entityIndustry')} >
                        <Option value="">Select Industry</Option>
                        { this.selectionOfIndustries() }
                    </Select>
                </Form.Item>
                <Form.Item validateStatus={this.props.values.errors.companySize ? 'error' : ''} help={this.props.values.errors.companySize || ''}>
                    <Select defaultValue="" onChange={this.props.handleChange('companySize')} >
                        <Option value="">Select Size</Option>
                        { this.selectionOfSizes() }
                    </Select>
                </Form.Item>
                <Form.Item validateStatus={this.props.values.errors.phoneNumber ? 'error' : ''} help={this.props.values.errors.phoneNumber || ''}>
                    <Input
                        prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="text" value={this.props.values.phoneNumber}
                        placeholder="Phone Number" onChange={this.props.handleChange('phoneNumber')}
                    />
                </Form.Item>
            </div>
        );
    }
}

export default CompanyInfo