import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Skeleton, message } from 'antd';
import { getServicesInCompany } from '../../../api/ApiGet/UserManageApiGet';
import { CompanyConnections } from '../../../api/CompanyConnectApi/CompanyConnectApi';
import { makeAvailable, linkedContnet } from '../../../api/SharingManApi/SharingManApi';
import ServicesOverviewWithMe from '../../components/SharingCentre/ServicesOverviewWithMe';
import ContentSharedWithMe from '../../components/SharingCentre/ContentSharedWithMe';

export class SharedWithMe extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            subscriptions: [],
            companyConnections: [],
            managementCompany: {},
            content: [],
            service: {},
            contentToggle: false,
            sharedWithCompany: {},
            loading: true
        }
    }

    async componentDidMount() {
        let Company = this.props.management.managingCompany;
        await this.setState({ managementCompany: Company });
        await getServicesInCompany(Company.id, this.successServicesInCompany);
        await CompanyConnections(Company.id, this.successCompanyConnections);
        await this.setState({ loading: false });
    }

    successServicesInCompany = async (res: any) => {
        await this.setState({ subscriptions: res.data });
    }

    successCompanyConnections = async (data: any) => {
        await this.setState({ companyConnections: data });
    }

    companySharingToggle = async (company: any, serviceId: number) => {
        await this.setState({ loading: true, sharedWithCompany: company, service: serviceId });

        if (Object.keys(company).length === 0 && serviceId !== 0) {
            let payload = {
                company: this.state.managementCompany.id,
                service: serviceId,
                companySharing: company.id
            };
            await linkedContnet(payload, this.setContentFromCompany);
        }
        await this.setState({ loading: false, contentToggle: !this.state.contentToggle });
    }

    setContentFromCompany = async (data: any) => {
        await this.setState({ content: data });
    }

    makeContentAvailable = async (payload: any) => {
        await makeAvailable(payload, (data: any) => { });
        message.success('content updated');
    }

    render() {
        return (
            <div>
                {(this.state.loading) ?
                    <Skeleton active /> :
                    (this.state.contentToggle) ?
                        <ContentSharedWithMe
                            service={this.state.service}
                            content={this.state.content}
                            makeContentAvailable={this.makeContentAvailable}
                            sharedWithCompany={this.state.sharedWithCompany}
                        /> :
                        <ServicesOverviewWithMe
                            subscriptions={this.state.subscriptions}
                            companySharingToggle={this.companySharingToggle}
                            companyConnections={this.state.companyConnections}
                        />
                }
            </div>
        )
    }
}

function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management
    };
}

export default connect(mapToStateToProps, {})(SharedWithMe);