function createInviteCompanyOwnerModel(state: any){
    return {
        email: state.email,
        entityName: state.entityName,
        role: state.role,
        isOwner: state.isOwner,
        parentCompany: state.parentCompany,
        service:"default"
    }
}

interface Roles {
    id: string;
    name: string;
    normalizedName: string;
    concurrencyStamp: string;
}

export interface InviteCompanyOwnerState {
    email: string;
    entityName: string;
    role: string;
    isOwner: boolean;
    userRole: string;
    roles: Array<Roles>;
    parentCompany: string;
    errors: any;
    loading: boolean;
    disabled: boolean;
}

export { createInviteCompanyOwnerModel };