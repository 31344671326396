import axios from 'axios';
import { ROOT_SERVICE } from '../Routes';

export async function createRootService(postObject: object, callback: Function, errorcallback: Function){
    await axios.post( ROOT_SERVICE, postObject )
    .then(async (res) => {
        //do something
        if(callback != null){
            await callback(res);
        }
    })
    .catch(async (err) => {
        // catch error
        if(errorcallback != null){
            await errorcallback(err);
        }
    });
}

export async function addLayout(layout: string, companyId: string, serviceId: number, callback: Function, errorcallback: Function) {
    await axios.post(`api/v1/root/addLayout/${layout}/${companyId}/${serviceId}`)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorcallback != null) {
                await errorcallback(err);
            }
        });
}

export async function editEndDate(date: any, companyId: string, serviceId: number, callback: Function, errorcallback: Function) {
    await axios.patch(`api/v1/root/editEndDate/${date}/${companyId}/${serviceId}`)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorcallback != null) {
                await errorcallback(err);
            }
        });
}

export async function editServiceVisibility(field: string, serviceId: number, callback: Function, errorcallback: Function) {
    await axios.patch(`api/v1/root/editServiceVisibility/${field}/${serviceId}`)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorcallback != null) {
                await errorcallback(err);
            }
        });
}

export async function refreshSharedToken(postObject: object, callback: Function, errorcallback: Function) {
    await axios.post("api/v1/shared-auth/refresh-share-token", postObject)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorcallback != null) {
                await errorcallback(err);
            }
        });
}

export async function editLockoutEnabled(userId: string, block:boolean, date:any, callback: Function, errorcallback: Function) {
    await axios.patch(`api/v1/root/blockUser/${userId}/${block}/${date}`)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorcallback != null) {
                await errorcallback(err);
            }
        });
}

export async function addNewLayout(postObject: object, callback: Function, errorcallback: Function) {
    await axios.post(`api/v1/root/addNewLayout`, postObject)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorcallback != null) {
                await errorcallback(err);
            }
        });
}

export async function editLayout(postObject: object, callback: Function, errorcallback: Function) {
    await axios.patch(`api/v1/root/editLayout`, postObject)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorcallback != null) {
                await errorcallback(err);
            }
        });
}