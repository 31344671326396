import axios from 'axios';
import { errorCallback } from '../ErrorCallback';
import { USER_PERMISSIONS,ENABLE_SERVICE } from '../Routes';

export async function getUserPermissions(
    config: object,
    callback: Function,
    errCallback: Function = errorCallback
) {
    await axios
        .get(USER_PERMISSIONS, config)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errCallback != null) {
                await errCallback(err);
            }
        });
}
export async function enableService(serviceId:number,callback: Function, errCallback: Function = errorCallback)
{
    await axios
        .post(ENABLE_SERVICE+`/${serviceId}`)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errCallback != null) {
                await errCallback(err);
            }
        });
}
