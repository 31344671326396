import React, { Component } from 'react';
import { ENTITIES, REQUEST_CONNECT } from '../../components/routes';
import Suggestions from '../../components/Companies/Suggestions';
import { getCurrentDate } from '../../../utils/dateUtils';
import { Tabs, Skeleton, message } from 'antd';
import axios from 'axios';

class Companies extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            companies: [],
            loading: true
        }
    }

    async componentDidMount() {
        await axios.get(ENTITIES)
            .then(async (res) => {
                await this.setState({ companies: res.data })
            })
            .catch(async (err) => {
                if (err.response.status === 404) {
                    message.error(`URL '${err.response.config.url}' ${err.response.status} | ${err.response.statusText}`);
                }
                else {
                    message.error(err.response.data);
                }
            });
        await this.setState({ loading: false })
    }

    async onConnect(legalName: string) {
        await axios.post(REQUEST_CONNECT, {
            company: legalName,
            submittedAt: getCurrentDate()
        })
            .then(async (res) => {
                message.info(res.data);
            })
            .catch(async (err) => {
                if (err.response.status === 404) {
                    message.error(`URL '${err.response.config.url}' ${err.response.status} | ${err.response.statusText}`);
                }
                else if (err.response.status === 500) {
                    message.error(`${err.response.status} | ${err.response.statusText}`);
                }
                else {
                    message.error(err.response.data);
                }
            });
    }

    render() {
        return (
            <div>
                {(this.state.loading) ?
                    <Skeleton active />
                    :
                    <Tabs tabPosition={'right'}
                        defaultActiveKey="1"
                        items={
                            [{
                                key: "1",
                                label: "Suggestions",
                                children: (<Suggestions
                                    companies={this.state.companies}
                                    onConnect={this.onConnect}
                                />)
                            }]
                        }

                    />
                }
            </div>
        )
    }
}
//tab that is currently hidden
{/*<TabPane tab="Invitations" key="2">*/ }
{/*    Invitations*/ }
{/*</TabPane>*/ }
export default Companies