import React, { Component } from 'react';
import { List, Skeleton, Avatar, Button, message, Popover, Radio } from 'antd';
import { ACCEPT_REQUEST_CONNECT } from '../routes';
import axios from 'axios';
import { getCurrentDate } from '../../../utils/dateUtils';

class Requests extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            loading: true,
            internal: false,
            hovered: false,
            requests: []
        };
        this.acceptRequest = this.acceptRequest.bind(this);
    }

    async componentDidMount(){
        this.setState({ loading: false, requests: this.props.requests });
    }

    acceptRequest = (answer: boolean) => async (e: any) => {
        //e.preventDefault();
        let id = e.target.getAttribute('data-id');

        await axios.post(ACCEPT_REQUEST_CONNECT, {
            requestId: id,
            requestAnswer: answer,
            level: this.state.internal,
            submittedAt: getCurrentDate()
        })
        .then(async (res) => {
            await this.setState({ requests: res.data });
        })
        .catch(async (err) => {
            console.error(err.response);
            message.error('Something went wrong, check log.');
        });
    }

    handleChange = (input: any) => (e: any) => {
        this.setState({ [input]: e.target.value } as any);
    }

    handleHoverChange = (visible: any) => {
        this.setState({ hovered: visible });
    };

    render() {
        return (
            <List
                className="demo-loadmore-list"
                loading={this.state.loading}
                itemLayout="horizontal"
                dataSource={this.state.requests}
                renderItem={(request: any) => (
                <List.Item key={request.id}
                    actions={[
                        <Popover
                            content={
                                <Radio.Group onChange={this.handleChange('internal')} defaultValue={this.state.internal}>
                                    <Radio.Button value={true}>Internal</Radio.Button>
                                    <Radio.Button value={false}>Public</Radio.Button>
                                </Radio.Group>
                            }
                            title={
                                <span>Content Access Level?</span>
                            }
                        >
                            <Button key="accept" type="primary" onClick={this.acceptRequest(true)} data-id={request.id}>Accept</Button>
                        </Popover>,
                        <Button key="delete" onClick={this.acceptRequest(false)} data-id={request.id}>Reject</Button>,
                    ]}
                >
                    <Skeleton avatar title={false} loading={false} active>
                    <List.Item.Meta
                        avatar={
                            <Avatar src={
                                (request.user.avatarImage) ?
                                request.user.avatarImage
                                :
                                "https://i1.wp.com/bitspacedevelopment.com/wp-content/uploads/2019/02/favicon_bsd_420.png"
                            } />
                        }
                        title={<a href={`/user/${request.user.userName}`}>{request.user.firstName}</a>}
                        description={`${request.user.firstName} ${request.user.lastName}`}
                    />
                    </Skeleton>
                    
                </List.Item>
                )}
            />
        )
    }
}

export default Requests;