import React, { Component } from 'react';
import CompanyEditForm from './CompanyEditForm';
import OwnerEditForm from './OwnerEditForm';
import { getCurrentDate } from '../../../utils/dateUtils';
import { message, Row, Col } from 'antd';
import axios from 'axios';
import {
    COMPANY_AVATAR_IMAGE_UPLOAD,
    COMPANY_BANNER_IMAGE_UPLOAD,
    UPADATE_COMPANY,
} from '../routes';

class CompanyEdit extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: 0,
            companyInformation: 0,
            companySize: 0,
            industryCode: 0,
            phoneNumber: '',
            address: '',
            city: '',
            province: '',
            postalCode: '',
            totalNumberOfEmployees: '',
            businessNumber: '',
            legalEntityName: '',
            logoImage: '',
            bannerImage: '',
            description: '',
            logoLoad: false,
            bannerLoad: false,
            errors: [],
            loading: true,
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        await this.setState({
            loading: false,
            id: this.props.company.id,
            companyInformation: this.props.company.entityInformationId,
            companySize: this.props.company.companyInformation.entitySizeId,
            companyIndustry: this.props.company.companyInformation
                .industryCodeId,
            businessNumber: this.props.company.businessNumber,
            legalName: this.props.company.legalEntityName,
            description: this.props.company.description,
            phoneNumber: this.props.company.companyInformation.phoneNumber,
            address: this.props.company.companyInformation.address,
            city: this.props.company.companyInformation.city,
            province: this.props.company.companyInformation.province,
            postalCode: this.props.company.companyInformation.postalCode,
            numberOfEmployees: this.props.company.companyInformation
                .totalNumberOfEmployees,
            bannerImage: this.props.company.bannerImage,
            logoImage: this.props.company.logoImage,
        });
    }

    createUpdateCompanyObj(data: any) {
        return {
            id: data.id,
            companySize: data.companySize,
            companyIndustry: data.companyIndustry,
            businessNumber: data.businessNumber,
            legalName: data.legalName,
            description: data.description,
            phoneNumber: data.phoneNumber,
            address: data.address,
            city: data.city,
            province: data.province,
            postalCode: data.postalCode,
            numberOfEmployees: data.numberOfEmployees,
            submittedAt: getCurrentDate(),
        };
    }

    handleChange = (input: any) => async (e: any) => {
        if (typeof e === 'object') {
            await this.setState({ [input]: e.target.value } as any);
        } else {
            await this.setState({ [input]: e } as any);
        }
    };

    handleAvatarChange = async (info: any) => {
        if (info.file.status === 'uploading') {
            this.setState({ logoLoad: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            this.getBase64(info.file.originFileObj, (logoImage: string) =>
                this.setState({ logoImage, logoLoad: false })
            );
        }

        let response = info.file;
        if (response.hasOwnProperty('xhr')) {
            await axios
                .post(COMPANY_AVATAR_IMAGE_UPLOAD, {
                    company: this.state.id,
                    imageUrl: response.xhr.responseText,
                    submittedAt: getCurrentDate(),
                })
                .then(async (res) => {
                    message.success('Company logo image uploaded.');
                })
                .catch(async (err) => {
                    message.error(
                        `${err.response.status} | ${err.response.statusText} `
                    );
                });
        }
    };

    handleBannerChange = async (info: any) => {
        if (info.file.status === 'uploading') {
            this.setState({ bannerLoad: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            this.getBase64(info.file.originFileObj, (bannerImage: string) =>
                this.setState({ bannerImage, bannerLoad: false })
            );
        }

        let response = info.file;
        if (response.hasOwnProperty('xhr')) {
            await axios
                .post(COMPANY_BANNER_IMAGE_UPLOAD, {
                    company: this.state.id,
                    imageUrl: response.xhr.responseText,
                    submittedAt: getCurrentDate(),
                })
                .then(async (res) => {
                    message.success('Company avatar image uploaded.');
                })
                .catch(async (err) => {
                    message.error(
                        `${err.response.status} | ${err.response.statusText} `
                    );
                });
        }
    };

    getBase64(img: any, callback: any) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    async onSubmit(e: any) {
        //e.preventDefault();
        let company = this.createUpdateCompanyObj(this.state);

        await axios
            .patch(UPADATE_COMPANY, company)
            .then(async (res) => {
                
                message.success('Account updated successfully.');
            })
            .catch(async (err) => {
                console.error(err.response);
                message.error('Something went wrong, check log.');
            });
    }

    render() {
        return (
            // removed type flex in Row below
            <Row  style={{display:"flex" }} justify="space-between">
            <Col xs={24} sm={24} md={11}>
            <CompanyEditForm
                values={this.state}
                handleChange={this.handleChange}
                handleAvatarChange={this.handleAvatarChange}
                handleBannerChange={this.handleBannerChange}
                onSubmit={this.onSubmit}
                authUser= {this.props.authUser}
            />
            </Col>
            <Col xs={24} sm={24} md={11}>
            <OwnerEditForm
                owner={this.props.owner}
            />
            </Col>
            </Row>
        );
    }
}

export default CompanyEdit;
