import React, { Component } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Collapse, List, Button, Modal } from 'antd';
import AllUsers from './AllUsers';
import AllSubscriptions from './AllSubscriptions';
const { Panel } = Collapse;
const { confirm } = Modal;
class AllCompanies extends Component<any, any> {

    deleteComp = async (companyId:string, companyName:string) => {
        await confirm({
            title: `Are you sure you want to delete ${companyName} permanently?`,
            okText:"Delete",
            onOk: async () => {
                await this.props.deleteCompany(companyId);
            },
            onCancel() {
            },
        });
    }

    render() {
        return (
            <Collapse>                
                {this.props.rootUsers.map((item: any) => (
                    <Panel header={item.company} key={item.company}>
                        <List.Item
                            actions={[
                                <Button
                                    key="delete"
                                    type="primary"
                                    danger
                                    icon={<DeleteOutlined />}
                                    onClick={() =>
                                        this.deleteComp(item.companyId, item.company)
                                    }
                                >
                                    Delete Company
                                </Button>,
                                    ]}
                                    >
                            <List.Item.Meta
                                title={
                                    <span>{ item.company }</span>
                                }

                            />

                        </List.Item>
                        <AllUsers
                            companyId={item.companyId}
                            users={item.users}
                            deleteUser={this.props.deleteUser}
                        />
                        <AllSubscriptions
                            companyId={item.companyId}
                            services={this.props.services}
                        />
                    </Panel>
                ))}
            </Collapse>
        );
    }
}

export default AllCompanies;
