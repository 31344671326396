import axios from 'axios';
import { errorCallback } from '../ErrorCallback';
import { USER_CONNECTION_DELETE } from '../Routes';

export async function DeleteUserConnectionAndPermissionsFromCompany(
    userId: string,
    compId: string,
    callback: Function
) {
    await axios
        .delete(`${USER_CONNECTION_DELETE}${userId}/${compId}`)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorCallback != null) {
                await errorCallback(err);
            }
        });
}
