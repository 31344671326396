import axios from 'axios';
import { errorCallback } from '../ErrorCallback';
import {
    STRIPE_CUSTOMER,
    STRIPE_PRICES,
    PAY_FOR_SUBSCRIPTION,
} from '../Routes';

export async function StripeCustomer(payload: string, callback: Function) {
    await axios
        .get(`${STRIPE_CUSTOMER}${payload}`)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorCallback != null) {
                await errorCallback(err);
            }
        });
}

export async function StripePrices(callback: Function) {
    await axios
        .get(STRIPE_PRICES)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorCallback != null) {
                await errorCallback(err);
            }
        });
}

export async function PayForSubscription(payload: any, callback: Function) {
    await axios
        .post(PAY_FOR_SUBSCRIPTION, payload)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorCallback != null) {
                await errorCallback(err);
            }
        });
}
