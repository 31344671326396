import { menuItem } from "../../interfaces/MenuSettings";
import { HomeIcon } from "../icons/icons";


export const Root: menuItem[] = [
    {
        name: 'root Menu',
        url: 'root',
        icon: HomeIcon,
    }
];