import React, { Component } from 'react';
import { SwapOutlined, UserOutlined } from '@ant-design/icons';
import { Divider, Avatar, Button, Typography } from 'antd';

const { Title, Paragraph, Text } = Typography;

class CompanyInfo extends Component<any, {}> {
    componentDidMount() {
        let company = this.props.match.params.company;
        this.props.setCompany(company);
    }

    render() {
        return (
            <section
                style={{
                    boxShadow:
                        '0 0 0 1px rgba(0,0,0,0.15), 0 2px 3px rgba(0,0,0,0.2)',
                }}>
                <figure className='cover-img onload lazy-loaded'>
                    <img
                        alt={this.props.company.legalEntityName}
                        src={
                            this.props.company.bannerImage
                                ? this.props.company.bannerImage
                                : 'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png'
                        }
                        style={{
                            width: '100%',
                            height: '335px',
                            objectFit: 'cover',
                        }}
                    />
                </figure>
                <div style={{ padding: '24px', position: 'relative' }}>
                    {this.props.company.logoImage === null ? (
                        <Avatar
                            size={80}
                            icon={<UserOutlined />}
                            style={{
                                border: '2px solid #fff',
                                margin: '-100px 0 12px',
                                backgroundColor: '#fff',
                            }}
                        />
                    ) : (
                        <Avatar
                            size={80}
                            src={this.props.company.logoImage}
                            style={{
                                border: '2px solid #fff',
                                margin: '-100px 0 12px',
                                backgroundColor: '#fff',
                            }}
                        />
                    )}
                    <div>
                        <Title level={2}>
                            {this.props.company.legalEntityName}
                        </Title>
                        <Paragraph>
                            {this.props.company.description === '' ||
                            this.props.company.description !== null
                                ? this.props.company.description.substring(
                                      0,
                                      200
                                  )
                                : 'No Description available.'}
                        </Paragraph>
                        <Text strong>Winnipeg, Manitoba</Text>
                        <Divider />
                        {this.props.company.connected ? (
                            <div>Connected</div>
                        ) : (
                            <Button
                                type='primary'
                                shape='round'
                                icon={<SwapOutlined />}
                                size='large'
                                onClick={this.props.onConnect}>
                                Connect
                            </Button>
                        )}
                    </div>
                </div>
            </section>
        );
    }
}

export default CompanyInfo;
