export const ENTITY_INDUSTRIES = '/api/v1/entity/industries';
export const ENTITY_SIZES = '/api/v1/entity/sizes';
export const ROOT_USERS = '/api/v1/root/users';
export const All_USERS = '/api/v1/root/usersRoles';
export const All_LAYOUTS = '/api/v1/root/allLayouts';
export const ROOT_SERVICES = '/api/v1/services/services';
export const ROOT_SERVICE = '/api/v1/services/service';
export const USER_TOKEN = '/api/v1/auth/userToken/';

export const FULL_ENTITY = '/api/v1/entity/full/';
export const USER_COMPANY_USERS = '/api/v1/user-company/users';
export const USER_COMPANY_CONNECTIONS = '/api/v1/user-company/user-connections';
export const PAYMENT_SUBSCRIPTIONS = '/api/v1/payment/subscriptions/';
export const TRANSFER_OWNER = '/api/v1/user-company/owner-transfer-request';
export const USER_PERMISSION_CHANGE =
    '/api/v1/access-level/user/permission-change';

export const USER_PERMISSION_DELETE = '/api/v1/user-company/user/';
export const USER_CONNECTION_DELETE = '/api/v1/connect/connection/';

export const COMPAY_SUBSCRIPTIONS = '/api/v1/payment/subscriptions';
export const CHILD_COMPANIES = '/api/v1/entity/child-companies/';

export const ENTITY = '/api/v1/entity/company/';

export const NOTIFICATIONS = '/api/v1/notification/notifications';

export const SHARED_SERVICE_REQUESTS =
    '/api/v1/user-company/shared-service-requests';
export const SHARE_SERVICE_REQUEST =
    '/api/v1/user-company/share-service-request';
export const SHARE_SERVICE_ACTION = '/api/v1/user-company/share-service-action';
export const SHARING_SERVICE_CONTENT = '/api/v1/sharing-service/content';

export const USER_PERMISSIONS = '/api/v1/access-level/user/permissions';

export const ALL_COMPANIES_CONNECTIONS = '/api/v1/company-connect/companies/';
export const CONNECTED_COMPANIES = '/api/v1/company-connect/connections';
export const REQUEST_COMPANY_CONNECT = '/api/v1/company-connect/request';
export const COMPANY_CONNECT_REQUESTS = '/api/v1/company-connect/requests/';
export const COMPANY_CONNECT_ACTION = '/api/v1/company-connect/action';

export const COMMUNICATE_SERVICE_PERMISSION =
    '/api/v1/communication/service-permissions';
export const LINKED_CONTENT = '/api/v1/sharing-service/linked-content';
export const MAKE_AVAILABLE = '/api/v1/sharing-service/make-available';
export const AVAILABLE_CONTENT = '/api/v1/sharing-service/available-content';

export const USER_LOGIN_PERMS = '/api/v1/auth/login-user-perms';
export const DELETE_USER = '/api/v1/root/user/';
export const DELETE_USER_FROM_COMP = '/api/v1/root/userFromCompany/';
export const DELETE_COMPANY = '/api/v1/root/company/';

export const STAFF_NORMAL_USER_IN_COMP =
    '/api/v1/entity/staff-nomal-users-in-company/';

export const UPDATE_USER_INTERNAL_CONNECTION =
    '/api/v1/entity/user-internal-connection';

export const UPDATE_USER_ROLE_IN_COMP = '/api/v1/user-company/user-roles';

export const ROLES = '/api/v1/account/roles';

export const STRIPE_CUSTOMER = 'api/v1/stripe-api/customer/';
export const STRIPE_PRICES = 'api/v1/stripe-api/prices';
export const PAY_FOR_SUBSCRIPTION = 'api/v1/payment/subscription';
export const ENABLE_SERVICE = 'api/v1/payment/enable-service';

export const BSD_ROLE = 'api/v1/companies/bsdRole';
