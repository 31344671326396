import React, { Component } from 'react';
import CreateUserForm from './CreateUser/CreateUserForm';
import { SmileOutlined } from '@ant-design/icons';
import { Skeleton, notification, message } from 'antd';
import { isValid } from '../../../utils/validate';
import { getCurrentDate } from '../../../utils/dateUtils';
import axios from 'axios';
import { CREATE_ACCOUNT } from '../routes';
import { isNullOrUndefined } from 'util';

class CreateUsers extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            userName: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            jobPosition: '',
            address: '',
            city: '',
            province: '',
            postalCode: '',
            role: '',
            internal: false,
            company: {},
            submittedAt: '',
            step: 1,
            loading: true,
            errors: [],
            timeZone:""
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        await this.setState({ company: this.props.company, loading: false });
    }

    handleChange = (input: any) => async (e: any) => {
        if (typeof e === 'object') {
            await this.setState({ [input]: e.target.value } as any);
        } else {
            await this.setState({ [input]: e });
        }
        let userInvite = this.createUserViewModel(this.state);
        let checker = isValid(userInvite);
        await this.setState({ errors: checker.errors });
        
    };

    createUserViewModel(value: any) {
        return {
            userName: value.email,
            firstName: value.firstName,
            lastName: value.lastName,
            email: value.email,
            password: value.password,
            jobPosition: value.jobPosition,
            address: value.address,
            city: value.city,
            province: value.province,
            postalCode: value.postalCode,
            role: value.role,
            internal: value.internal,
            company: value.company.id,
            submittedAt: getCurrentDate(),
            timeZone: value.timeZone,
            service:"Janus",
        };
    }

    async onSubmit(e: any) {
        //e.preventDefault();
        await this.setState({ loading: true });
        let createUser = this.createUserViewModel(this.state);
        let checker = isValid(createUser);
        if (checker.isValid) {
            await axios
                .post(CREATE_ACCOUNT, createUser)
                .then(async (res) => {
                    notification.open({
                        message: 'Successfully Registered',
                        description: `You have successfully register ${res.data.firstName}, tell them to check their email ${res.data.email} for a confirmation email.`,
                        icon: (
                            <SmileOutlined style={{ color: '#108ee9' }} />
                        ),
                    });
                   
                    if (!isNullOrUndefined(this.props.refreshListsOnUpdate)) {
                        this.props.refreshListsOnUpdate();
                    }
                    
                    this.setState({
                        loading: false,
                        step: 1,
                        userName: '',
                        firstName: '',
                        lastName: '',
                        email: '',
                        password: '',
                        jobPosition: '',
                        address: '',
                        city: '',
                        province: '',
                        postalCode: '',
                        submittedAt: '',
                        timeZone:'',
                        errors: [],
                    });
                })
                .catch(async (err) => {
                    console.log(err);
                    this.setState({
                        loading: false,
                        errors: [],
                    });
                    message.error(err.response.data);
                });
        }
        this.setState({ errors: checker.errors, loading: false });
    }

    // Proceed to next step
    nextStep = () => {
        this.setState({ step: this.state.step + 1 });
    };

    // Go back to prev step
    prevStep = () => {
        this.setState({ step: this.state.step - 1 });
    };

    render() {
        return (
            <div>
                {this.state.loading ? (
                    <Skeleton active />
                ) : (
                    <CreateUserForm
                        values={this.state}
                        handleChange={this.handleChange}
                        onSubmit={this.onSubmit}
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        roles={this.props.roles}
                    />
                )}
            </div>
        );
    }
}

export default CreateUsers;
