function createInvitationLinkModel(state: any) {
    return {
        company: state.company,
        role: state.role,
        internal: state.internal,
    }
}

interface Roles {
    id: string;
    name: string;
    normalizedName: string;
    concurrencyStamp: string;
}

export interface GetInviteLinkState {
    internal: boolean;
    errors: any;
    role: string;
    roles: Array<Roles>;
    company: string;
    confirmed: boolean;
    loading: boolean;
    disabled: boolean;
    link: string,
    showLinkModal: boolean,
}

export { createInvitationLinkModel };