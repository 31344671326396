import React, { Component } from 'react';
import { Tabs } from 'antd';
import { ServiceTabWithMe } from './ServiceTabWithMe';

class ServicesOverviewWithMe extends Component<any, any> {
    render() {
        return (
            <Tabs tabPosition={'left'}>
                items={
                    this.props.subscriptions.map((subscription: any, index: number) => {
                        const id = String(index);
                        return {
                            key:`${subscription.service.id}`,
                            label: `${subscription.service.name}`,
                            children: (                  
                            <ServiceTabWithMe
                                service={subscription.service}
                                companyConnections={this.props.companyConnections}
                                companySharingToggle={this.props.companySharingToggle}
                                />
                            )
                        }
                    }                  
                )}
            </Tabs>
        )
    }
}

export default ServicesOverviewWithMe;