import React, { Component } from 'react'
import { LockOutlined, MailOutlined, SmileOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Form } from 'antd';

class UserInfo extends Component<any, {}> {
    render() {
        return (
            <div>
                <Form.Item validateStatus={this.props.values.errors.firstName ? 'error' : ''} help={this.props.values.errors.firstName || ''}>
                    <Input
                        prefix={<SmileOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="text" value={this.props.values.firstName}
                        placeholder="First Name" onChange={this.props.handleChange('firstName')}
                    />
                </Form.Item>
                <Form.Item validateStatus={this.props.values.errors.lastName ? 'error' : ''} help={this.props.values.errors.lastName || ''}>
                    <Input
                        prefix={<SmileOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="text" value={this.props.values.lastName}
                        placeholder="Last Name" onChange={this.props.handleChange('lastName')}
                    />
                </Form.Item>
                <Form.Item validateStatus={this.props.values.errors.email ? 'error' : ''} help={this.props.values.errors.email || ''}>
                    <Input
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="email" value={this.props.values.email}
                        placeholder="Email" onChange={this.props.handleChange('email')}
                        disabled={this.props.values.hasEmail}
                    />
                </Form.Item>
                <Form.Item validateStatus={this.props.values.errors.password ? 'error' : ''} help={this.props.values.errors.password || ''}>
                    <Input
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password" value={this.props.values.password}
                        placeholder="Password"  onChange={this.props.handleChange('password')}
                    />
                </Form.Item>
            </div>
        );
    }
}

export default UserInfo