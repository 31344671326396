import React, { Component } from 'react';
import { FormOutlined, LeftOutlined, LockOutlined, MailOutlined, RightOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Select, Form } from 'antd';
import { timeZonesNames } from "@vvo/tzdb";

const { Option } = Select;
const timeZones = timeZonesNames.filter((zone: any) => zone.includes("America"));

class UserForm extends Component<any, any> {
    render() {
        return (
            <div>
                <Form.Item
                    validateStatus={
                        this.props.values.errors.firstName ? 'error' : ''
                    }
                    help={this.props.values.errors.firstName || ''}
                >
                    <Input
                        prefix={
                            <FormOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                        }
                        type="text"
                        value={this.props.values.firstName}
                        placeholder="First Name"
                        onChange={this.props.handleChange('firstName')}
                    />
                </Form.Item>
                <Form.Item
                    validateStatus={
                        this.props.values.errors.lastName ? 'error' : ''
                    }
                    help={this.props.values.errors.lastName || ''}
                >
                    <Input
                        prefix={
                            <FormOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                        }
                        type="text"
                        value={this.props.values.lastName}
                        placeholder="Last Name"
                        onChange={this.props.handleChange('lastName')}
                    />
                </Form.Item>
                <Form.Item
                    validateStatus={
                        this.props.values.errors.email ? 'error' : ''
                    }
                    help={this.props.values.errors.email || ''}
                >
                    <Input
                        prefix={
                            <MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                        }
                        type="email"
                        value={this.props.values.email}
                        placeholder="Email"
                        onChange={this.props.handleChange('email')}
                    />
                </Form.Item>
                <Form.Item
                    validateStatus={
                        this.props.values.errors.password ? 'error' : ''
                    }
                    help={this.props.values.errors.password || ''}
                >
                    <Input
                        prefix={
                            <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                        }
                        type="password"
                        value={this.props.values.password}
                        placeholder="Password"
                        onChange={this.props.handleChange('password')}
                    />
                </Form.Item>
                <Form.Item validateStatus={this.props.values.errors.time ? 'error' : ''} help={this.props.values.errors.time || ''}>
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="TimeZone"
                        optionFilterProp="children"
                        onChange={this.props.handleChange('timeZone')}
                        filterOption={(input: any, option: any) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value="">Select One</Option>
                        {
                            timeZones.map((zone: any) =>

                                <Option key={zone}
                                    value={zone}>
                                    {zone}
                                </Option>)
                        }
                    </Select>
                </Form.Item>
                <div style={{ textAlign: 'center' }}>
                    <Button
                        className="bsd-btn-teal"
                        style={{ marginRight: '20px' }}
                        onClick={this.props.prevStep}
                    >
                        Take me back
                        <LeftOutlined />
                    </Button>

                    <Button
                        className="bsd-btn-teal"
                        onClick={this.props.nextStep}
                    >
                        Continue
                        <RightOutlined />
                    </Button>
                </div>
            </div>
        );
    }
}

export default UserForm;
