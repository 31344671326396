import React, { Component } from 'react';
import { Collapse, List, Button, message, DatePicker, Row } from 'antd';
import { editEndDate } from '../../../api/ApiPost/RootApiPost';
import { createMySqlDate} from '../../../utils/dateUtils';
import moment from 'moment';

interface State {
    endDate:string
};
class DateChange extends Component<any, State> {   
    state: State = {
        endDate: ""
    }

    public constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.setState({ endDate: this.props.endDate});
    }

    successChangeDate = (data: any) => {
        this.setState({ endDate: data.data.endAt });
    };

    error = (data: any) => {
        console.log(data);
        message.error("Oops!! Failed to update Layout!!");
    }

    changeEndDate=(date:any)=>{
        let endDate= createMySqlDate(date.format('YYYY-MM-DD'));
        editEndDate(endDate, this.props.companyId, this.props.serviceId, this.successChangeDate, this.error);
    }

    render() {
        const layouts = ["VRSafety", "RealX"];
        return (
            <Row>
                <span>End Date: </span>
                <DatePicker 
                    onChange={this.changeEndDate}
                    value={(this.state.endDate)?moment(this.state.endDate):null}
                    style={{display:"inline-block"}}
                />
            </Row>
        );
    }
}

export default DateChange;
