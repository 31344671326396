import React from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Switch, Select, Form } from 'antd';
const { Option } = Select;

export default function PromoteUserRoleModal(props: any) {
    return (
        <Modal
            title="Promote User Roles"
            open={props.promoteUserVisible}
            onOk={() => props.handleUserPromoteChanges()}
            onCancel={() => props.handleModalCancel()}
        >
            <h1>{props.selectedUser?.user?.userName}</h1>
            <Form.Item label="Internal?">
                <Switch
                    checked={props.selectedUser.internal}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={() => props.handleUserInternalToggle()}
                />
            </Form.Item>
            <Form.Item>
                <Select
                    style={{ width: '100%' }}
                    defaultValue={props.selectedUser.role}
                    onChange={(data: string) =>
                        props.handleUserRoleChange(data)
                    }
                >
                    <Option value="">Select One</Option>
                    {props.accountRoles.map((accountRole: any) => (
                        <Option key={accountRole.id} value={accountRole.name}>
                            {accountRole.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </Modal>
    );
}
