import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Avatar, List, Button } from 'antd';

export default function AvailableContent(props: any) {
   return (
        <List
            header={<div>{props.header}</div>}
            dataSource={props.content}
            renderItem={(content: any) => (
                <List.Item key={content.id} actions={[]}>
                    <List.Item.Meta
                        avatar={
                            <Avatar
                                shape="square"
                                size={64}
                                icon={<InfoCircleOutlined />}
                            />
                        }
                        title={
                            <Button
                                type='link'
                                onClick={() => props.viewContentWithPermissions(
                                    props.subscription.service.id,
                                    content.contentURL
                                )}
                            >{content.name}</Button>
                        }
                        description={content.description}
                    />
                </List.Item>
            )}
        />
    );
}
