import React, { Component } from 'react';
import { Checkbox, Button, Modal, Collapse, Row, Col, Table, Select, Tabs } from 'antd';
import { SERVICE_CHECKBOX_OPTIONS } from './module';

const { Panel } = Collapse;
const { Option } = Select;

class ServicePermissionModal extends Component<any, any> {
    submitPermissions(){
        this.props.requestServicePermission(this.props.user);
    }

    render() {
        return (
            <Modal
                title={`VR Safety Permissions for ${this.props.user?.user?.firstName} ${this.props.user?.user?.lastName}`}
                open={this.props.servicePermissionModalToggle}
                onOk={this.props.closeServicePermissionModal}
                onCancel={this.props.closeServicePermissionModal}
                closable
            >
                <Row>
                {
                    SERVICE_CHECKBOX_OPTIONS.map((data: any) => (
                    <Col span={8} key={data.label}>
                        <Checkbox value={data.value} onChange={this.props.handleChange(data.label)}
                            checked={this.props[data.label]}>
                            {data.label.toUpperCase()}
                        </Checkbox>
                    </Col>
                    ))
                }
                </Row>
                <Row>
                    <Col span={20}></Col>
                    <Col>
                        <Button onClick={this.submitPermissions.bind(this)}>Save</Button>
                    </Col>
                </Row>
            </Modal>
        )
    }
}

export default ServicePermissionModal