function createInvitationModel(state: any){
    return {
        email: state.email,
        company: state.company,
        role: state.role,
        internal: state.internal,
        isOwner: state.isOwner,
        service: "default"
    }
}

interface Roles {
    id: string;
    name: string;
    normalizedName: string;
    concurrencyStamp: string;
}

export interface SendInviteState
{
    internal: boolean;
    errors: any;
    email: string;
    isOwner: boolean;
    role: string;
    roles: Array<Roles>;
    company: string;
    confirmed: boolean;
    loading: boolean;
    disabled: boolean;
}

export { createInvitationModel };