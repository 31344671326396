import React, { Component } from 'react';
import UserForm from './UserForm';
import { getCurrentDate } from '../../../../utils/dateUtils';
import { isValid } from '../../../../utils/validate';
import { SmileOutlined } from '@ant-design/icons';
import { Skeleton, message, notification } from 'antd';
import axios from 'axios';
import { ACCEPT_INVITE } from '../../routes';
import { ACCEPT_COMMON_INVITE } from '../../routes';

class UserInvitation extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            token: '',
            userName: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            company: '',
            role: '',
            internal: '',
            isOwner: '',
            jobPosition: '',
            address: '',
            city: '',
            province: '',
            postalCode: '',
            timeZone:'',
            submittedAt: '',
            step: 1,
            loading: true,
            errors: [],
            hasEmail:false
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        var email;
        var owner;
        var hasEmail= false;
        if (this.props.invitation.email) {
            email = this.props.invitation.email
            hasEmail = true;
        }
        else {
            email = '';
        }
        await this.setState({ 
            token: this.props.invitation.token, email: email,
            role: this.props.invitation.role, company: this.props.invitation.company, 
            isOwner: this.props.invitation.isOwner, internal: this.props.invitation.internal,
            hasEmail:hasEmail
        });


        await this.setState({ loading: false });
    }

    handleChange = (input: any) => async (e: any) => {
        if (typeof e === 'object') {
            await this.setState({ [input]: e.target.value } as any);
        } else {
            await this.setState({ [input]: e });
        }
        let userInvite = this.createUserInvitationViewModel(this.state);
        let checker = isValid(userInvite);
        this.setState({ errors: checker.errors});
    }

    createUserInvitationViewModel(value: any){
        return {
            token: value.token,
            userName: value.email,
            firstName: value.firstName,
            lastName: value.lastName,
            email: value.email,
            password: value.password,
            company: value.company,
            role: value.role,
            isOwner: value.isOwner,
            internal: value.internal,
            jobPosition: value.jobPosition,
            address: value.address,
            city: value.city,
            province: value.province,
            postalCode: value.postalCode,
            timeZone:value.timeZone,
            submittedAt: getCurrentDate(),
            service:"default",
        }
    }


    async onSubmit(e: any) {
        //e.preventDefault();
        this.setState({ loading: true });
        let userInvitation = this.createUserInvitationViewModel(this.state);
        let checker = isValid(userInvitation);
        if(checker.isValid)
        {
            if (this.props.invitation.email) {
                await axios.post(ACCEPT_INVITE, userInvitation)
                    .then(async (res) => {
                        notification.open({
                            message: 'Successfully Registered',
                            description:
                                `You have successfully registered. Welcome ${res.data.firstName}, check your email ${res.data.email} for a confirmation email.`,
                            icon: <SmileOutlined style={{ color: '#108ee9' }} />,
                        });
                        this.setState({
                            loading: false, step: this.state.step + 1,
                            userName: '', firstName: '', lastName: '', email: '',
                            password: '', jobPosition: '', address: '', city: '',
                            province: '', postalCode: '', submittedAt: '', errors: [], timeZone:''
                        });
                    })
                    .catch(async (err) => {
                        this.setState({
                            loading: false, userName: '', firstName: '', lastName: '', email: '',
                            password: '', jobPosition: '', address: '', city: '',
                            province: '', postalCode: '', submittedAt: '', errors: [], timeZone:''
                        });
                        message.error(err.response.data);
                    });
            }
            else {
                await axios.post(ACCEPT_COMMON_INVITE, userInvitation)
                    .then(async (res) => {
                        notification.open({
                            message: 'Successfully Registered',
                            description:
                                `You have successfully registered. Welcome ${res.data.firstName}, check your email ${res.data.email} for a confirmation email.`,
                            icon: <SmileOutlined style={{ color: '#108ee9' }} />,
                        });
                        this.setState({
                            loading: false, step: this.state.step + 1,
                            userName: '', firstName: '', lastName: '', email: '',
                            password: '', jobPosition: '', address: '', city: '',
                            province: '', postalCode: '', submittedAt: '', errors: [], timeZone:''
                        });
                    })
                    .catch(async (err) => {
                        this.setState({
                            loading: false, userName: '', firstName: '', lastName: '', email: '',
                            password: '', jobPosition: '', address: '', city: '',
                            province: '', postalCode: '', submittedAt: '', errors: [], timeZone:''
                        });
                        message.error(err.response.data);
                    });
            }
        }
        this.setState({ errors: checker.errors, loading: false });
    }

    // Proceed to next step
    nextStep = () => {        
        this.setState({ step: this.state.step + 1 });
    };

    // Go back to prev step
    prevStep = () => {
        this.setState({ step: this.state.step - 1 });
    };

    render() {
        return (
            <div>
            {
                (this.state.loading)?
                <Skeleton active/>
                :
                <UserForm 
                    values={this.state}
                    handleChange={this.handleChange}
                    onSubmit={this.onSubmit}
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                />
            }
            </div>
        )
    }
}

export default UserInvitation