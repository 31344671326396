import jwtDecode from 'jwt-decode';
import { SET_CURRENT_USER } from './types';
import setAuthTokenToHeader from '../utils/SetHeaders/authToken';
import { emptyManagement } from '../actions/management-action/management-action';

export function setCurrentUser(user: any) {
    return {
        type: SET_CURRENT_USER,
        user,
    };
}

export function logout() {
    return async (dispatch: any) => {
        localStorage.clear();
        setAuthTokenToHeader(false);
        dispatch(setCurrentUser({}));
        dispatch(emptyManagement());
    };
}

export function login(token: string) {
    return async (dispatch: any) => {
        await localStorage.setItem('token', token);
        setAuthTokenToHeader(token);
        dispatch(setCurrentUser(jwtDecode(token)));
    };
}
