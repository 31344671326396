import React, { Component } from 'react';
import { isValid, objectToFormData } from '../../../utils/validate';
import axios from 'axios';
import { NavLink, Link } from 'react-router-dom';
import { MailOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, Row, Col, Input, Button, message, Form } from 'antd';
import { FORGOT_PASSWORD } from '../../components/routes';
import LoginLogoTeal from '../../assets/img/vrsafety-login-logo-teal.png';
import PoweredWithLove from '../../assetComponents/PoweredWithLove/PoweredWithLove';

class ForgotPassword extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            errors: [],
            loading: true,
            email: '',
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        this.setState({ loading: false });
    }

    async onSubmit(e: any) {
        this.setState({ loading: true });
        //e.preventDefault();
        let checker = isValid(this.state);
        if (checker.isValid) {
            let formData = objectToFormData({ email: this.state.email });
            await axios
                .post(FORGOT_PASSWORD, formData)
                .then(async (res) => {
                    this.setState({ loading: false });
                    message.success(res.data);
                })
                .catch(async (err) => {
                    this.setState({ loading: false });
                    message.error(err.response.data);
                });
        }
        this.setState({ errors: checker.errors, loading: false });
    }

    handleChange = (input: any) => (e: any) => {
        this.setState({ [input]: e.target.value } as any);
        let checker = isValid(this.state);
        this.setState({ errors: checker.errors, loading: false });
    };

    render() {
        return (
            <div className="ant-card loginCard">
                <Row>
                    <Col span={12} style={{ textAlign: 'center' }}>
                        <img
                            src={LoginLogoTeal}
                            alt="Logo"
                            style={{ height: '200px' }}
                        />
                    </Col>
                    <Col span={12} style={{ textAlign: 'center' }}>
                        <h1>Forgot password</h1>
                        <Form onFinish={this.onSubmit} className="login-form">
                            <Form.Item
                                validateStatus={
                                    this.state.errors.email ? 'error' : ''
                                }
                                help={this.state.errors.email || ''}
                            >
                                <Input
                                    prefix={
                                        <MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                                    }
                                    type="email"
                                    value={this.state.email}
                                    placeholder="Email"
                                    onChange={this.handleChange('email')}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Row>
                                    <Col span={12}>
                                        <Button className="bsd-btn-blue">
                                            <Link to={`/`}>Back to log in</Link>
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        {this.state.loading ? (
                                            <Button type="primary" loading>
                                                Loading
                                            </Button>
                                        ) : (
                                            <Button
                                                htmlType="submit"
                                                className="bsd-btn-blue"
                                            >
                                                Submit!
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Row
                    style={{
                        textAlign: 'center',
                        color: '#fff',
                        padding: '0px',
                        margin: '0px',
                        marginTop: '50px',
                    }}
                >
                    <PoweredWithLove />
                </Row>
            </div>
        );
    }
}

export default ForgotPassword;
