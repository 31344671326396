import React, { Component } from 'react';
import { List, Card, Collapse, Button } from 'antd';
import AddServiceForm from './AddServiceForm';
const { Panel } = Collapse;

class Services extends Component<any, any> {
    render() {
        return (
            <div>
                <h1>Services</h1>
                <List
                    grid={{ gutter: 16, column: 4 }}
                    dataSource={this.props.services}
                    renderItem={(service: any) => (
                    <List.Item>
                        <Card title={service.name}>
                            {
                            (service.enabled)?
                            <Button style={{marginRight:"10px"}} type="primary" danger onClick={()=>this.props.editService(service.id, "enable")}>Disable</Button>
                            :
                            <Button style={{marginRight:"10px"}} type="primary" onClick={()=>this.props.editService(service.id, "enable")}>Enable</Button>
                            }
                            {
                            (service.public)?
                            <Button type="primary" danger onClick={()=>this.props.editService(service.id, "public")}>Privatize</Button>
                            :
                            <Button type="primary" onClick={()=>this.props.editService(service.id, "public")}>Publicize</Button>
                            }
                        </Card>
                    </List.Item>
                    )}
                />
                <Collapse>
                    <Panel header="Add new Service" key="1">
                        <AddServiceForm 
                            addService={this.props.addService}
                            handleChange={this.props.handleChange}
                            createService={this.props.createService}
                        />
                    </Panel>
                </Collapse>
                
            </div>
        )
    }
}

export default Services
