import React, { Component } from 'react';

import {
    CheckOutlined,
    CloseOutlined,
    EnvironmentOutlined,
    LeftOutlined,
    PhoneOutlined,
    ShopOutlined,
} from '@ant-design/icons';

//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, Button, Switch, Form } from 'antd';

class UserInfoForm extends Component<any, any> {
    render() {
        return (
            <div>
                <Form.Item label="Same details as Company?">
                    <Switch
                        checked={this.props.values.sameAsCompany}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange={this.props.handleSameAddress('sameAsCompany')}
                    />
                </Form.Item>
                {this.props.values.sameAsCompany ? (
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            className="bsd-btn-teal"
                            style={{ marginRight: '20px' }}
                            onClick={this.props.prevStep}
                        >
                            Take me back
                            <LeftOutlined />
                        </Button>
                        <Button
                            htmlType="submit"
                            className="bsd-btn-teal"
                            onClick={this.props.onSubmit}
                        >
                            Register
                        </Button>
                    </div>
                ) : (
                    <div>
                        <Form.Item
                            validateStatus={
                                this.props.values.errors.userPhoneNumber
                                    ? 'error'
                                    : ''
                            }
                            help={
                                this.props.values.errors.userPhoneNumber || ''
                            }
                        >
                            <Input
                                prefix={
                                    <PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                                }
                                type="text"
                                value={this.props.values.userPhoneNumber}
                                placeholder="Phone Number"
                                onChange={this.props.handleChange(
                                    'userPhoneNumber'
                                )}
                            />
                        </Form.Item>
                        <Form.Item
                            validateStatus={
                                this.props.values.errors.userAddress
                                    ? 'error'
                                    : ''
                            }
                            help={this.props.values.errors.userAddress || ''}
                        >
                            <Input
                                prefix={
                                    <ShopOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                                }
                                type="text"
                                value={this.props.values.userAddress}
                                placeholder="Address"
                                onChange={this.props.handleChange(
                                    'userAddress'
                                )}
                            />
                        </Form.Item>
                        <Form.Item
                            validateStatus={
                                this.props.values.errors.userCity ? 'error' : ''
                            }
                            help={this.props.values.errors.userCity || ''}
                        >
                            <Input
                                prefix={
                                    <EnvironmentOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                                }
                                type="text"
                                value={this.props.values.userCity}
                                placeholder="City"
                                onChange={this.props.handleChange('userCity')}
                            />
                        </Form.Item>
                        <Form.Item
                            validateStatus={
                                this.props.values.errors.userProvince
                                    ? 'error'
                                    : ''
                            }
                            help={this.props.values.errors.userProvince || ''}
                        >
                            <Input
                                prefix={
                                    <EnvironmentOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                                }
                                type="text"
                                value={this.props.values.userProvince}
                                placeholder="Province/State"
                                onChange={this.props.handleChange(
                                    'userProvince'
                                )}
                            />
                        </Form.Item>
                        <Form.Item
                            validateStatus={
                                this.props.values.errors.userPostalCode
                                    ? 'error'
                                    : ''
                            }
                            help={this.props.values.errors.userPostalCode || ''}
                        >
                            <Input
                                prefix={
                                    <EnvironmentOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                                }
                                type="text"
                                value={this.props.values.userPostalCode}
                                placeholder="Postal/Zip Code"
                                onChange={this.props.handleChange(
                                    'userPostalCode'
                                )}
                            />
                        </Form.Item>
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                className="bsd-btn-teal"
                                style={{ marginRight: '20px' }}
                                onClick={this.props.prevStep}
                            >
                                Take me back
                                <LeftOutlined />
                            </Button>
                            <Button
                                htmlType="submit"
                                className="bsd-btn-teal"
                                onClick={this.props.onSubmit}
                            >
                                Register
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default UserInfoForm;
