import React, { Component } from 'react'
import RegisterForm from '../../components/Register/RegisterForm';
import { Card, Row, Col, Typography } from 'antd';

const { Title } = Typography;

interface Props {
  history: {
    push: (url: string) => {}
  }
}

interface RegisterState {
  // ...
}

class Register extends Component<Props, RegisterState> {

  render() {
    return (
      <Card className="roundedCard">
        <Row>
          <Col span={12}>
            <Title style={{ fontWeight: 300, marginBottom: '5px' }}>Welcome to</Title>
            <Title style={{ marginTop: '5px' }}>Janus Board</Title>
          </Col>
          <Col span={12}>
            <RegisterForm changeUrl={this.props.history}/>
          </Col>
        </Row>
      </Card>
    )
  }
}

export default Register