import React, { Component } from 'react';
import { Tree, Collapse, List, Avatar, Button, Modal, Select, message } from 'antd';
import { editLockoutEnabled } from '../../../api/ApiPost/RootApiPost';
import { UpdateUserRoleInCompany } from '../../../api/CompanyApi/CompanyApi';
import { getCurrentDate } from '../../../utils/dateUtils';

const { Panel } = Collapse;
const { confirm } = Modal;
const { Option } = Select;

interface State {
    roleModal: boolean,
    roles: any,
    selectedRole:string,
    selectedCompany:string
};

class UserRoles extends Component<any, State> {
    state: State = {
        roleModal: false,
        roles: [],
        selectedRole:"",
        selectedCompany:""
    }

    public constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        //console.log(this.props);
    }

    changeRole = (companyRole: any) => {
        this.setState({ selectedCompany: companyRole.companyId });
        this.setState({ roleModal: true });
    }

    setRoleState = (e: any) => {
        this.setState({ selectedRole: e });
    }

    onRoleChange = () => {
        UpdateUserRoleInCompany(
            {
                user: this.props.user.id,
                company: this.state.selectedCompany,
                role: this.state.selectedRole,
                submittedAt: getCurrentDate()
            },
            this.successRoleChange
        );
    }

    successRoleChange = (data:any) => {
        this.props.successRoleChange();
        this.setState({ roleModal: false });
        message.success("Role Updated");
    }

    toggleRoleModal = () => {
        this.setState({ roleModal: !this.state.roleModal });
    }

    error = (data: any) => {
        
    }

    render() {
        return (
            <div>
                <Collapse>
                    <Panel
                        header="Company Roles"
                        key={this.props.user.id}
                    >
                        <List
                            dataSource={this.props.user.companies}
                            renderItem={(company: any) => (
                                <List.Item
                                actions={[
                                    <Button type="primary" onClick={()=>this.changeRole(company)}>
                                            Change Role
                                    </Button>
                                ]}
                            >
                                <List.Item.Meta
                                    title={`${company.role.name} (${company.company.legalEntityName})`}

                                />

                            </List.Item>
                            )}
                        />

                    </Panel>
                </Collapse>
                <Modal
                    title="Select new Role"
                    open={this.state.roleModal}
                    okText="Change Role"
                    onOk={this.onRoleChange}
                    onCancel={this.toggleRoleModal}
                    closable
                >
                    <Select
                        placeholder="Select a role"
                        onChange={this.setRoleState}
                        style={{ width: 200 }}
                    >
                        {
                            this.props.roles.map((role: any) =>
                                <Option value={role.name} key={role.id}>
                                    {role.name}
                                </Option>
                                )
                        }
                    </Select>
                </Modal>
            </div>
        );
    }
}

export default UserRoles;
