import React from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const homeSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.25 31.24" width="1em" height="1em">
        <title>home</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_4" data-name="Layer 4">
                <path fill="#fff" d="M17.93,1.5V6a1.5,1.5,0,0,0,1.5,1.5H30.75A1.5,1.5,0,0,0,32.25,6V1.5A1.5,1.5,0,0,0,30.75,0H19.43A1.5,1.5,0,0,0,17.93,1.5Z"/>
                <path fill="#fff" d="M17.93,12.6V29.74a1.5,1.5,0,0,0,1.5,1.5H30.75a1.5,1.5,0,0,0,1.5-1.5V12.6a1.5,1.5,0,0,0-1.5-1.5H19.43A1.5,1.5,0,0,0,17.93,12.6Z"/>
                <path fill="#fff" d="M14.32,29.74V25.26a1.5,1.5,0,0,0-1.5-1.5H1.5A1.5,1.5,0,0,0,0,25.26v4.48a1.5,1.5,0,0,0,1.5,1.5H12.82A1.5,1.5,0,0,0,14.32,29.74Z"/>
                <path fill="#fff" d="M14.32,18.65V1.5A1.5,1.5,0,0,0,12.82,0H1.5A1.5,1.5,0,0,0,0,1.5V18.65a1.5,1.5,0,0,0,1.5,1.5H12.82A1.5,1.5,0,0,0,14.32,18.65Z"/>
            </g>
        </g>
    </svg>
);

const HomeIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={homeSvg} {...props} />
);

const companiesSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.14 24.24" width="1em" height="1em">
        <title>companies</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_4" data-name="Layer 4">
                <path fill="#fff" d="M20.33,15.39H31.48a.57.57,0,0,0,.57-.58V6.92a1.59,1.59,0,0,0-1.6-1.59h-8A5.33,5.33,0,0,0,17.14,0H15A5.34,5.34,0,0,0,9.68,5.33h-8A1.6,1.6,0,0,0,.09,6.92v7.89a.58.58,0,0,0,.58.58H20.33ZM11.81,5.33h0A3.2,3.2,0,0,1,15,2.13h2.13a3.19,3.19,0,0,1,3.19,3.2H11.81Z"/>
                <path fill="#fff" d="M11.48,17.35H.57a.58.58,0,0,0-.57.57v5.17a1.15,1.15,0,0,0,1.15,1.15H31a1.15,1.15,0,0,0,1.14-1.15V17.92a.57.57,0,0,0-.57-.57H11.48Z"/>
            </g>
        </g>
    </svg>
);

const CompanyIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={companiesSvg} {...props} />
);

const incognitoSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.43 29.73" width="1em" height="1em">
    <title>incognito</title>
    <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_4" data-name="Layer 4">
            <path fill="#fff" d="M29.66,9.71,25,13.59a7.63,7.63,0,0,1,.43,2.5,7.53,7.53,0,0,1-11.33,6.5L11,25.15a18.67,18.67,0,0,0,7.21,1.68c10.23.14,16.71-10,17.18-10.74A28,28,0,0,0,29.66,9.71Z"/>
            <path fill="#fff" d="M7.6,23.32l.51.32,3.71-3.07A7.53,7.53,0,0,1,23.41,11l3.71-3.07A16.55,16.55,0,0,0,18.2,5.34,21,21,0,0,0,7.6,8.85,23.52,23.52,0,0,0,.37,16.09,23.51,23.51,0,0,0,7.6,23.32Z"/>
            <path fill="#fff" d="M1.25,29.73a1.23,1.23,0,0,1-1-.46,1.25,1.25,0,0,1,.16-1.76L33.38.29A1.25,1.25,0,1,1,35,2.21L2.05,29.44A1.29,1.29,0,0,1,1.25,29.73Z"/>
        </g>
    </g>
    </svg>
);
const IncognitoIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={incognitoSvg} {...props} />
);

const profileSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.25 37.21" width="1em" height="1em">
        <title>profile</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_4" data-name="Layer 4">
                <path fill="#fff" d="M1,32.07H31.22a1,1,0,0,1,1,1v3.08a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V33.1A1,1,0,0,1,1,32.07Z"/>
                <path fill="#fff" d="M30.7,0H1.55A1.54,1.54,0,0,0,0,1.55V27a1.54,1.54,0,0,0,1.55,1.55H4.88L5,28.2H5a5.5,5.5,0,0,1,4.31-3.85c3.8-.68,3.8-1.9,3.8-2.66V20.48h0a7.61,7.61,0,0,1-2.27-5.53V11.2a5,5,0,0,1,4.84-5.45l.48,0a5.09,5.09,0,0,1,5.32,5.47v3.68a7.66,7.66,0,0,1-.35,2.4,7,7,0,0,1-1.92,3.19v1.22c0,.76,0,1.94,3.8,2.66a5.58,5.58,0,0,1,4.3,3.85h0l.09.33H30.7A1.54,1.54,0,0,0,32.25,27V1.55A1.54,1.54,0,0,0,30.7,0Z"/>
                <path fill="#fff" d="M16.13,5.69a5.11,5.11,0,0,1,5.34,5.5v3.67a7.81,7.81,0,0,1-.63,3.22,7.72,7.72,0,0,1-1.66,2.42A3.69,3.69,0,0,1,16.13,22a4.29,4.29,0,0,1-3.06-1.53,8.44,8.44,0,0,1-2.29-5.64V11.19a5.12,5.12,0,0,1,5.35-5.5m0-1.14a6.22,6.22,0,0,0-6.49,6.64V14.9a9.49,9.49,0,0,0,2.63,6.42,5.37,5.37,0,0,0,3.86,1.85A4.8,4.8,0,0,0,20,21.28a8.82,8.82,0,0,0,2.59-6.44V11.19a6.22,6.22,0,0,0-6.48-6.64Z"/>
            </g>
        </g>
    </svg>
);
const ProfileIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={profileSvg} {...props} />
);

const notifCenterSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.25 25.81" width="1em" height="1em">
        <title>notif-center</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_4" data-name="Layer 4">
                <path fill="#fff" d="M28,.52a.52.52,0,0,0-.82-.43,29,29,0,0,1-15,4.71H8V15.33c0,3.21,0,8.11,6,10.48h.37a1.06,1.06,0,0,0,.37-2.06c-4.58-1.79-4.58-5.31-4.58-8.42h2.11a26.5,26.5,0,0,1,15,4.68.52.52,0,0,0,.81-.44ZM0,8v4.21a3.16,3.16,0,0,0,3.16,3.16H6.32V4.8H3.16A3.15,3.15,0,0,0,0,8Z" transform="translate(0 0)"/>
                <path fill="#fff" d="M29.62,6.92h.49a3.14,3.14,0,0,1,3.14,3.14v0a3.14,3.14,0,0,1-3.14,3.14h-.49a0,0,0,0,1,0,0V6.92A0,0,0,0,1,29.62,6.92Z"/>
            </g>
        </g>
    </svg>
);
const NotifCenterIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={notifCenterSvg} {...props} />
);

const servicesSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.33 33.33" width="1em" height="1em">
        <title>services</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_4" data-name="Layer 4">
                <path fill="#fff" d="M25.42,15.06a1,1,0,0,1-1.14-.75A4.49,4.49,0,0,0,23.72,13,1,1,0,0,1,24,11.61a.66.66,0,0,0,.1-1L22.76,9.23a.66.66,0,0,0-1,.1,1,1,0,0,1-1.34.28A4.49,4.49,0,0,0,19,9.05a1,1,0,0,1-.75-1.15.65.65,0,0,0-.66-.8H15.72a.65.65,0,0,0-.66.81,1,1,0,0,1-.75,1.14A4.49,4.49,0,0,0,13,9.61a1,1,0,0,1-1.34-.28.66.66,0,0,0-1-.1L9.23,10.57a.66.66,0,0,0,.1,1A1,1,0,0,1,9.61,13a4.49,4.49,0,0,0-.56,1.36,1,1,0,0,1-1.15.75.65.65,0,0,0-.8.66v1.89a.65.65,0,0,0,.81.66A1,1,0,0,1,9.05,19a4.49,4.49,0,0,0,.56,1.36,1,1,0,0,1-.28,1.34.66.66,0,0,0-.1,1l1.34,1.34a.66.66,0,0,0,1-.1A1,1,0,0,1,13,23.72a4.49,4.49,0,0,0,1.36.56,1,1,0,0,1,.75,1.14.65.65,0,0,0,.66.81h1.89a.65.65,0,0,0,.66-.81A1,1,0,0,1,19,24.28a4.49,4.49,0,0,0,1.36-.56,1,1,0,0,1,1.34.28.66.66,0,0,0,1,.1l1.34-1.34a.66.66,0,0,0-.1-1,1,1,0,0,1-.28-1.34A4.49,4.49,0,0,0,24.28,19a1,1,0,0,1,1.14-.75.65.65,0,0,0,.81-.66V15.72A.65.65,0,0,0,25.42,15.06Zm-8.76,5.58a4,4,0,1,1,4-4A4,4,0,0,1,16.66,20.64Z"/>
                <path fill="#fff" d="M16.67,33.33A16.67,16.67,0,1,1,16.67,0a1,1,0,0,1,0,2A14.67,14.67,0,1,0,31.33,16.67a15,15,0,0,0-.49-3.79,1,1,0,1,1,1.93-.51,16.67,16.67,0,0,1-16.1,21Z"/>
                <circle fill="#fff" cx="26.23" cy="4.25" r="1.31"/>
            </g>
        </g>
    </svg>
);

const ServicesIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={servicesSvg} {...props} />
);
const toursSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.4 27.7" width="1em" height="1em">
        <title>tours</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_4" data-name="Layer 4">
                <path fill="#fff" d="M12.44,14.44a1,1,0,0,0,.93,0l11.29-6,.58-.3a1,1,0,0,0,0-1.78L13.37.12a1,1,0,0,0-.93,0L.53,6.42a1,1,0,0,0,0,1.76l.62.34Z"/>
                <polygon fill="#fff" points="26.2 13.52 26.2 13.51 26.2 13.52 26.2 13.52"/>
                <path fill="#fff" d="M17.83,14.08a3,3,0,0,0,0,.52v4.15l-4.41,2.33a1,1,0,0,1-.94,0l-11.24-6-.59-.3A1,1,0,0,1,.59,13l2.23-1.17a1,1,0,0,1,.93,0l8.68,4.61a1,1,0,0,0,.94,0Z"/>
                <path fill="#fff" d="M31.32,13.51H21.09A1.09,1.09,0,0,0,20,14.6V24.83a1.09,1.09,0,0,0,1.09,1.09H31.32a1.08,1.08,0,0,0,1.08-1.09V14.6A1.08,1.08,0,0,0,31.32,13.51Zm-2.17,6.28a.24.24,0,0,1-.08.2.24.24,0,0,1-.18.07h-2.1V22.2a.27.27,0,0,1-.08.19.24.24,0,0,1-.19.07h-.63a.25.25,0,0,1-.19-.07.27.27,0,0,1-.08-.19V20.06h-2.1a.28.28,0,0,1-.19-.07.27.27,0,0,1-.07-.2v-.57a.25.25,0,0,1,.07-.19.28.28,0,0,1,.19-.07h2.1V16.88a.28.28,0,0,1,.08-.2.29.29,0,0,1,.19-.07h.63a.27.27,0,0,1,.19.07.28.28,0,0,1,.08.2V19h2.1a.24.24,0,0,1,.26.26Z"/>
                <path fill="#fff" d="M17.81,25.24l-4.44,2.35a1,1,0,0,1-.94,0L.55,21.3a1,1,0,0,1,0-1.77l2.18-1.15a1,1,0,0,1,.94,0L12.43,23a1,1,0,0,0,.94,0l4.41-2.34v4.14A2.6,2.6,0,0,0,17.81,25.24Z"/>
            </g>
        </g>
    </svg>
);
const ToursIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={toursSvg} {...props} />
);
export { HomeIcon, CompanyIcon, IncognitoIcon, ProfileIcon, NotifCenterIcon, ServicesIcon, ToursIcon };