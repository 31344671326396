import React, { Component } from 'react';
import AppRouter from '../router';
import LayOut from './LayOut';
import './App.css';
import '../Janus/assets/custom.css';
import '../Janus/assets/bsd-text-style.css';
import '../Janus/assets/bsd-btn-style.css';


class App extends Component {
    render() {
        return (
            <LayOut>
                <AppRouter />
            </LayOut>
        );
    }
}

export default App;
