import React, { Component } from 'react'
import { MailOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Form } from 'antd';

class AddServiceForm extends Component<any, any> {
    render() {
        return (
            <Form onFinish={this.props.addService} className="login-form">
                <Form.Item validateStatus={this.props.createService.errors.code ? 'error' : ''} help={this.props.createService.errors.code || ''}
                    label="Code">
                    <Input
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="text" value={this.props.createService.code}
                        placeholder="code" onChange={this.props.handleChange('code')}
                    />
                </Form.Item>
                <Form.Item validateStatus={this.props.createService.errors.name ? 'error' : ''} help={this.props.createService.errors.name || ''}
                    label="Name">
                    <Input
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="text" value={this.props.createService.name}
                        placeholder="name" onChange={this.props.handleChange('name')}
                    />
                </Form.Item>
                <Form.Item validateStatus={this.props.createService.errors.description ? 'error' : ''} help={this.props.createService.errors.description || ''}
                    label="description">
                    <Input
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="text" value={this.props.createService.description}
                        placeholder="description" onChange={this.props.handleChange('description')}
                    />
                </Form.Item>
                <Form.Item validateStatus={this.props.createService.errors.dbType ? 'error' : ''} help={this.props.createService.errors.dbType || ''}
                    label="dbType">
                    <Input
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="text" value={this.props.createService.dbType}
                        placeholder="dbType" onChange={this.props.handleChange('dbType')}
                    />
                </Form.Item>
                <Form.Item validateStatus={this.props.createService.errors.url ? 'error' : ''} help={this.props.createService.errors.url || ''}
                    label="url">
                    <Input
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="text" value={this.props.createService.url}
                        placeholder="url" onChange={this.props.handleChange('url')}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Add Service
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default AddServiceForm
