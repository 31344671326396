import React, { useMemo } from 'react';
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
} from '@stripe/react-stripe-js';

import useResponsiveFontSize from './useResponsiveFontSize';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Form } from 'antd';
import { StripeCardNumberElement } from '@stripe/stripe-js';

const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    const options = useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    color: '#424770',
                    letterSpacing: '0.025em',
                    fontFamily: 'Source Code Pro, monospace',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                },
                invalid: {
                    color: '#9e2146',
                },
            },
            className: 'ant-input',
        }),
        [fontSize]
    );

    return options;
};

const CheckoutForm = (props: any) => {
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const payload = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(
                CardNumberElement
            ) as StripeCardNumberElement,
        });
        
        props.SavePaymentMethod(payload);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Form.Item label="Card number">
                <CardNumberElement className="ant-input" options={options} />
            </Form.Item>
            <Form.Item label="Expiration date">
                <CardExpiryElement className="ant-input" options={options} />
            </Form.Item>
            <Form.Item label="CVC">
                <CardCvcElement className="ant-input" options={options} />
            </Form.Item>
            <Button
                htmlType="submit"
                className="bsd-btn-orange"
                disabled={!stripe && props.NotPaid}
            >
                Pay now!
            </Button>
        </form>
    );
};

export default CheckoutForm;
