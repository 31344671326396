import { Management } from '../../interfaces/Management';

export const defaultManageState: Management = {
    menuSettings: {
        menu: 'Default',
        role: 'User',
    },
    managingCompany: {
        id: '',
        logoImage: '',
        bannerImage: '',
        description: '',
        businessNumber: '',
        legalEntityName: '',
        connected: false,
        internal: false,
    },
};

export const emptyManageState: Management = {
    menuSettings: {
        menu: '',
        role: '',
    },
    managingCompany: {
        id: '',
        logoImage: '',
        bannerImage: '',
        description: '',
        businessNumber: '',
        legalEntityName: '',
        connected: false,
        internal: false,
    },
};
