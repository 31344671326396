import axios from 'axios';
import { errorCallback } from '../ErrorCallback';
import { DELETE_USER, DELETE_USER_FROM_COMP, DELETE_COMPANY } from '../Routes';

export async function DeleteUser(userId: string, callback: Function) {
    await axios
        .delete(`${DELETE_USER}${userId}`)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorCallback != null) {
                await errorCallback(err);
            }
        });
}

export async function DeleteUserFromComp(userId: string, companyId: string, callback: Function) {
    await axios
        .delete(`${DELETE_USER_FROM_COMP}${userId}/${companyId}`)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorCallback != null) {
                await errorCallback(err);
            }
        });
}

export async function DeleteCompany(companyId: string, callback: Function) {
    await axios
        .delete(`${DELETE_COMPANY}${companyId}`)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorCallback != null) {
                await errorCallback(err);
            }
        });
}