import { stringToBoolean } from '../../../../utils/helperFunctions';

function createCompanyUserInvitation(state: any){
    return {
        token: state.t,
        email: state.e,
        role: state.r,
        company: state.c,
        isOwner: stringToBoolean(state.o),
        parentC: state.p
    }
}

function createUserInvitation(state: any){
    return {
        token: state.t,
        email: state.e,
        role: state.r,
        company: state.c,
        internal: stringToBoolean(state.i),
        isOwner: stringToBoolean(state.o)
    }
}

function createCommonUserInvitation(state: any) {
    return {
        token: state.t,
        role: state.r,
        company: state.c,
        internal: stringToBoolean(state.i),
    }
}

export interface AcceptInvitationState
{
    invitation: {};
    loading: boolean; // is loading
    disabled: boolean; // is disabled
    type: string;
}

export { createCompanyUserInvitation, createUserInvitation, createCommonUserInvitation };