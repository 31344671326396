import React from 'react';
import { Card, Row, Col, Typography } from 'antd';
import LoginRootForm from '../../components/LoginRoot/LoginRootForm';
import PoweredWithLove from '../../assetComponents/PoweredWithLove/PoweredWithLove';
import { Link } from 'react-router-dom';
import JanusLogo from '../../assets/img/default-janus.png';

const { Title } = Typography;

export default function LoginRoot() {
    return (
        <div className="ant-card loginCard">
            <Row>
                <Col xs={24} sm={24} md={12} style={{ textAlign: 'center' }}>
                    <button className="StrippedButton">
                        <Link to={`/`}>
                            <img
                                src={
                                    JanusLogo
                                }
                                alt="Logo"
                                style={{ height: '200px' }}
                            />
                        </Link>
                    </button>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <LoginRootForm />
                </Col>
            </Row>
            <Row
                style={{
                    textAlign: 'center',
                    color: '#fff',
                    padding: '0px',
                    margin: '0px',
                }}
            >
                <PoweredWithLove />
            </Row>
        </div>
    );
}
