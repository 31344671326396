import React, { Component } from 'react';
import { HomeOutlined, LoadingOutlined, NumberOutlined, PhoneOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Input, Upload, message, Button, Row, Col, Form } from 'antd';
import { IMAGE_UPLOAD } from '../routes';

class CompanyEditForm extends Component<any, any> {
    beforeUpload(file: any) {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    render() {
        return (
            <Row className="tealBorder bsd-whiteBG" style={{ padding: "20px", display: "block" }}>
                <h2>Edit Company Profile</h2>
                <Form layout='vertical' onFinish={this.props.onSubmit}>
                    <Row>
                        <Col span={12}>
                            <Form.Item label='Company Logo'>
                                <Upload
                                    listType='picture-card'
                                    className='avatar-uploader'
                                    showUploadList={false}
                                    action={IMAGE_UPLOAD}
                                    beforeUpload={this.beforeUpload}
                                    onChange={this.props.handleAvatarChange}>
                                    {this.props.values.logoImage !== null ||
                                        this.props.values.logoImage ? (
                                        <div>
                                            {this.props.values.logoLoad ? (
                                                <LoadingOutlined />
                                            ) : null}
                                            <img
                                                src={this.props.values.logoImage}
                                                alt='Company Logo'
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div>{
                                            (this.props.values.logoLoad) ?
                                                <LoadingOutlined />
                                                :
                                                <PlusOutlined />
                                        }

                                            <div className='ant-upload-text'>
                                                Upload
                                            </div>
                                        </div>
                                    )}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Company Banner'>
                                <Upload
                                    listType='picture-card'
                                    className='avatar-uploader'
                                    showUploadList={false}
                                    action={IMAGE_UPLOAD}
                                    beforeUpload={this.beforeUpload}
                                    onChange={this.props.handleBannerChange}>
                                    {this.props.values.bannerImage !== null ||
                                        this.props.values.bannerImage ? (
                                        <div>
                                            {this.props.values.bannerLoad ? (
                                                <LoadingOutlined />
                                            ) : null}
                                            <img
                                                src={this.props.values.bannerImage}
                                                alt='Company Logo'
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                (this.props.values.bannerLoad) ?
                                                    <LoadingOutlined />
                                                    :
                                                    <PlusOutlined />
                                            }

                                            <div className='ant-upload-text'>
                                                Upload
                                            </div>
                                        </div>
                                    )}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    {
                        (this.props.authUser.user.type === "company owner") ?
                            <Form.Item
                                validateStatus={
                                    this.props.values.errors.businessNumber ? 'error' : ''
                                }
                                help={this.props.values.errors.businessNumber || ''}
                                label='Business Number'>
                                <Input
                                    prefix={
                                        <NumberOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                                    }
                                    type='text'
                                    value={this.props.values.businessNumber}
                                    placeholder='Business Number'
                                    onChange={this.props.handleChange('businessNumber')}
                                />
                            </Form.Item>
                            : null
                    }
                    <Form.Item
                        validateStatus={
                            this.props.values.errors.legalName ? 'error' : ''
                        }
                        help={this.props.values.errors.legalName || ''}
                        label='Legal Company Name'>
                        <Input
                            prefix={
                                <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                            }
                            type='text'
                            value={this.props.values.legalName}
                            placeholder='Legal Company Name'
                            onChange={this.props.handleChange('legalName')}
                        />
                    </Form.Item>
                    <Form.Item
                        validateStatus={
                            this.props.values.errors.description ? 'error' : ''
                        }
                        help={this.props.values.errors.description || ''}
                        label='Description'>
                        <Input.TextArea
                            value={this.props.values.description}
                            placeholder='Description'
                            onChange={this.props.handleChange('description')}
                            style={{ height: "200px" }}
                        />
                    </Form.Item>
                    <Form.Item
                        validateStatus={
                            this.props.values.errors.address ? 'error' : ''
                        }
                        help={this.props.values.errors.address || ''}
                        label='Address'>
                        <Input
                            prefix={
                                <HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                            }
                            type='text'
                            value={this.props.values.address}
                            placeholder='Address'
                            onChange={this.props.handleChange('address')}
                        />
                    </Form.Item>
                    <Form.Item
                        validateStatus={
                            this.props.values.errors.province ? 'error' : ''
                        }
                        help={this.props.values.errors.province || ''}
                        label='Province'>
                        <Input
                            prefix={
                                <HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                            }
                            type='text'
                            value={this.props.values.province}
                            placeholder='Province'
                            onChange={this.props.handleChange('province')}
                        />
                    </Form.Item>
                    <Form.Item
                        validateStatus={
                            this.props.values.errors.city ? 'error' : ''
                        }
                        help={this.props.values.errors.city || ''}
                        label='City'>
                        <Input
                            prefix={
                                <HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                            }
                            type='text'
                            value={this.props.values.city}
                            placeholder='City'
                            onChange={this.props.handleChange('city')}
                        />
                    </Form.Item>
                    <Form.Item
                        validateStatus={
                            this.props.values.errors.postalCode ? 'error' : ''
                        }
                        help={this.props.values.errors.postalCode || ''}
                        label='Postal/Zip Code'>
                        <Input
                            prefix={
                                <HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                            }
                            type='text'
                            value={this.props.values.postalCode}
                            placeholder='Postal/Zip Code'
                            onChange={this.props.handleChange('postalCode')}
                        />
                    </Form.Item>
                    <Form.Item
                        validateStatus={
                            this.props.values.errors.phoneNumber ? 'error' : ''
                        }
                        help={this.props.values.errors.phoneNumber || ''}
                        label='Phone Number'>
                        <Input
                            prefix={
                                <PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                            }
                            type='text'
                            value={this.props.values.phoneNumber}
                            placeholder='Phone Number'
                            onChange={this.props.handleChange('phoneNumber')}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type='primary'
                            htmlType='submit'
                            className='login-form-button'>
                            Update
                        </Button>
                    </Form.Item>
                </Form>
            </Row>
        );
    }
}

export default CompanyEditForm;
