import React, { Component } from 'react';

import {
    EnvironmentOutlined,
    LeftOutlined,
    PhoneOutlined,
    RightOutlined,
    ShopOutlined,
} from '@ant-design/icons';

//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, Button, Form } from 'antd';

class CompanyInfoForm extends Component<any, any> {
    render() {
        return (
            <div>
                <Form.Item
                    validateStatus={
                        this.props.values.errors.phoneNumber ? 'error' : ''
                    }
                    help={this.props.values.errors.phoneNumber || ''}
                >
                    <Input
                        prefix={
                            <PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                        }
                        type="text"
                        value={this.props.values.phoneNumber}
                        placeholder="Phone Number"
                        onChange={this.props.handleChange('phoneNumber')}
                    />
                </Form.Item>
                <Form.Item
                    validateStatus={
                        this.props.values.errors.address ? 'error' : ''
                    }
                    help={this.props.values.errors.address || ''}
                >
                    <Input
                        prefix={
                            <ShopOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                        }
                        type="text"
                        value={this.props.values.address}
                        placeholder="Address"
                        onChange={this.props.handleChange('address')}
                    />
                </Form.Item>
                <Form.Item
                    validateStatus={
                        this.props.values.errors.city ? 'error' : ''
                    }
                    help={this.props.values.errors.city || ''}
                >
                    <Input
                        prefix={
                            <EnvironmentOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                        }
                        type="text"
                        value={this.props.values.city}
                        placeholder="City"
                        onChange={this.props.handleChange('city')}
                    />
                </Form.Item>
                <Form.Item
                    validateStatus={
                        this.props.values.errors.province ? 'error' : ''
                    }
                    help={this.props.values.errors.province || ''}
                >
                    <Input
                        prefix={
                            <EnvironmentOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                        }
                        type="text"
                        value={this.props.values.province}
                        placeholder="Province/State"
                        onChange={this.props.handleChange('province')}
                    />
                </Form.Item>
                <Form.Item
                    validateStatus={
                        this.props.values.errors.postalCode ? 'error' : ''
                    }
                    help={this.props.values.errors.postalCode || ''}
                >
                    <Input
                        prefix={
                            <EnvironmentOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                        }
                        type="text"
                        value={this.props.values.postalCode}
                        placeholder="Postal/Zip Code"
                        onChange={this.props.handleChange('postalCode')}
                    />
                </Form.Item>
                <div style={{ textAlign: 'center' }}>
                    <Button
                        className="bsd-btn-teal"
                        style={{ marginRight: '20px' }}
                        onClick={this.props.prevStep}
                    >
                        Take me back
                        <LeftOutlined />
                    </Button>

                    <Button
                        className="bsd-btn-teal"
                        onClick={this.props.nextStep}
                    >
                        Continue
                        <RightOutlined />
                    </Button>
                </div>
            </div>
        );
    }
}

export default CompanyInfoForm;
