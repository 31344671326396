export const ROOT_ROLE = 'Root';
export const USER_ROLE = 'User';
export const DEFAULT = 'Default';
export const COMPANY_OWNER = 'Company Owner';
export const COMPANY_ADMIN = 'Company Admin';
export const COMPANY_MANAGER = 'Company Manager';
export const COMPANY_OWNER_LOGIN = 'Company Owner Login';
export const COMPANY_ADMIN_LOGIN = 'Company Admin Login';
export const COMPANY_MANAGER_LOGIN = 'Company Manager Login';
export const VRSAFETY_LOGIN = 'VRSafety Login';
export const VRSAFETY_SHARECONTENT_LOGIN = 'VRSafety ShareContent Login';
export const COMPANY = 'Company';
//export const ROOT_ROLE = (process.env.FRONT_END === "Janus") ? 'Root' : 'Realx_Root';
//export const USER_ROLE = (process.env.FRONT_END === "Janus") ? 'User' : 'Realx_User';
//export const DEFAULT = (process.env.FRONT_END === "Janus") ? 'Default' : 'Realx_Default';
//export const COMPANY_OWNER = (process.env.FRONT_END === "Janus") ? 'Company Owner' : 'Realx_Company Owner';
//export const COMPANY_ADMIN = (process.env.FRONT_END === "Janus") ? 'Company Admin' : 'Realx_Company Admin';
//export const COMPANY_MANAGER = (process.env.FRONT_END === "Janus") ? 'Company Manager' : 'Realx_Company Manager';
//export const COMPANY_OWNER_LOGIN = (process.env.FRONT_END === "Janus") ? 'Company Owner Login' : 'Realx_Company Owner Login';
//export const COMPANY_ADMIN_LOGIN = (process.env.FRONT_END === "Janus") ? 'Company Admin Login' : 'Realx_Company Admin Login';
//export const COMPANY_MANAGER_LOGIN = (process.env.FRONT_END === "Janus") ? 'Company Manager Login' : 'Realx_Company Manager Login';
//export const VRSAFETY_LOGIN = (process.env.FRONT_END === "Janus") ? 'VRSafety Login' : 'Realx_VRSafety Login';
//export const VRSAFETY_SHARECONTENT_LOGIN = (process.env.FRONT_END === "Janus") ? 'VRSafety ShareContent Login' : 'Realx_VRSafety ShareContent Login';