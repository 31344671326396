import { Root } from './routes/Root';
import { CompanyOwner } from './routes/CompanyOwner';
import { CompanyAdmin } from './routes/CompanyAdmin';
import { CompanyManager } from './routes/CompanyManager';
import { DefaultUser } from './routes/DefaultUser';
import { VrsafetyUser } from './routes/VrsafetyUser';
import {
    ROOT_ROLE,
    COMPANY_OWNER,
    COMPANY_ADMIN,
    COMPANY_MANAGER,
    DEFAULT,
    COMPANY_OWNER_LOGIN,
    COMPANY_ADMIN_LOGIN,
    VRSAFETY_LOGIN,
} from '../app/UserMenuTypes';

export function ReturnRoutes(role: string) {
    switch (role) {
        case ROOT_ROLE:
            return Root;
        case COMPANY_OWNER:
            return CompanyOwner;
        case COMPANY_ADMIN:
            return CompanyAdmin;
        case COMPANY_MANAGER:
            return CompanyManager;
        case DEFAULT:
            return DefaultUser;
        case VRSAFETY_LOGIN:
            return VrsafetyUser;
        case COMPANY_OWNER_LOGIN:
        case COMPANY_ADMIN_LOGIN:
        default:
            return [];
    }
}
