import React, { Component } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Card, Button, Avatar } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { MenuSettings } from '../../../interfaces/MenuSettings';

class CompanyCard extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    async componentDidMount() {
        await this.setState({ loading: false });
    }

    CheckModel(data: any) {
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                if (data[key] === null || data[key] == undefined) {
                    data[key] = '';
                }
            }
        }
        return data;
    }

    changeMenu() {
        let menuSettings: MenuSettings = {
            menu: this.props.menuName,
            role: 'Company',
        };
        this.props.changeManagement(menuSettings, this.props.company);
    }

    render() {
        return (
            <Card
                size="small"
                style={{ marginBottom: '10px' }}
                actions={[
                    <Button
                        type="primary"
                        onClick={() => this.changeMenu()}
                        disabled={
                            this.props.management.managingCompany.name ===
                            this.props.company?.legalEntityName
                        }
                    >
                        Manage me!
                    </Button>,
                ]}
                loading={this.state.loading}
            >
                <Meta
                    avatar={
                        this.props.company?.logoImage === '' ||
                        this.props.company?.logoImage === null ? (
                            <Avatar shape="square" size={69} icon={<UserOutlined />} />
                        ) : (
                            <Avatar
                                shape="square"
                                size={69}
                                src={this.props.company?.logoImage}
                            />
                        )
                    }
                    title={this.props.company?.legalEntityName}
                    description={
                        this.props.company?.description === '' ||
                        this.props.company?.description !== null
                            ? this.props.company?.description.substring(0, 200)
                            : 'No Description available.'
                    }
                />
            </Card>
        );
    }
}

export default CompanyCard;
