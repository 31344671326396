import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Companies } from '../../../api/CompanyApi/CompanyApi';
import { RequestCompanyConnect } from '../../../api/CompanyConnectApi/CompanyConnectApi';
import Suggestions from '../../components/OtherCompanies/Suggestions';

export class OtherCompanies extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            companies: [],
            loading: true
        };
    }

    async componentDidMount(){
        // await ConnectedCompanies(this.props.managedCompany.id, (data: any) => console.log(data));
        await Companies(this.props.managedCompany.id, this.successCompanies);
        await this.setState({ loading: false })
    }

    successCompanies = async (data: any) => {
        await this.setState({ companies: data });
    }

    requestConnection = async (data: any) => {
        RequestCompanyConnect(data, (res: any) => {} )
    }

    render() {
        return (
            <div>
            {
                (this.state.loading) ?
                <h1>Loading... lol</h1>
                :
                <Suggestions
                    myCompanyId={this.props.managedCompany.id}
                    companies={this.state.companies}
                    requestConnection={this.requestConnection}
                />  
            }
            </div>
        )
    }
}

function reduxStateToProps(state: any){
    return {
        managedCompany: state.management.managingCompany
    }
}

export default connect(reduxStateToProps)(OtherCompanies);