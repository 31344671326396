import { message } from 'antd';

export const errorCallback = (error: any) => {
    message.error('An error Occurred check the console for more information');
    if (error.hasOwnProperty('response')) {
        if (error.response.hasOwnProperty('data')) {
            console.error(error.response.data);
            if (typeof error.response.data === 'string') {
                message.warn(error.response.data);
            } else if (typeof error.response.data === 'object') {
                let keys: Array<string> = Object.values(error.response.data);
                for (let index = 0; index < keys.length; index++) {
                    message.info(keys[index]);
                }
            }
        } else {
            console.error(error.response);
        }
    } else {
        console.error(error);
    }
};
