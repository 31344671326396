import React, { Component } from 'react';
import { Empty, Spin } from 'antd';
import { connect } from 'react-redux';
import CompanyCard from './CompanyCard';
import { changeManagement } from '../../../actions/management-action/management-action';

export class ManageCompanies extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.setState({ loading: false });
    }

    render() {
        return (
            <div>
                {this.state.loading ? (
                    <div style={{ textAlign: 'center' }}>
                        <Spin />
                    </div>
                ) : this.props.companies < 1 ? (
                    <Empty
                        // if we need to add custom image we can do like so
                        // image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                        // imageStyle={{ height: 60, }}
                        description={
                            <span>You have no companies to administrate.</span>
                        }
                    />
                ) : (
                    this.props.companies.map((company: any) => (
                        <CompanyCard
                            key={company.id}
                            company={company}
                            management={this.props.management}
                            menuName={this.props.menuName}
                            changeManagement={this.props.changeManagement}
                        />
                    ))
                )}
            </div>
        );
    }
}

function mapToStateToProps(state: any) {
    return {
        management: state.management,
    };
}

export default connect(mapToStateToProps, { changeManagement })(
    ManageCompanies
);
