import React, { Component } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { logout } from '../../../actions/authActions';
import { connect } from 'react-redux';
import qs from 'qs';
import axios from 'axios';
import { DECOMPOSE_LINK } from '../../components/routes';
import CompanyUserInvitation from '../../components/AcceptInvitation/CompanyInvitation/CompanyUserInvitation';
import UserInvitation from '../../components/AcceptInvitation/UserInvitation/UserInvitation';
import {
    createUserInvitation,
    createCompanyUserInvitation,
    createCommonUserInvitation,
    AcceptInvitationState,
} from '../../components/AcceptInvitation/modules/accept-invitation';
import { Redirect } from 'react-router';
import LoginLogoTeal from '../../assets/img/vrsafety-login-logo-teal.png';

class AcceptInvitation extends Component<any, AcceptInvitationState> {
    constructor(props: any) {
        super(props);
        this.state = {
            invitation: {},
            loading: true,
            disabled: false,
            type: '',
        };
    }

    async componentDidMount() {
        if (localStorage.getItem('token') !== null) {
            localStorage.clear();
            this.props.logout();
        }
        const queryString = this.props.location.search.replace('?', '');
        
            
            const base64 = await atob(this.props.location.search.replace('?', ''));
            const params = await qs.parse(base64, { strictNullHandling: true });
            let invitation = {};
            
            if (params['p'] !== undefined) {
                invitation = createCompanyUserInvitation(params);
                this.setState({ loading: false, type: 'company', invitation });
            }
            else if (params['e'] !== undefined && params['o'] !== undefined) {
                invitation = await createUserInvitation(params);
                await this.setState({ loading: false, type: 'user', invitation });
            }
            else {
                invitation = createCommonUserInvitation(params);
                this.setState({ loading: false, type: 'commonUser', invitation });
            }
    }





        /*if (localStorage.getItem('token') !== null) {
            localStorage.clear();
            this.props.logout();
        }
        const base64 = await atob(this.props.location.search.replace('?', ''));
        const params = await qs.parse(base64, { strictNullHandling: true });
        //const base64 = await atob(this.props.location.search.replace('?', ''));
        //const params = await qs.parse(base64, { strictNullHandling: true });
        let invitation = {};
        console.log(params);
        if (params['p'] !== undefined) {
            invitation = createCompanyUserInvitation(params);
            this.setState({ loading: false, type: 'company', invitation });
        }
        else if (params['e'] !== undefined && params['o']!==undefined) {
            invitation = await createUserInvitation(params);
            await this.setState({ loading: false, type: 'user', invitation });
        } 
        else {
            invitation = createCommonUserInvitation(params);
            this.setState({ loading: false, type: 'commonUser', invitation });
        }*/
        // add an else here for if no owner or email, if params o and e 



    checkInvitationType() {
        switch (this.state.type) {
            case 'company':
                return (
                    <CompanyUserInvitation invitation={this.state.invitation} />
                );
            case 'user':
                return <UserInvitation invitation={this.state.invitation} />;
            case 'commonUser':
                return <UserInvitation invitation={this.state.invitation} />;
            default:
                return <Redirect to="/" />;
        }
    }

    render() {
        return (
            <div className="ant-card loginCard">
                <Row>
                    <Col span={12} style={{ textAlign: 'center' }}>
                        <img
                            src={LoginLogoTeal}
                            alt="Logo"
                            style={{ height: '200px' }}
                        />
                    </Col>
                    <Col span={12} key="2">
                        {this.state.loading ? (
                            <Skeleton active />
                        ) : (
                            this.checkInvitationType()
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default connect(null, { logout })(AcceptInvitation);
