import React, { Component } from 'react';
import { connect } from 'react-redux';
import { COMPANY_ENTITY, ENTITY_INDUSTRIES, ENTITY_SIZES, USER_COMPANY_CONNECTIONS } from '../../components/routes';
import axios from 'axios';
import { Spin, Tabs, message } from 'antd';
import CompanyInfo from '../../components/Company/CompanyInfo';
import CompanyEdit from '../../components/Company/CompanyEdit';
import Requests from '../../components/Company/Requests';
import Connections from '../../components/Company/Connections';
import { CompanyConnectRequests, CompanyConnectAction } from '../../../api/CompanyConnectApi/CompanyConnectApi';

class Company extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            company: {},
            industries: [],
            sizes: [],
            requests: [],
            connections: [],
            companyRequests: [],
            loading: true,
            owner: {}
        };
    }

    async componentDidMount(){
        let companyId = this.props.management.managingCompany.id;
        
        await axios.get(`${COMPANY_ENTITY}${companyId}`)
        .then(async (res: any) => {
            await this.setState({ company: res.data });
        })
        .catch(async (err) => {
            console.log(err.response);
        });

        await axios.get( ENTITY_INDUSTRIES )
        .then(async (res: any) => {
            await this.setState({ industries: res.data });
        })
        .catch(async (err) => {
            console.log(err.response);
        });

        await axios.get( ENTITY_SIZES )
        .then(async (res: any) => {
            await this.setState({ sizes: res.data });
        })
        .catch(async (err) => {
            console.log(err.response);
        });
        let compId = this.state.company.id;
        await this.GetUsersInMyCompany(compId);
        await CompanyConnectRequests(compId, this.successCompanyRequests);
        await this.setState({ loading: false });
    }

    successCompanyRequests = async (data: any) => {
        await this.setState({ companyRequests: data });
    }

    async GetUsersInMyCompany(compId: number){
        await axios.get(USER_COMPANY_CONNECTIONS,
            { params: { company: compId } })
        .then(async (res) => {
            let owner = res.data.connections.filter((user: any) => user.user.firstName !== "shared"&& user.user.isOwner);
            await this.setState({ 
                requests: res.data.requests,
                connections: res.data.connections,
                owner: owner[0]});
        })
        .catch(async (err) => {
            console.error(err.response);
            message.error('Something went wrong, check log.');
        });
    }

    async companyConnectAction(payload: any){
        CompanyConnectAction(payload, this.successcompanyConnectAction);
    }

    successcompanyConnectAction = async (data: any) => {
        await this.setState({ companyRequests: data });
    }

    render() {
        let items = [
            {
                key: "1",
                label: "Profile",
                children: (<CompanyInfo
                    company={this.state.company}
                    owner={this.state.owner}
                />)
            },
            {
                key: "2",
                label: "Edit Company Info",
                children: (
                    <CompanyEdit
                        company={this.state.company}
                        industries={this.state.industries}
                        sizes={this.state.sizes}
                        owner={this.state.owner}
                        authUser={this.props.auth}
                    />)
            },
        ];
        if (this.props.auth.user.type === "company owner") {
            items.push({
                key: "3",
                label: "Connections",
                children: (<Connections
                    connections={this.state.connections}
                />)
            });
            items.push({
                key: "4",
                label: "Requests",
                children: (
                    <Requests
                        requests={this.state.requests}
                    />)
            });
        }
        return (
            <div>
                {
                    (this.state.loading) ?
                        <div style={{ textAlign: 'center' }}>
                            <Spin />
                        </div>
                        :
                        <Tabs defaultActiveKey="1"
                            tabPosition={'right'}
                            items={items}
                        />

                }
                
            </div>
        )
    }
}
//removed company request page
/*<TabPane tab="Company Requests" key="6">
<CompanyRequest
    companyRequests={this.state.companyRequests}
    companyConnectAction={this.companyConnectAction}
/>
</TabPane>*/
function mapToStateToProps(state: any){
    return {
      auth: state.auth,
      management: state.management
    };
}

export default connect(mapToStateToProps, { })(Company);