import { CHANGE_MENU } from '../../actions/types';
import { Management, ActionManagement } from '../../interfaces/Management';

const initialState: Management = {
    menuSettings: {
        menu: '',
        role: '',
    },
    managingCompany: {
        id: '',
        logoImage: '',
        bannerImage: '',
        description: '',
        businessNumber: '',
        legalEntityName: '',
        connected: false,
        internal: false,
    },
};

export default (state = initialState, action: ActionManagement) => {
    switch (action.type) {
        case CHANGE_MENU:
            return {
                managingCompany: action.managingCompany,
                menuSettings: action.menuSettings,
            };
        default:
            return state;
    }
};
