import React, { Component } from 'react';
import { SmileOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Layout, message, notification, Result, Skeleton, Form } from 'antd';
import {
    CompanyUserRegister,
    createCompanyUserInvitationModel,
} from '../modules/company-user-invitation';
import CompanyForm from './CompanyForm';
import CompanyInfoForm from './CompanyInfoForm';
import UserForm from './UserForm';
import UserInfoForm from './UserInfoForm';
import { isValid } from '../../../../utils/validate';
import { Link } from 'react-router-dom';
import {
    ENTITY_INDUSTRIES,
    ENTITY_SIZES,
    ACCEPT_COMPANY_OWNER_INVITE,
} from '../../routes';
import axios from 'axios';

const { Content } = Layout;

class CompanyUserInvitation extends Component<any, CompanyUserRegister> {
    constructor(props: any) {
        super(props);
        this.state = {
            parentEntity: '',
            businessNumber: '',
            legalName: '',
            companySize: 0,
            entityIndustry: 0,
            phoneNumber: '',
            address: '',
            city: '',
            province: '',
            postalCode: '',
            numberOfEmployees: 0,
            userName: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            jobPosition: '',
            userPhoneNumber: '',
            userAddress: '',
            userCity: '',
            userProvince: '',
            userPostalCode: '',
            role: '',
            isOwner: false,
            parent: '',
            submittedAt: '',
            step: 1,
            industries: [],
            sizes: [],
            errors: [],
            loading: true,
            sameAsCompany: false,
            lockoutEnabled: false,
            timeZone:''
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        await this.setState({
            parentEntity: this.props.invitation.parentC,
            legalName: this.props.invitation.company,
            email: this.props.invitation.email,
            jobPosition: 'Company Owner',
            role: this.props.invitation.role,
            isOwner: this.props.invitation.isOwner,
            lockoutEnabled:false,
            parent: this.props.invitation.token,
        });
        await axios
            .get(ENTITY_INDUSTRIES)
            .then(async (res) => {
                await this.setState({ industries: res.data });
            })
            .catch(async (err) => {
                message.error(err.response.statusText);
            });

        await axios
            .get(ENTITY_SIZES)
            .then(async (res) => {
                await this.setState({ sizes: res.data });
            })
            .catch(async (err) => {
                message.error(err.response.statusText);
            });
        await this.setState({ loading: false });
    }

    // Proceed to next step
    nextStep = () => {
        this.setState({ step: this.state.step + 1 });
    };

    // Go back to prev step
    prevStep = () => {
        this.setState({ step: this.state.step - 1 });
    };

    handleSameAddress = (input: any) => async (e: any) => {
        await this.setState({ [input]: e } as any);

        this.setState({
            userPhoneNumber: this.state.phoneNumber,
            userAddress: this.state.address,
            userCity: this.state.city,
            userProvince: this.state.province,
            userPostalCode: this.state.postalCode,
        });

        let checker = isValid(this.state);
        await this.setState({ errors: checker.errors, loading: false });
    };

    handleChange = (input: any) => async (e: any) => {
        if (typeof e === 'object') {
            await this.setState({ [input]: e.target.value } as any);
        } else {
            await this.setState({ [input]: e } as any);
        }
        let checker = isValid(this.state);
        await this.setState({ errors: checker.errors, loading: false });
    };

    async onSubmit() {
        this.setState({ loading: true });
        let companyUser = createCompanyUserInvitationModel(this.state);
        let checker = isValid(companyUser);
        if (checker.isValid) {
            await axios
                .post(ACCEPT_COMPANY_OWNER_INVITE, companyUser)
                .then(async (res) => {
                    notification.open({
                        message: 'Company & Owner Created!',
                        description: `Your Company and Account have been Created! Check your email ${this.state.email}`,
                        icon: (
                            <SmileOutlined style={{ color: '#108ee9' }} />
                        ),
                    });
                    this.setState({
                        step: this.state.step + 1,
                        loading: false,
                    });                    
                })
                .catch(async (err) => {
                    message.error(err.response.statusText);
                    console.log('====================================');
                    console.log(err.response);
                    console.log('====================================');
                });
        } else {
            await this.setState({ errors: checker.errors, loading: false });
        }
    }

    showForm() {
        switch (this.state.step) {
            case 1:
                return (
                    <CompanyForm
                        handleChange={this.handleChange}
                        values={this.state}
                        nextStep={this.nextStep}
                    />
                );
            case 2:
                return (
                    <CompanyInfoForm
                        handleChange={this.handleChange}
                        values={this.state}
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                    />
                );
            case 3:
                return (
                    <UserForm
                        handleChange={this.handleChange}
                        values={this.state}
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                    />
                );
            case 4:
                return (
                    <UserInfoForm
                        handleChange={this.handleChange}
                        values={this.state}
                        prevStep={this.prevStep}
                        onSubmit={this.onSubmit}
                        handleSameAddress={this.handleSameAddress}
                    />
                );
            case 5:
                return (
                    <Result
                        status="success"
                        title="Successfully Registered!"
                        subTitle="Check your email for a confirmation email."
                        extra={[
                            <Button className={`bsd-btn-teal`}>
                                <Link to={`/`}>Login now!</Link>
                            </Button>,
                        ]}
                    />
                );
        }
    }

    render() {
        return (
            <Form
                onFinish={this.onSubmit}
                className="accept-company-owner-invite"
            >
                {this.state.loading ? <Skeleton active /> : this.showForm()}
            </Form>
        );
    }
}

export default CompanyUserInvitation;
