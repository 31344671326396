import { CHANGE_MENU } from '../types';
import { MenuSettings } from '../../interfaces/MenuSettings';
import { CompanyConnection } from '../../interfaces/CompanyConnection';
import { defaultManageState, emptyManageState } from './module';
import setCompToHeader from '../../utils/SetHeaders/compToken';
import { setCurrentCompany } from '../company-action/company-action';

function setManage(
    menuSettings: MenuSettings,
    managingCompany: CompanyConnection
) {
    return {
        type: CHANGE_MENU,
        menuSettings: menuSettings,
        managingCompany: managingCompany,
    };
}

export function emptyManagement() {
    return async (dispatch: any) => {
        localStorage.removeItem('management');
        dispatch(
            setManage(
                emptyManageState.menuSettings,
                emptyManageState.managingCompany
            )
        );
    };
}

export function defaultManagement() {
    return async (dispatch: any) => {
        localStorage.setItem(
            'management',
            JSON.stringify(defaultManageState, null, 2)
        );
        setCompToHeader(false);
        dispatch(
            setManage(
                defaultManageState.menuSettings,
                defaultManageState.managingCompany
            )
        );
    };
}

export function changeManagement(
    menuSettings: MenuSettings,
    managingCompany: CompanyConnection
) {
    return async (dispatch: any) => {
        let manage = {
            menuSettings: menuSettings,
            managingCompany: managingCompany,
        };
        localStorage.setItem('management', JSON.stringify(manage, null, 2));
        setCompToHeader(managingCompany);
        dispatch(setCurrentCompany(managingCompany));
        dispatch(setManage(menuSettings, managingCompany));
    };
}
