import React, { Component } from 'react'
import '@ant-design/compatible/assets/index.css';
import { Input, Button, message, Switch, Upload, Row, Col, Select, Form } from 'antd';
import axios from 'axios';
import { connect } from 'react-redux';
import { PROFILE_WITH_ID, IMAGE_UPLOAD, PROFILE_IMAGE_UPLOAD } from '../../components/routes';
import { isValid, objectToFormData } from '../../../utils/validate';
import { getCurrentDate } from '../../../utils/dateUtils';
import { setCurrentUser } from '../../../actions/authActions';
import { timeZonesNames } from "@vvo/tzdb";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons/lib/icons';

const timeZones = timeZonesNames.filter((zone: any) => zone.includes("America"));
const { Option } = Select;


class ProfileSettings extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            avatarImage: '',
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            jobPosition: '',
            address: '',
            city: '',
            province: '',
            postalCode: '',
            tutorialDone: false,
            submittedAt: '',
            errors: [],
            loading: true,
            imgLoading: true,
            timeZone: ''
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {

        await this.setState({
            email: this.props.profile.email, firstName: this.props.profile.firstName, avatarImage: this.props.profile.avatarImage,
            lastName: this.props.profile.lastName, phoneNumber: this.props.profile.phoneNumber, jobPosition: this.props.profile.userInformation.jobPosition,
            address: this.props.profile.userInformation.address, city: this.props.profile.userInformation.city, province: this.props.profile.userInformation.province,
            postalCode: this.props.profile.userInformation.postalCode, tutorialDone: this.props.profile.userInformation.tutorialDone, loading: false,
            imgLoading: false, timeZone: this.props.profile.userInformation.timeZone
        });
    }

    handleChange = (input: any) => async (e: any) => {
        if (typeof e === 'object') {
            await this.setState({ [input]: e.target.value } as any);
        }
        else {
            await this.setState({ [input]: e } as any);
        }
    }

    UpdateProfileViewModel(value: any) {

        return {
            id: (this.props.profile.id !== '') ? this.props.profile.id : this.props.userId,
            email: value.email,
            firstName: value.firstName,
            lastName: value.lastName,
            phoneNumber: value.phoneNumber,
            jobPosition: value.jobPosition,
            address: value.address,
            city: value.city,
            province: value.province,
            postalCode: value.postalCode,
            tutorialDone: value.tutorialDone,
            submittedAt: getCurrentDate(),
            timeZone: value.timeZone
        }
    }

    async onSubmit(e: any) {
        this.setState({ loading: true });
        //e.preventDefault();
        let updateProfile = this.UpdateProfileViewModel(this.state);
        let checker = isValid(updateProfile);
        if (checker.isValid) {
            let formModel = objectToFormData(updateProfile);
            await axios.patch(PROFILE_WITH_ID, formModel)
                .then(async (res) => {
                    //    let updatedDetails=
                    //    {
                    //     avatar: this.state.avatarImage,
                    //     display_name: this.state.firstName+" "+this.state.lastName,email: this.state.email,
                    //     exp: this.props.auth.user.exp,
                    //     family_name: this.state.lastName,
                    //     given_name: this.state.firstName,
                    //     iat: this.props.auth.user.iat,
                    //     nameid: this.props.auth.user.nameid,
                    //     nbf: this.props.auth.user.nbf,
                    //     type: this.props.auth.user.type,
                    //     unique_name: this.props.auth.user.unique_name
                    //    }
                    //    updateCurrentUser(JSON.stringify(updatedDetails));
                    //    console.log(this.props.auth);
                    await this.setState({
                        email: res.data.email, firstName: res.data.firstName,
                        lastName: res.data.lastName, phoneNumber: res.data.phoneNumber, jobPosition: res.data.userInformation.jobPosition,
                        address: res.data.userInformation.address, city: res.data.userInformation.city, province: res.data.userInformation.province,
                        postalCode: res.data.userInformation.postalCode, tutorialDone: res.data.userInformation.tutorialDone, timeZone: res.data.userInformation.timeZone,
                        loading: false
                    });
                    message.success("Profile Updated successfully.");

                })
                .catch(async (err) => {
                    this.setState({ loading: false });
                    console.log(err);
                    message.error(err.response.data);
                });
        }
        this.setState({ errors: checker.errors, loading: false });
    }

    beforeUpload(file: any) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    getBase64(img: any, callback: any) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    handleImageChange = async (info: any) => {
        if (info.file.status === 'uploading') {
            this.setState({ imgLoading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            this.getBase64(info.file.originFileObj, (avatarImage: string) =>
                this.setState({
                    avatarImage,
                    imgLoading: false,
                }),

            );
        }

        let response = info.file;
        if (response.hasOwnProperty('xhr')) {
            await axios.post(PROFILE_IMAGE_UPLOAD, {
                avatarImage: response.xhr.responseText,
                submittedAt: getCurrentDate()
            })
                .catch(async (err) => {
                    console.log('====================================');
                    console.log(err.response);
                    console.log('====================================');
                });
        }
    };

    render() {
        const formItemLayout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        }
        return (
            <div>
                {/* <h1>Profile Settings</h1> */}
                <Row>
                    <Col xs={24} sm={24} md={6}></Col>
                    <Col xs={24} sm={24} md={12} style={{ backgroundColor: "#fff", textAlign: "center", padding: "30px" }}>
                        <div>
                            <Upload
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={IMAGE_UPLOAD}
                                beforeUpload={this.beforeUpload}
                                onChange={this.handleImageChange}
                            >
                                {
                                    this.state.avatarImage ?
                                        <img src={this.state.avatarImage} alt="avatar" style={{ width: '100%' }} />
                                        :
                                        <div>
                                            {
                                                (this.state.imgLoading) ?
                                                    <LoadingOutlined />
                                                    :
                                                    <PlusOutlined />
                                            }                                            
                                            <div className="ant-upload-text">Upload</div>
                                        </div>
                                }
                            </Upload>
                        </div>
                        <Row>
                            <Col xs={24} sm={24} md={18}>
                                <Form onFinish={this.onSubmit} className="login-form" style={{}} labelAlign="left" labelCol={{ span: 2, offset: 12 }}>
                                    <Form.Item {...formItemLayout} validateStatus={this.state.errors.firstName ? 'error' : ''} help={this.state.errors.firstName || ''}
                                        label="First Name"><br />
                                        <Input
                                            // prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            type="text" value={this.state.firstName}
                                            placeholder="First Name" onChange={this.handleChange('firstName')}
                                        />
                                    </Form.Item>
                                    <Form.Item {...formItemLayout} validateStatus={this.state.errors.lastName ? 'error' : ''} help={this.state.errors.lastName || ''}
                                        label="Last Name"><br />
                                        <Input
                                            // prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            type="text" value={this.state.lastName}
                                            placeholder="Last Name" onChange={this.handleChange('lastName')}
                                        />
                                    </Form.Item>
                                    <Form.Item {...formItemLayout} validateStatus={this.state.errors.jobPosition ? 'error' : ''} help={this.state.errors.jobPosition || ''}
                                        label="Job Title"><br />
                                        <Input
                                            // prefix={<Icon type="fire" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            type="text" value={this.state.jobPosition}
                                            placeholder="Job Title" onChange={this.handleChange('jobPosition')}
                                        />
                                    </Form.Item>
                                    <Form.Item {...formItemLayout} validateStatus={this.state.errors.email ? 'error' : ''} help={this.state.errors.email || ''}
                                        label="Email"><br />
                                        <Input
                                            // prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            type="email" value={this.state.email} disabled
                                            placeholder="Email"
                                        />
                                    </Form.Item>
                                    <Form.Item {...formItemLayout} validateStatus={this.state.errors.phoneNumber ? 'error' : ''} help={this.state.errors.phoneNumber || ''}
                                        label="Phone Number"><br />
                                        <Input
                                            // prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            type="text" value={this.state.phoneNumber}
                                            placeholder="Phone Number" onChange={this.handleChange('phoneNumber')}
                                        />
                                    </Form.Item>

                                    <Form.Item {...formItemLayout} validateStatus={this.state.errors.address ? 'error' : ''} help={this.state.errors.address || ''}
                                        label="Address"><br />
                                        <Input
                                            // prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            type="text" value={this.state.address}
                                            placeholder="Address" onChange={this.handleChange('address')}
                                        />
                                    </Form.Item>
                                    <Form.Item {...formItemLayout} validateStatus={this.state.errors.city ? 'error' : ''} help={this.state.errors.city || ''}
                                        label="City"><br />
                                        <Input
                                            // prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            type="text" value={this.state.city}
                                            placeholder="City" onChange={this.handleChange('city')}
                                        />
                                    </Form.Item>
                                    <Form.Item {...formItemLayout} validateStatus={this.state.errors.province ? 'error' : ''} help={this.state.errors.province || ''}
                                        label="Province"><br />
                                        <Input
                                            // prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            type="text" value={this.state.province}
                                            placeholder="Province" onChange={this.handleChange('province')}
                                        />
                                    </Form.Item>
                                    <Form.Item {...formItemLayout} validateStatus={this.state.errors.postalCode ? 'error' : ''} help={this.state.errors.postalCode || ''}
                                        label="Postal Code"><br />
                                        <Input
                                            // prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            type="text" value={this.state.postalCode}
                                            placeholder="Postal Code" onChange={this.handleChange('postalCode')}
                                        />
                                    </Form.Item>
                                    <Form.Item {...formItemLayout} validateStatus={this.state.errors.timeZone ? 'error' : ''} help={this.state.errors.timeZone || ''}
                                        label="Time Zone"><br />
                                        <Select
                                            showSearch
                                            style={{ width: 200 }}
                                            placeholder="Time Zone"
                                            optionFilterProp="children"
                                            //onSearch={this.onSearch}
                                            onChange={this.handleChange('timeZone')}
                                            value={this.state.timeZone}
                                            filterOption={(input: any, option: any) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Option value="">Select One</Option>
                                            {
                                                timeZones.map((zone: any) =>

                                                    <Option key={zone}
                                                        value={zone}>
                                                        {zone}
                                                    </Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item {...formItemLayout} validateStatus={this.state.errors.tutorialDone ? 'error' : ''} help={this.state.errors.tutorialDone || ''}
                                        label="Tutorial Check" ><br />
                                        <Switch onChange={this.handleChange('tutorialDone')} checked={this.state.tutorialDone} />
                                    </Form.Item>
                                    <Form.Item {...formItemLayout}>
                                        <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loading}>
                                            Update Profile
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col xs={24} sm={24} md={12}></Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={6}></Col>
                </Row>
            </div>
        );
    }
}

function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        userId: state.auth.user.nameid,
    };
}
export default connect(mapToStateToProps, { setCurrentUser })(ProfileSettings);