import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Skeleton, message } from 'antd';
import { getServicesInCompany } from '../../../api/ApiGet/UserManageApiGet';
import { CompanyConnections } from '../../../api/CompanyConnectApi/CompanyConnectApi';
import { contentFromService, shareContent } from '../../../api/SharingManApi/SharingManApi';
import { unshareLinkedContent } from '../../../api/SharingManApi/SharingManApi';
import ServicesOverview from '../../components/SharingCentre/ServicesOverview';
import CompanySharing from '../../components/SharingCentre/CompanySharing';

export class ShareWith extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            subscriptions: [],
            companyConnections: [],
            managementCompany: {},
            content: [],
            service: {},
            shareToCompany: {},
            loading: true
        }
    }

    async componentDidMount(){
        let Company = this.props.management.managingCompany;
        await this.setState({ managementCompany: Company });
        await getServicesInCompany(Company.id, this.successServicesInCompany);
        await CompanyConnections(Company.id, this.successCompanyConnections);
        await this.setState({ loading: false });
    }

    successServicesInCompany = async (res: any) => {
        await this.setState({ subscriptions: res.data });
    }

    successCompanyConnections = async (data: any) => {
        await this.setState({ companyConnections: data });
    }
 
    companySharingToggle = async (company: any, serviceId: number) => {
        await this.setState({ loading: true, shareToCompany: company, service: serviceId });

        if((company != undefined || company != null) && serviceId !== 0) {
            let payload = {
                company: this.state.managementCompany.id,
                service: serviceId,
                shareToCompany: company.id
            };
            await contentFromService(payload, this.successContentFromService);
        }
        await this.setState({ companySharing: !this.state.companySharing });
        await this.setState({ loading: false });
    }

    successContentFromService = async (data: any) => {
        let contentFromService = JSON.parse(data.contentFromService);
        let content: any[] = [];

        for(let i = 0; i < contentFromService.length; i++) {
            let contentCheck = false;
            let tempLinkedContent: any = {};
            let tempServiceContent: any = {};
            for(let x = 0; x < data.contentLinked.length; x++){
                if(contentFromService[i].id == data.contentLinked[x].contentId){
                    contentCheck = true;
                    tempLinkedContent = data.contentLinked[x];
                    tempServiceContent = contentFromService[i];
                    break;
                }
            }
            if(contentCheck){
                content.push({
                    id: tempLinkedContent.id,
                    name: (contentFromService[i].name === undefined) ? contentFromService[i].tourName : contentFromService[i].name,
                    available: tempLinkedContent.available,
                    createdAt: tempLinkedContent.createdAt
                });
            } else {
                content.push({
                    id: contentFromService[i].id,
                    name: (contentFromService[i].name === undefined) ? contentFromService[i].tourName : contentFromService[i].name,
                    createdAt: contentFromService[i].createdAt
                });
            }
        }
        await this.setState({ content: content });
    }

    shareContentWithCompany = async (payload: any) => {
        await shareContent(payload, this.successSharedContent);
    }

    unshareContent = async (payload: string) => {
        await this.setState({ loading: true });
        await unshareLinkedContent(payload, this.successMakeAvailable);
        await this.setState({ loading: false });
    }

    successMakeAvailable = async (data: any) => {
        let payload = {
            company: data.contentOwnerId,
            service: data.serviceId,
            shareToCompany: data.contentSharedToId
        };
        await contentFromService(payload, this.successContentFromService);
    }

    successSharedContent = async (data: any) => {
        let content = this.state.content;
        content = content.filter((content: any) => content.id !== data.contentId);
        await this.setState({ content: content });
        message.success(`Content was shared with ${this.state.shareToCompany.legalEntityName}`);
    }

    render() {
        return (
            <div>
                {(this.state.loading) ?
                    <Skeleton active/> :
                    (this.state.companySharing) ?
                    <CompanySharing
                        content={this.state.content}
                        service={this.state.service}
                        unshareContent={this.unshareContent}
                        shareToCompany={this.state.shareToCompany}
                        contentOwner={this.state.managementCompany.id}
                        companySharingToggle={this.companySharingToggle}
                        shareContentWithCompany={this.shareContentWithCompany}
                    /> :
                    <ServicesOverview
                        subscriptions={this.state.subscriptions}
                        companySharingToggle={this.companySharingToggle}
                        companyConnections={this.state.companyConnections}
                    />
                }
            </div>
        )
    }
}

function mapToStateToProps(state: any){
    return {
        auth: state.auth,
        management: state.management
    };
}

export default connect(mapToStateToProps, {})(ShareWith);