import React, { Component } from 'react';
import { DeleteOutlined, MailOutlined, RedoOutlined } from '@ant-design/icons';
import { Tree, Collapse, List, Avatar, Button, Modal, message, DatePicker } from 'antd';
import { editLockoutEnabled } from '../../../api/ApiPost/RootApiPost';
import { getUserToken } from '../../../api/ApiGet/RootApiGet';
import UserRoles from './UserRoles';
import { AccountRoles } from '../../../api/AccountAPI/AccountAPI';
import { defaultManagement } from '../../../actions/management-action/management-action';
import { defaultUserCompPermission } from '../../../actions/permissionActions';
import { login } from '../../../actions/authActions';
import { connect } from 'react-redux';

const { Panel } = Collapse;
const { confirm } = Modal;

interface State {
    dateModal: boolean,
    dateSelected:any
    selectedUser: string,
    roles:any
}
class Users extends Component<any, State> {
    state: State = {
        dateModal: false,
        dateSelected: "",
        selectedUser: "",
        roles:[]
    }

    public constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        AccountRoles(this.rolesSuccess);
    }

    rolesSuccess = (data: any) => {
        this.setState({ roles: data });
    }

    blockToggle = async(type:any, userId:string) => {
        if (type === "block") {
            this.setState({ selectedUser: userId, dateModal: true });
        }
        else {
            await confirm({
                title: 'Do you want to unblock this user?',
                onOk: async () => {
                    await editLockoutEnabled(userId, false, null, this.props.successBlock, this.error)
                },
                onCancel() {
                },
            });
        }
    }

    toggleDateModal=()=> {
        this.setState({ dateModal: !this.state.dateModal });
    }

    changeDate = (date: any) => {
        this.setState({ dateSelected: date.format('YYYY-MM-DD') });
    }

    onBlock = async() => {
        await confirm({
            title: 'Do you want to block this user?',
            okText:"Block",
            onOk: async () => {
                await editLockoutEnabled(this.state.selectedUser, true, this.state.dateSelected, this.props.successBlock, this.error)
                this.setState({ dateModal: false });
            },
            onCancel() {
            },
        });
    }

    deleteUser = async (userId: string) => {
        await confirm({
            title: 'Are you sure you want to delete this user permanently?',
            okText: "Delete",
            onOk: async () => {
                await this.props.deleteUser(userId)
            },
            onCancel() {
            },
        });
    }

    impersonate = async(userId: any) => {
        let actualToken: any = localStorage.getItem("token");
        
        await localStorage.setItem("rootToken", actualToken);
        await getUserToken(userId, this.successToken, this.error);
    }

    successToken = async (data: any) => {
        message.success("Loading impersonated user's settings!! Please Wait!!");
        
        await this.props.login(data.data);
        await this.props.defaultUserCompPermission();
        await this.props.defaultManagement();

        await window.location.reload();
    }

    error = (data:any) => {
        console.log(data);
    }

    render() {
        return (
            <div>
                <Collapse>
                {
                    (this.props.allUsers.filter((data: any) => (data.firstName !== 'shared' && data.lastName !== 'content'))).map((user: any) =>

                        <Panel
                            header={`${user.firstName} ${user.lastName} (${user.email})`}
                            key={user.id}
                        >
                            <List.Item
                                actions={[
                                    <Button
                                        key="ResetManually"
                                        type="primary"
                                        title='Update password'
                                        icon={<RedoOutlined />}
                                        onClick={() =>
                                            this.props.openPasswordResetModal(user)
                                        }
                                        danger
                                    />,
                                    <Button
                                        key="ResetByEmail"
                                        type="primary"
                                        title='Reset user password'
                                        icon={<MailOutlined />}
                                        onClick={() => this.props.resetEmail(user.email)}
                                        danger
                                    />,
                                    <Button
                                        key="delete"
                                        type="primary"
                                        title='Delete user'
                                        icon={<DeleteOutlined />}
                                        onClick={() =>
                                            this.deleteUser(user.id)
                                        }
                                        danger
                                    />,
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <Avatar src={user.avatar} />
                                    }
                                    title={
                                        <a href={`/profile?${user.id}`}>
                                            {`${user.firstName} ${user.lastName} (${user.email})`}
                                        </a>
                                    }

                                />
                                {
                                    (user.companies.length > 0)?
                                        (user.companies[0].user.lockoutEnabled) ?
                                            <Button type="primary" onClick={() => this.blockToggle("unblock", user.id)}>Unblock</Button>
                                            :
                                            <Button type="primary" danger onClick={() => this.blockToggle("block", user.id)}>Block</Button>
                                    :null
                                } 

                                
                                {
                                    (user.companies.length > 0) ?
                                        (user.companies[0].user.emailConfirmed ) ?                                   
                                            <Button style={{marginLeft:"10px"}} type="primary" danger onClick={() =>  this.impersonate(user.id)}>Impersonate</Button> 
                                            :
                                            null
                                    :null
                                }

                            </List.Item>
                                    <UserRoles user={user} roles={this.state.roles} successRoleChange={this.props.successRoleChange} />
                        </Panel>
                        )
                    }
                </Collapse>

                <Modal
                    title="Select a date until which user should remain blocked"
                    open={this.state.dateModal}
                    okText="Block"
                    onOk={this.onBlock}
                    onCancel={this.toggleDateModal}
                    closable
                >
                    <DatePicker onChange={this.changeDate} />
                </Modal>

            </div>
        );
    }
}

export default connect(null, {
    login,
    defaultManagement,
    defaultUserCompPermission,
})(Users);
